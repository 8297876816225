import React from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { getBookingInfo, setIsShowNoShowPolicy, setIsShowOrderSummary, setTempStore } from "./Booking.slice";
import Sidebar from "src/components/Sidebar";
import AppointmentPreview from "./AppointmentPreview";
import NoShowPolicy from "./NoShowPolicy";
import PopupModal from "src/components/PopupModal";
import { useBookingConditionsStatus } from "src/hooks/useBookingConditionsStatus";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { EBookingTabState } from "./bookAppointment.interface";

const ConditionalNoShowView = () => {
    const booking = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const { hasSelectedRequiredFields } = useBookingConditionsStatus();
    const { syncTempStoreOnClose } = useShopNavigation();
    const dispatch = useAppDispatch();

    const updateSelectionCountOnClose = () => {
        // Dynamically check for either selectedServices or selectedSpecialist based on the step
        switch (booking.step) {
            case EBookingTabState.SELECT_SERVICE:
                return dispatch(setTempStore({ SelectionCount: booking.selectedServices ? 1 : 0 }));

            case EBookingTabState.SELECT_SPECIALIST:
                return dispatch(setTempStore({ SelectionCount: booking.selectedSpecialist ? 1 : 0 }));

            case EBookingTabState.SELECT_DATE_TIME:
                return dispatch(setTempStore({ SelectionCount: booking.selectedBookingDate && booking.selectedBookingTime ? 1 : 0 }));

            default:
                return null;
        }
    };
    const handleClose = () => {
        if (hasSelectedRequiredFields) {
            syncTempStoreOnClose(booking);
        }
        updateSelectionCountOnClose();
        dispatch(setIsShowNoShowPolicy(false));
    };

    return (
        <>
            <Sidebar
                dismissable
                isOpen={booking.isShowOrderSummary && uiState.isMobile}
                handleClose={() => dispatch(setIsShowOrderSummary(false))}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`} // w-[460px]"
                customClass="AppointmentPreview"
                isBottomView={uiState.isMobile ? true : false}
            >
                <AppointmentPreview />
            </Sidebar>
            <Sidebar
                dismissable
                isOpen={booking.isShowNoShowPolicy && uiState.isMobile}
                handleClose={handleClose}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`}
                customClass="!h-auto noShowPolicy"
                isBottomView={uiState.isMobile ? true : false}
            >
                <NoShowPolicy />
            </Sidebar>

            {booking.isShowNoShowPolicy && !uiState.isMobile && (
                <PopupModal
                    onClose={handleClose}
                    size="max-w-[95%] min-w-[340px] mx-auto lg:w-[540px] font-primary outline-custom"
                    className="!pt-5 !pb-0 "
                    isCrossSign={false}
                    dismissible
                    position="center"
                >
                    <NoShowPolicy />
                </PopupModal>
            )}
        </>
    );
};

export default ConditionalNoShowView;
