import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ManageSubmit from "./AppointmentPreview/ManageSubmit";
import { useAppSelector } from "src/redux/hooks";
import { InfoCircle } from "@untitled-ui/icons-react/build/cjs";

const NoShowPolicy: FC = () => {
    const { t } = useTranslation();
    const uiStates = useAppSelector((data) => data.UiStates);

    return (
        <div className="w-full flex flex-col flex-1">
            <div className="flex flex-col pt-[20px] lg:py-[50px] font-primary max-w-[440px] mx-auto relative flex-1">
                <div className="flex flex-col max-lg:h-full font-primary flex-1 max-lg:pb-[80px]">
                    <div className="flex flex-col">
                        <span className={uiStates.isMobile ? "login-model-title" : "login-model-title"}>Cancelation & No Show Policy</span>
                        <span className={uiStates.isMobile ? "login-model-subtitle" : "login-model-subtitle"}>{t("Review our no show and cancelation policies.")}</span>
                    </div>
                    <div className="flex flex-col mt-[42px] gap-4 mb-5">
                        <div className={`leading-[24px] text-[16px] -tracking-[0.02rem] font-normal text-txtcolor`}>
                            <span className="font-normal leading-[24px] text-[16px]"> Your have time </span> <span className="font-semibold leading-[24px] text-[16px]"> until 12:00 AM </span>
                            <span className="font-normal leading-[24px] text-[16px]">on</span>
                            <span className="font-semibold leading-[24px] text-[16px]"> February 24th, 2024 </span>
                            <span className="font-normal leading-[24px] text-[16px]"> to cancel this appointment without being charged</span>
                        </div>
                        <div className="flex w-full border border-borderColorSecondary p-4 rounded-[12px] shadow-[0px_1px_2px_0px_#1018280D] gap-4">
                            <span className="border border-borderColorPrimary flex rounded-[8px] shadow-[0px_1px_2px_0px_#1018280D] h-[40px] min-w-[40px] w-[40px] items-center justify-center">
                                <InfoCircle className="w-[16px] text-txtcolor" />
                            </span>
                            <div className="flex flex-col flex-1">
                                <span className="flex text-txtcolor font-semibold text-[14px] leading-[20px] mb-1">Charge up to 50%</span>
                                <span className="text-txtcolor font-normal text-[14px] leading-[20px]">You will be charged a up to 50% fee if you cancel your appointment after this date.</span>
                            </div>
                        </div>
                        <div className="flex w-full border border-borderColorSecondary p-4 rounded-[12px] shadow-[0px_1px_2px_0px_#1018280D] gap-4">
                            <span className="border border-borderColorPrimary flex rounded-[8px] shadow-[0px_1px_2px_0px_#1018280D] h-[40px] min-w-[40px] w-[40px] items-center justify-center">
                                <InfoCircle className="w-[16px] text-txtcolor" />
                            </span>
                            <div className="flex flex-col flex-1">
                                <span className="flex text-txtcolor font-semibold text-[14px] leading-[20px] mb-1">Charge up to 100%</span>
                                <span className="text-txtcolor font-normal text-[14px] leading-[20px]">
                                    You will be charged up to 100% if you do not attend your appointment on the date you specified.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageSubmit isModal={true} />
            </div>
        </div>
    );
};

export default NoShowPolicy;
