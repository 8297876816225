export const somethingWentWrongError = "We are sorry, but something went wrong. Please try again later.";
export const MAX_IMAGE_SIZE_ALLOWED = 800000;
export const COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*_|]).{6,}$/;
export const INSTAGRAM_URL = "https://www.instagram.com/";
export const GENDER_OPTIONS = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "non-binary", label: "Non-binary" },
    { value: "prefer not to respond", label: "Prefer not to respond" },
];
export const errorCode = {
    notFound: 404,
    success: 200,
    updateSuccess: 201,
    unprocessable: 422,
    internalError: 500,
    badRequest: 400,
};
export const LOCAL_STORAGE_KEYS = {
    authToken: "authUser",
};
export const ALLOW_IMAGE_TYPES = ["image/jpeg", "image/pjpeg", "image/png", "image/webp"];
export const GLOBALVARIABLE = {
    accountImageSize: 4096,
};
