import React, { FC, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { usePhone } from "src/hooks/usePhone";
import { IForgotPassword, IForgotPasswordProps, AuthDataInit } from "./Password.interface";
import { useTranslation } from "react-i18next";
import { errorCode } from "src/constants/common";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import WForgotPassword from "./Web/WForgotPassword";
import { useAppSelector } from "src/redux/hooks";
import MForgotPassword from "./Mobile/MForgotPassword";
const ForgotPassword: FC<IForgotPasswordProps> = ({ setAuthData, authData, handleAuthAction }) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const { setFormatPhone } = usePhone();
    const [isLoading, setIsLoading] = useState(false);
    const inputType = authData?.email && !authData?.phone ? "phone" : authData?.phone && !authData?.email ? "email" : "both";

    const methods = useForm<IForgotPassword>({
        defaultValues: {
            emailPhone: "",
        },
    });

    const { handleSubmit, setError } = methods;

    const handleSave = (data: any) => {
        setIsLoading(true);
        if (data.emailPhone === "") {
            setError("emailPhone", {
                type: "manual",
                message: t("Enter phone number or email"),
            });
            setIsLoading(false);

            return;
        }
        const info = data.emailPhone;
        if (info.error) {
            setError("emailPhone", {
                type: "manual",
                message: info.error,
            });
            setIsLoading(false);

            return;
        }
        let payload = {};
        if (info.isEmail) {
            payload = {
                email: info.data,
            };
        } else {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
        }
        let authDetail = { ...AuthDataInit, isEmail: info.isEmail };
        if (!info.isEmail) {
            const phoneInfo = info.data;
            const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
            const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
            authDetail = {
                ...authDetail,
                phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
            };
        } else {
            authDetail = {
                ...authDetail,
                email: info.data,
            };
        }
        axiosPost(API.USER.FORGOT_PASSWORD, payload)
            .then(() => {
                if (!info.isEmail) {
                    const phoneInfo = info.data;
                    const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                    const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                    authDetail = {
                        ...authDetail,
                        phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                    };
                } else {
                    authDetail = {
                        ...authDetail,
                        email: info.data,
                    };
                }
                setAuthData(authDetail);
                handleAuthAction("forgotPasswordOtp");
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError("emailPhone", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                    } else {
                        Object.keys(response).forEach((field) => {
                            setError("emailPhone", {
                                type: "manual",
                                message: response.message,
                            });
                        });
                    }
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
                {uiState.isTablet ? (
                    <WForgotPassword inputType={inputType} isLoading={isLoading} handleAuthAction={handleAuthAction} />
                ) : (
                    <MForgotPassword inputType={inputType} isLoading={isLoading} handleAuthAction={handleAuthAction} />
                )}
            </form>
        </FormProvider>
    );
};

export default ForgotPassword;
