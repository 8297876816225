import React, { ReactNode } from "react";
import { Button } from "primereact/button";

interface IProps {
    onClick?: (event: any) => void;
    children?: ReactNode;
    className?: string;
    type?: "submit" | "reset" | "button" | undefined;
    disabled?: boolean;
    isLoading?: boolean;
    primary?: boolean;
    secondary?: boolean;
    outlinePrimary?: boolean;
    outlineSecondary?: boolean;
    icon?: ReactNode | undefined;
    rightIcon?: ReactNode | undefined;
    size?: string;
    borderClass?: string;
    iconOnlyClass?: string;
    shadow?: boolean;
}

const CustomButton = ({
    onClick,
    children,
    className = "",
    disabled = false,
    isLoading = false,
    type = "button",
    primary = false,
    secondary = false,
    outlinePrimary = false,
    outlineSecondary = false,
    icon = undefined,
    rightIcon = undefined,
    size = "",
    borderClass = "",
    iconOnlyClass = "",
    shadow = true,
}: IProps) => (
    <Button
        type={type}
        loading={isLoading}
        disabled={disabled}
        onClick={onClick}
        className={`flex justify-center gap-[4px] font-bold text-[16px] leading-[24px] px-3 py-[9px] h-[40px] rounded-secondary !transition-all -tracking-[0.01rem]   ${className} ${size} ${borderClass} ${iconOnlyClass} ${
            primary ? "hover:bg-primaryBgButton text-primaryText  shadow-[0px_1px_2px_0px_#1018280D] bg-primary" : ""
        } ${disabled && primary ? "border border-borderColorPrimary !bg-[#EEF2F9] text-textGrey !transition-all font-bold text-[16px] leading-[24px] shadow-[0px_1px_2px_0px_#1018280D]" : ""} ${
            outlinePrimary ? "text-primary bg-containerBgColor border border-primary hover:bg-primaryHover shadow-[0px_1px_2px_0px_#1018280D]" : ""
        } ${secondary ? "bg-containerBgColor border border-borderColorPrimary text-gray-700 hover:bg-primaryHover hover:border-primaryBorderHover shadow-[0px_1px_2px_0px_#1018280D]" : ""} ${
            outlineSecondary ? "text-txtAppointmentColor  border border-borderColorSecondary hover:bg-primaryHover hover:border-primaryBorderHover shadow-[0px_1px_2px_0px_#1018280D]" : ""
        } ${disabled && secondary ? "!bg-gray-100 border border-borderColorSecondary text-black shadow-[0px_1px_2px_0px_#1018280D]" : ""} ${shadow && "shadow-InputAndButton"} `}
    >
        {icon}
        {children}
        {rightIcon}
    </Button>
);

export default CustomButton;
