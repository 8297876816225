import React, { useEffect, useState } from "react";
import { addDays, format, isAfter, isSameDay, isToday, subDays } from "date-fns";
import { useAppSelector } from "../../../../../redux/hooks";
import moment from "moment";
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";

type Props = {
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    workingDays: string[];
};

const RangeCalnedarComponent: React.FC<Props> = ({ selectedDate, setSelectedDate, workingDays }) => {
    const uiState = useAppSelector((data) => data.UiStates);
    const [dateRangeArray, setDateRangeArray] = useState<any>([]);
    const [formattedDate, setFormattedDate] = useState("");

    const updateDateRange = (date: Date) => {
        const totalDates = uiState.isMobile ? 4 : 7;
        const newDates = Array.from({ length: totalDates }, (_, index) => addDays(date, index));
        setDateRangeArray(newDates);
    };

    useEffect(() => {
        const date = new Date(selectedDate);
        const formatted = moment(date).format("MMMM YYYY");
        setFormattedDate(formatted);
        updateDateRange(selectedDate);
    }, [selectedDate, uiState.currentBreakpoint]);

    const handlePrevClick = () => {
        const newDate = subDays(selectedDate, 1);
        setSelectedDate(newDate);
    };

    const handleNextClick = () => {
        const newDate = addDays(selectedDate, 1);
        setSelectedDate(newDate);
    };

    useEffect(() => {
        // let date = new Date();
        // if (selectedDate) {
        //     date = selectedDate;
        // }
        // const totalDates = 7;
        // const newDates = Array.from({ length: totalDates }, (_, index) => addDays(date, index));
        // setDateRangeArray(newDates);
        // const pastDateLength = uiState.currentBreakpoint === "2XL" ? 6 : uiState.currentBreakpoint === "XL" ? 4 : 3;
        // const futureDateLength = uiState.currentBreakpoint === "2XL" ? 7 : uiState.currentBreakpoint === "XL" ? 5 : 4;
        // const currentDate = new Date();
        // const isCurrentDate = isToday(selectedDate);
        // const daysDifference = differenceInDays(selectedDate, currentDate);
        // let pastDates;
        // if (uiState.isMobile) {
        //     if (daysDifference < pastDateLength) {
        //         if (daysDifference == 0 && isCurrentDate == false) {
        //             pastDates = Array.from({ length: daysDifference + 1 }, (_, index) => subDays(selectedDate, index + 1));
        //         } else {
        //             pastDates = Array.from({ length: daysDifference }, (_, index) => subDays(selectedDate, index + 1));
        //         }
        //     } else {
        //         pastDates = Array.from({ length: pastDateLength }, (_, index) => subDays(selectedDate, index + 1));
        //     }
        // } else {
        //     pastDates = Array.from({ length: pastDateLength }, (_, index) => subDays(selectedDate, index + 1));
        // }
        // const futureDates = Array.from({ length: futureDateLength }, (_, index) => addDays(selectedDate, index + 1));
        // setDateRangeArray([...pastDates.reverse(), selectedDate, ...futureDates]);
    }, [selectedDate, uiState.currentBreakpoint]);

    // UI classes
    const getSelectedDateClasses = (dateRange: number) => {
        if (selectedDate && format(selectedDate, "d") === format(dateRange, "d")) {
            //   point mate
            return "!text-primary  border border-borderColorPrimary !bg-containerBgColor shadow-[0px_2px_12px_0px_#00000014] before:content-[''] before:absolute before:w-[5px] !font-semibold before:h-[5px] before:bg-primary before:rounded-full before:bottom-[6px] ";
            // if (workingDays.includes(format(dateRange, "EEEE").toLocaleLowerCase())) {
        }
        const isFutureDate = isAfter(dateRange, new Date()) || isToday(dateRange);
        if (!isFutureDate) {
            return "";
        }
        if (workingDays.includes(format(dateRange, "EEEE").toLocaleLowerCase())) {
            return " text-primaryAppointment border-borderColorPrimary";
        }
        if (isFutureDate) {
            return "border-borderColorSecondary border-[1px] opacity-[0.5]";
        }
    };

    return (
        <div className="bg-background overflow-auto md:overflow-hidden scrollbar-hide relative w-full lg:max-w-full">
            {uiState.isMobile && (
                <div className="flex justify-between w-full items-center lg:px-2 mb-[15px]">
                    <button onClick={handlePrevClick} className="flex justify-center items-center disabled:opacity-50">
                        <ChevronLeft className="text-txtcolor w-[20px] h-[20px]" />
                    </button>
                    <span className="text-base leading-[22.4px] tracking-[-0.02rem] text-txtcolor font-medium">{formattedDate}</span>
                    <button onClick={handleNextClick} className="flex justify-center items-center disabled:opacity-50">
                        <ChevronRight className="text-txtcolor w-[20px] h-[20px]" />
                    </button>
                </div>
            )}
            <div className="flex justify-between w-full items-center lg:px-2">
                {!uiState.isMobile && (
                    <button
                        onClick={handlePrevClick}
                        className="bg-containerBgColor shadow-[0px_4px_4px_0px_#0000000D] flex justify-center items-center rounded-full border h-[36px] min-w-[36px] w-[36px] border-borderColorPrimary disabled:opacity-50 z-10"
                    >
                        <ArrowLeft className="text-txtcolor w-[18px] h-[18px]" />
                    </button>
                )}
                <div className="flex gap-2.5  justify-between w-full pr-[20px] lg:pr-0 mx-[4px]">
                    {dateRangeArray.map((dateRange: number, index: number) => (
                        <div
                            key={index}
                            onClick={() => {
                                if ((isAfter(dateRange, new Date()) || isToday(dateRange)) && !isSameDay(selectedDate, new Date(dateRange))) {
                                    setSelectedDate(new Date(dateRange));
                                }
                            }}
                            className={`w-full h-full flex justify-center flex-col items-center ${
                                isAfter(dateRange, new Date()) || isToday(dateRange) ? "cursor-pointer" : "cursor-not-allowed opacity-50"
                            }`}
                        >
                            <div
                                className={`md:w-w-full md:h-[68px] w-full h-[68px] relative flex justify-center items-center rounded-secondary text-txtcolor uppercase text-[12px] font-medium flex-col bg-mainBackgroundColor
                            ${getSelectedDateClasses(dateRange)}
                            `}
                            >
                                <div className="uppercase text-[12px] lg:text-[14px] font-medium leading-[16.8px] tracking-[-0.01rem] flex mb-1 mt-1">{format(dateRange, "EE")}</div>
                                <span className="uppercase text-[14px] lg:text-[16px] font-medium leading-[18.6px] tracking-[-0.01rem] flex">{format(dateRange, "d")}</span>
                            </div>
                        </div>
                    ))}
                </div>
                {!uiState.isMobile && (
                    <button
                        onClick={handleNextClick}
                        className="bg-containerBgColor shadow-[0px_4px_4px_0px_#0000000D] flex justify-center items-center rounded-full border h-[36px] min-w-[36px] w-[36px] border-borderColorPrimary disabled:opacity-50 z-10"
                    >
                        <ArrowRight className="text-txtcolor w-[18px] h-[18px]" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default RangeCalnedarComponent;
