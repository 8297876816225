import React from "react";
import { formatInstagramLink, getInstagramUsernames } from "../../../../utils/global-functions";
import { FiInstagram } from "react-icons/fi";

type Props = {
    data: any;
};

const Info: React.FC<Props> = ({ data }) => (
    <div className="flex flex-col text-txtcolor">
        <div className="profile-images">
            <img src={data.image_url} alt="" className="w-full h-[480px] sm:h-[410px] md:h-[420px] lg:h-[380px] xl:h-[410px] 2xl:h-[435px] 3xl:h-[540px] profile-img object-cover cursor-pointer " />
        </div>
        <div className="w-[-webkit-fill-available] relative border-t mt-[10px] pt-[12px] lg:mt-[0.8vw] lg:pt-[0.8vw] text-txtcolor border-borderColorPrimary">
            <h3 className="text-[14px] uppercase lg:text-[0.8vw] xl:text-[0.9vw] font-normal">{"Stylist"}</h3>
            <div className="flex justify-between text-txtcolor mt-1 font-semibold">
                <p className="text-[18px] lg:text-[1.2vw] uppercase text-txtcolor font-normal">{data?.name}</p>
                <div className="">
                    {data?.is_instgram_button && data?.instagram_link && (
                        <a href={`${formatInstagramLink(data?.instagram_link)}`} target="_blank" className="text-xs flex items-center gap-2 border-txtcolor border p-1 px-1.5">
                            <FiInstagram size={20} className="cursor-pointer" color="text-txtcolor" />@{getInstagramUsernames(data?.instagram_link)}
                        </a>
                    )}
                </div>
            </div>
        </div>
    </div>
);

export default Info;
