import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { DefaultImageJPG } from "src/theme/Images";

import "swiper/css";
import "swiper/css/pagination";
interface Props {
    data: any;
}
const Testimonial: React.FC<Props> = ({ data }) => (
    <div className="text-txtcolor">
        <h2 className="custom-heading font-semithin uppercase spacing-top mb-[4vw]">Client Testimonials</h2>
        <Swiper
            slidesPerView={1}
            spaceBetween={20}
            pagination={{
                clickable: true,
                dynamicBullets: true,
            }}
            loop={data?.length >= 4}
            navigation={false} // Disable Swiper default navigation
            className="mySwiper w-full"
            breakpoints={{
                // When window width is <= 768px
                640: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                },
                1280: {
                    slidesPerView: 4,
                    spaceBetween: 32,
                },
            }} // Apply dynamic height here
        >
            {data?.testimonials?.map((testimonial: any, index: React.Key | null | undefined) => (
                <>
                    <div className="w-full mx-auto">
                        <SwiperSlide key={index}>
                            <div className="cursor-pointer">
                                <div className="font-light mb-6">
                                    <img src={testimonial.image_url || DefaultImageJPG} className="w-[65px] h-[65px] mb-5" alt="" />
                                    {testimonial.is_description && <p className="font-light text-[14px] md:text-[16px] xl:text-[1.111111vw] ">{testimonial.description}</p>}
                                </div>
                                <div className="flex justify-between  w-full">
                                    <div className="flex  items-center justify-center  gap-4">
                                        <p className="items-center font-normal uppercase text-[24px]">{testimonial.name}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>
                </>
            ))}
        </Swiper>
    </div>
);

export default Testimonial;
