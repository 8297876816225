import React, { useEffect } from "react";
import { ISelectedTab } from "../../../../Interface";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectTab, setIsScrollable } from "../../../../redux/reducers/Landing.slice";

interface Props {
    data: any;
}
const navLinks: { name: string; tabName: ISelectedTab }[] = [
    { name: "ABOUT US", tabName: "about" },
    { name: "LOCATION", tabName: "location" },
    { name: "TEAM", tabName: "team" },
    { name: "SERVICES", tabName: "service" },
    { name: "ACADEMY", tabName: "academy" },
    { name: "PRODUCT", tabName: "product" },
    { name: "GALLERY", tabName: "gallery" },
    { name: "TESTIMONIALS", tabName: "testimonial" },
];
const Footer: React.FC<Props> = ({ data }) => {
    const dispatch = useAppDispatch();
    const handleNavigate = (tabName: ISelectedTab) => () => {
        dispatch(setIsScrollable(true));
        dispatch(selectTab(tabName));
    };
    const [activeSettings, setActiveSettings] = React.useState<string[]>([]);
    const settings: any = useAppSelector((settingData) => settingData.Common.settings)?.find((setting: any) => setting.type === "template")?.value;

    useEffect(() => {
        const extractKeysWithStatusTrue = (obj: any) => {
            const result = [];
            for (const key in obj) {
                if (obj[key].status === true) {
                    result.push(key);
                }
            }
            return result;
        };
        const filterData = extractKeysWithStatusTrue(settings);
        setActiveSettings(filterData);
    }, [settings]);

    return (
        <footer className="bg-contentBackground pt-[60px] px-5 pb-5 lg:px-[20px]">
            <div className="design-grid">
                <div className="menu-column">
                    <div className="slice-label mb-3">
                        <span className="num">1</span>
                        <span className="slice-text">Links</span>
                    </div>
                    <ul>
                        {navLinks
                            .filter(({ tabName }) => activeSettings.includes(tabName))
                            .map(({ name, tabName }) => (
                                <li key={tabName} className="text-txtcolor hover:text-txtcolor text-sm cursor-pointer" onClick={handleNavigate(tabName)}>
                                    {name}
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="menu-column">
                    <div className="slice-label mb-3">
                        <span className="num">2</span>
                        <span className="slice-text">FOLLOWS</span>
                    </div>
                    <ul>
                        <li className="text-txtcolor hover:text-txtcolor text-sm">Instagram</li>
                    </ul>
                </div>
                <div className="menu-column">
                    <div className="slice-label mb-3">
                        <span className="num">3</span>
                        <span className="slice-text">Contact</span>
                    </div>
                    <address className="not-italic text-sm">
                        <p className="text-txtcolor">{`${data?.address_1} ${data?.address_2} ${data?.city} ${data?.country}, ${data?.zip_code} `}</p>
                        <p className="py-3 text-txtcolor"> {data.email}</p>
                        <p className="text-txtcolor">Contact for appointment</p>
                    </address>
                </div>
                <div className="newsletter-area">
                    <div className="slice-label mb-3">
                        <span className="num">4</span>
                        <span className="slice-text">Newsletter</span>
                    </div>
                    <form className="flex">
                        <input
                            type="email"
                            placeholder="Enter Your Email Address"
                            className="w-full pl-0 pt-1 pr-3 pb-3 bg-contentBackground border-[0px] !outline-0 border-b border-borderColorPrimary mt-1 placeholder:text-txtcolor placeholder:text-sm placeholder:font-normal uppercase placeholder:uppercase text-sm font-normal focus:outline-none focus:ring-0 focus-visible:border-text-txtcolor focus-within:border-text-txtcolor autofill:border-text-txtcolor focus:border-text-txtcolor"
                        />
                        <button type="submit" className="px-4 py-2 border-borderColorPrimary border-b bg-transparent text-txtcolor">
                            SUBSCRIBE
                        </button>
                    </form>
                    <div className="mt-8 pt-4 flex justify-between">
                        <span className="font-semithin text-sm text-txtcolor">
                            WE ARE OPEN {data?.start_day} TO {data?.end_day}
                        </span>
                        <span className="font-semithin text-sm text-txtcolor">
                            {data?.special_day} {data?.special_start_time}- {data?.special_end_time}
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
