import React from "react";

type Props = {
    service: any;
    isCollapsed: any;
    setIsCollapsed: any;
    index: number;
};
const Info: React.FC<Props> = ({ service, setIsCollapsed, isCollapsed, index }) => (
    <div className="border-b flex flex-col text-txtcolor relative border-b-animation border-borderColorPrimary">
        <div
            className="w-full cursor-pointer flex py-[5.2vw] md:py-[3vw] lg:py-[2vw] xl:py-[1.2vw] "
            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
        >
            <div className="flex w-full lg:w-1/2">
                <h3 className="text-[14px] lg:text-[1vw] leading-[1] pt-2.5 font-normal uppercase text-txtcolor">{service.name}</h3>
            </div>
            <div className="flex lg:w-1/2 w--full justify-end lg:justify-between">
                <h3 className="text-[14px] lg:text-[1vw] hidden lg:flex font-normal leading-[1] pt-2.5 uppercase text-txtcolor">{Number(service.price).toFixed(2)}</h3>
                {service?.is_description && (
                    <div className="text-right">
                        <button
                            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
                            className="text-right text-txtcolor leading-[1] pt-2.5 text-[14px] lg:text-[1vw] flex justify-end font-normal min-w-36"
                        >
                            {isCollapsed.isShow && isCollapsed.id === index ? "LESS −" : "MORE +"}
                        </button>
                    </div>
                )}
            </div>
        </div>
        {isCollapsed.isShow && isCollapsed.id === index && service?.is_description && (
            <div className="flex flex-row">
                <div className="flex flex-col w-full lg:w-1/2 lg:ml-auto justify-end">
                    <h3 className="text-[14px] lg:text-[1vw] flex lg:hidden font-normal uppercase mb-2 text-txtcolor">{Number(service.price).toFixed(2)}</h3>
                    <p className="font-light text-[14px] md:text-[16px] xl:text-[1vw] mb-[1.4vw]">{service.description}</p>
                </div>
            </div>
        )}
    </div>
    // service.price.description
    // <div className="border-b flex flex-col md:flex-row  text-txtcolor">
    //     <div className="flex w-full md:w-[65%] 2xl:w-[70%]">
    //         <p className="text-base font-normal flex py-5 text-white align-top w-[70%] ">{name}</p>
    //         <p className="text-base font-normal flex py-5 text-white justify-end md:justify-start align-top w-[50%] md:w-[30%] ">${price} USD</p>
    //     </div>
    //     <div className="flex w-full md:w-[35%] 2xl:w-[30%]">
    //         <p className="text-base font-normal pt-0 pb-5 md:py-5 text-white align-top flex-1 w-full md:w-[30%] ">{description}</p>
    //     </div>
    // </div>
);

export default Info;
