import React, { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { DefaultImageJPG } from "src/theme/Images";
import { Swiper as SwiperType } from "swiper/types";
import { format, parse } from "date-fns";
import { usePhone } from "../../../../hooks/usePhone";
import { Plus, Minus } from "@untitled-ui/icons-react/build/cjs";
// import { Plus } from "@untitled-ui/icons-react";

interface Props {
    data: any;
    sectionIndex: number;
}
const Location: React.FC<Props> = ({ data, sectionIndex }) => {
    const { getCustomFormatPhone } = usePhone();
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 1, isShow: true });
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const formatTimeRange = (hours: any) => {
        const from = format(parse(hours.from, "HH:mm:ss", new Date()), "h:mmaaa");
        const to = format(parse(hours.to, "HH:mm:ss", new Date()), "h:mmaaa");
        return `${from.toLowerCase()} → ${to.toLowerCase()}`;
    };

    return (
        <div className="">
            <div className="w-full flex items-center justify-between mb-4">
                <div className="slice-label">
                    <span className="num">{sectionIndex}</span>
                    <span className="slice-text">Location</span>
                </div>
                <div className="flex relative ">
                    <button className="rounded-full p-2 border border-txtcolor cursor-pointer mr-3 bg-contentBackground" onClick={handlePrevious}>
                        <FaChevronLeft className="text-gray-600 text-lg text-txtcolor" />
                    </button>
                    <button className="rounded-full  p-2 border border-txtcolor cursor-pointer bg-contentBackground " onClick={handleNext}>
                        <FaChevronRight className="text-gray-600 text-lg text-txtcolor" />
                    </button>
                </div>
            </div>

            <div className="flex flex-col items-center pb-5">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    loop={true}
                    onSwiper={setSwiperRef}
                    navigation={false} // Disable Swiper default navigation
                    className="mySwiper w-full"
                    // Apply dynamic height here
                >
                    {data?.locations?.map((location: any, index: React.Key | null | undefined) => (
                        <SwiperSlide key={index}>
                            <>
                                <div className="w-full ">
                                    <img className="w-full aspect-[2.64/1]" src={`${location?.is_image && location.image_url ? location.image_url : DefaultImageJPG}`} alt="" />
                                    {/* <img
                                        className="w-full h-[250px] md:h-[250px] lg:h-[295px] xl:h-[375px] 2xl:h-[425px] 3xl:h-[480px]"
                                        src={`${location?.is_image && location.image_url ? location.image_url : DefaultImageJPG}`}
                                        alt="Barbering"
                                    /> */}
                                </div>
                                <div className="my-8">
                                    <h2 className="text-base md:text-lg xl:text-xl font-normal uppercase  text-txtcolor opacity-[.60] mt-3">We are open Monday to friday</h2>
                                    <div className="flex flex-col w-full gap-y-3 mt-3 pr-2">
                                        {location?.hours
                                            ?.filter((dataInfo: any) => dataInfo.status === true)
                                            .map((dataInfo: any, dayIndex: React.Key | null | undefined) => (
                                                <div key={`dayIndex${dayIndex}`} className="flex flex-col h-fit ">
                                                    <div className="border-b pb-2 ">
                                                        <div
                                                            className="flex justify-between items-center cursor-pointer text-txtcolor "
                                                            onClick={() =>
                                                                setIsCollapsed({
                                                                    isShow: isCollapsed.isShow && isCollapsed.id === `dayIndex${dayIndex}` ? false : true,
                                                                    id: `dayIndex${dayIndex}`,
                                                                })
                                                            }
                                                        >
                                                            <span className="text-gray-800 text-txtcolor uppercase">{dataInfo.day}</span>
                                                            {/* <span className="text-xl font-thin text-txtcolor">
                                                                {isCollapsed.isShow && isCollapsed.id === `dayIndex${dayIndex}` ? <Minus /> : <Plus />}
                                                            </span> */}
                                                            <div className="text-gray-800 text-txtcolor uppercase">{formatTimeRange(dataInfo)}</div>
                                                        </div>
                                                        {/* {isCollapsed.isShow && isCollapsed.id === `dayIndex${dayIndex}` && (
                                                            <div className="text-sm text-txtcolor font-light max-w-[90%] mt-[6px]">{formatTimeRange(dataInfo)}</div>
                                                        )} */}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="flex flex-row mt-6 justify-between pr-2">
                                        <div className="flex flex-col">
                                            <h2 className="text-base md:text-lg xl:text-xl font-normal uppercase text-txtcolor opacity-[.60]">Location </h2>
                                            <h2 className="text-base md:text-lg xl:text-xl font-medium uppercase text-txtcolor ">{location.title}</h2>
                                        </div>
                                        <div className="flex flex-col">
                                            <h2 className="text-base md:text-lg xl:text-xl font-normal uppercase text-txtcolor opacity-[.60]">CONTACT</h2>
                                            <div className="text-base md:text-lg xl:text-xl font-medium uppercase text-txtcolor">{getCustomFormatPhone(location.contact, location?.country_code)}</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default Location;
