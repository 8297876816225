import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomButton from "src/components/CustomButton";
import OTPField from "src/components/OTPField/OTPField";
import { ChevronLeft } from "@untitled-ui/icons-react/build/cjs";
import { useTranslation } from "react-i18next";
import { IVerifyOtp } from "../Login.interface";

const WVerifyOtp: FC<IVerifyOtp> = ({ authData, loading, handleKeyPress, handlerLoginOtpAgainSend, handleChangePhoneEmail, handleAction }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const otpWatch = watch("otp");
    return (
        <div className="font-primary model-inner-wrapper" onKeyDown={handleKeyPress}>
            <h2 className="login-model-title">{authData.isEmail ? t("Verify your email address") : t("Verify your phone number")}</h2>
            <div className="flex flex-col">
                <span className="login-model-subtitle"> {t("A 4-digit code has been sent to you at")}</span>
                <span className="login-model-subtitle-dec">{authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}</span>
            </div>

            <div className="mx-auto mt-[30px]">
                <Controller
                    name="otp"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col">
                            <OTPField otp={value} setOtp={onChange} errors={error} />
                            {error && <p className="text-error text-center">{error.message}</p>}
                        </div>
                    )}
                />
                {authData.isEmail && <div className="text-textGrey text-center text-sm font-normal mt-1.5 -tracking-[0.02rem]">{t("Tip: Be sure to check your inbox and spam folders")}</div>}
                <div className="mt-[24px] flex flex-col items-center">
                    <div className="flex items-center justify-center gap-1">
                        <p className="text-textGrey text-sm font-normal -tracking-[0.02rem] leading-[19.6px]">{t("Didn’t receive code")}? </p>
                        <CustomButton
                            type="button"
                            disabled={loading}
                            onClick={handlerLoginOtpAgainSend}
                            className="text-primary !border-0 !p-0 !h-auto leading-[19.6px] !font-semibold !bg-transparent text-sm text-center flex flex-col ring-0"
                            shadow={false}
                        >
                            {t(" Resend Code")}
                        </CustomButton>
                    </div>
                    {authData.email && authData.phone && (
                        <CustomButton
                            type="button"
                            className="!border-0 !bg-transparent text-center ring-0 leading-[19.6px] mt-[24px] !p-0 text-sm  text-primary font-bold"
                            shadow={false}
                            onClick={handleChangePhoneEmail}
                        >
                            {authData.isEmail ? t("Changed your email?") : t("Changed your mobile number?")}
                        </CustomButton>
                    )}
                </div>
                <div className="mt-[52px] w-full bottom-0 flex flex-col">
                    <CustomButton
                        type="button"
                        icon={<ChevronLeft width={20} />}
                        className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-gray-100 !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                        shadow={false}
                        onClick={authData.email && authData.phone ? handleChangePhoneEmail : handleAction("login")}
                    ></CustomButton>
                    <CustomButton type="submit" className="w-full !h-[48px]" primary disabled={loading || (otpWatch.length !== 4 ? true : false)} isLoading={loading}>
                        <span> {t("Continue")}</span>
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default WVerifyOtp;
