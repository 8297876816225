import React from "react";
import Template2 from "./Template2";
import Template1 from "./Template1";
import { useAppSelector } from "../../redux/hooks";
import PersonalTemplate1 from "./PersonalTemplate1";

const Landing = () => {
    const template = useAppSelector((data) => data.Common.template);

    return (
        <>
            {template === "template_1" && <Template1 />}
            {template === "template_2" && <Template2 />}
            {/* <PersonalTemplate1 /> */}
        </>
    );
};

export default Landing;
