import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CustomButton from "src/components/CustomButton";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputWithLabel from "src/components/InputWithLabel";
import { GLOBALVARIABLE, errorCode } from "src/constants/common";
import { s3Upload } from "src/utils/s3Operations";
import { s3Path } from "src/utils/s3Path";
import { axiosPatch } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { checkFileTypeValidation, convertBase64ToFile, getShortName } from "src/utils/global-functions";
import { format, parse } from "date-fns";
import Number from "src/components/Number";
import { currentUser, me } from "src/redux/reducers/common/Common.slice";
import { Trash01 } from "@untitled-ui/icons-react/build/cjs";
import DateSelect from "src/components/DateSelect/DateSelect";
import { iconDateCalendar } from "src/theme/Images";
import CropperModal from "src/components/CropperModal/CropperModal";
import FloatingInputbox from "src/components/FloatingInputbox";
import MAccountSetting from "./MAccountSetting";
import WAccountSetting from "./WAccountSetting";
interface ILanding {
    handleClose: () => void;
}
interface IFormInput {
    first_name: string;
    last_name: string;
    profile_image_name?: any;
    birthdate: string;
    // gender: string;
}
interface IGenderOptions {
    value: string;
    label: string;
}
const AccountSetting = ({ handleClose }: ILanding) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    // const [customGenderOption, setCustomGenderOption] = useState<IGenderOptions[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [upload, setUpload] = useState(false);
    const imageUrl = user && user.profile_image_name ? user.profile_image_url : null;
    const [cropData, setCropData] = useState<any>(imageUrl || "");
    const [image, setImage] = useState<any>("");
    const [file, setFile] = useState<any>({ convertedFile: "", filename: "" });

    const schema = Yup.object({
        first_name: Yup.string().required(t("This field is required")),
        last_name: Yup.string().required(t("This field is required")),
        profile_image_name: Yup.object().nullable(),
        /* profile_image_name: Yup.object()
            .nullable()
            .shape({
                name: Yup.string().required(t("This field is required")),
                file: Yup.string().nullable(),
                url: Yup.string().required(t("This field is required")),
            })
            .test("image-validation", t("This field is required"), function (value) {
                if (!value) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t("This field is required"),
                    });
                }
                const { name, url } = value;
                if (!name || !url) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t("This field is required"),
                    });
                }
                return true;
            }), */
        birthdate: Yup.string().required(t("This field is required")),
        // gender: Yup.string().required(t("Please select gender")),
    }).required();

    const methods = useForm<IFormInput>({
        resolver: yupResolver(schema) as Resolver<IFormInput>,
        defaultValues: {
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            profile_image_name: user?.profile_image_url ? user?.profile_image_name : null,
            birthdate: user?.birthdate ? format(parse(user.birthdate, "yyyy-MM-dd", new Date()), "dd-MM-yyyy") : "",
            // gender: user?.gender,
        },
    });
    const { control, handleSubmit, setError, setValue, clearErrors } = methods;

    // useEffect(() => {
    //     const userGender = user?.gender ?? "";
    //     const foundOption = GENDER_OPTIONS.find((go) => go.value === userGender);
    //     if (!foundOption && userGender) {
    //         const customOption = {
    //             label: userGender,
    //             value: userGender,
    //         };
    //         setCustomGenderOption([...GENDER_OPTIONS, customOption]);
    //     } else {
    //         setCustomGenderOption(GENDER_OPTIONS);
    //     }
    // }, [user]);

    const handleChange = async (data: IFormInput) => {
        axiosPatch(API.USER.PROFILE, data)
            .then(async () => {
                await dispatch(me());
                // handleClose();
                return;
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormInput, {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        return;
                    }
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (data: IFormInput) => {
        setIsLoading(true);
        if (file.convertedFile && file?.filename) {
            const logoImageResponse: any = await s3Upload(file.convertedFile, `${s3Path.USER_PROFILE}${file?.filename}`);
            const imageStatus = logoImageResponse?.status;
            if (imageStatus === errorCode.updateSuccess) {
                const updatedData = {
                    ...data,
                    profile_image_name: file?.filename,
                    birthdate: format(parse(data.birthdate, "dd-MM-yyyy", new Date()), "Y-MM-dd"),
                };
                handleChange(updatedData);
            } else {
                setIsLoading(false);
            }
        } else {
            const { profile_image_name: profileImageName, ...dataWithoutProfileImage } = data;
            const updatedData = {
                ...dataWithoutProfileImage,
                birthdate: format(parse(data.birthdate, "dd-MM-yyyy", new Date()), "Y-MM-dd"),
            };
            handleChange(updatedData);
        }
    };

    const onChangeCrop = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);
        if (result) {
            clearErrors("profile_image_name");
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage("");
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError("profile_image_name" as "profile_image_name", {
                type: "manual",
                message: message,
            });
        }
    };

    const imageUploadFun = (data: any) => {
        const fileInfo = convertBase64ToFile(data);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue("profile_image_name", fileInfo.filename);
        }
    };

    const handleRemove = () => {
        setFile({ convertedFile: "", filename: "" });
        setCropData("");
        setValue("profile_image_name", null);
    };

    return (
        <div className="relative h-full w-full flex flex-col font-primary">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)} className="flex flex-col flex-1 px-4 lg:px-5">
                    <div className="flex flex-col mt-1 lg:mt-[20px] ">
                        <div className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                            Account Settings
                        </div>
                        <div className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">Manage your profile and security</div>
                    </div>
                    {uiState.isTablet ? <WAccountSetting /> : <MAccountSetting />}
                    <div className="w-full mt-4 flex flex-col">
                        <div className="flex flex-wrap">
                            {uiState.isTablet ? <label className="text-txtAppointmentColor text-sm font-normal leading-[18.2px] mb-1.5 -tracking-[0.01rem] block">Phone Number </label> : ""}
                            <Number getNumber={() => {}} onNumberChange={() => {}} disabled defaultCode={user?.phone_country_code} defaultPhone={user?.phone} />
                        </div>
                    </div>
                    <div className="mt-[16px] relative mb-5">
                        <Controller
                            name="birthdate"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="w-full relative date_of_birth flex flex-col">
                                    <DateSelect
                                        label={uiState.isTablet ? t("Date of birth") : ""}
                                        inputName={"birthdate"}
                                        className="form_control_date w-full form_input_control"
                                        placeholder="DD-MM-YYYY"
                                        dateFormat="dd-MM-yyyy"
                                        onChangeValue={onChange}
                                        InputClass={`${error ? "is-invalid" : ""}`}
                                        popperPlacement="top"
                                        icon={<img src={iconDateCalendar} alt="" className="w-5" />}
                                        value={value}
                                        isMobileView={uiState.isTablet ? false : true}
                                    />
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                    </div>
                    <div className="mt-auto border-t border-borderColorPrimary flex gap-3 bg-containerBgColor pt-4 mb-4 -mx-[16px] px-[16px] lg:-mx-[20px] lg:px-[20px]">
                        <CustomButton outlineSecondary className="w-1/2" disabled={isLoading} onClick={handleClose}>
                            Cancel
                        </CustomButton>
                        <CustomButton primary className="w-1/2" type="submit" isLoading={isLoading} disabled={isLoading}>
                            Save changes
                        </CustomButton>
                    </div>
                </form>
            </FormProvider>
            {upload && (
                <CropperModal
                    imageUpload={imageUploadFun}
                    imageUrl={image}
                    setUpload={setUpload}
                    setCropData={setCropData}
                    defaultCropType="Square"
                    title="Crop Image"
                    description="Upload a 400 x 400px image for best results."
                    isCropType={false}
                />
            )}
        </div>
    );
};

export default AccountSetting;
