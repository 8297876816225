import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addProduct, getBookingInfo, removeProduct, setPaymentOption, setPaymentType, setStep } from "../Booking.slice";
import { useTranslation } from "react-i18next";
import ProductCard from "./ProductCard";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import ProductDetail from "./ProductDetail";
import { IInitAction, initAction } from "./Product.interface";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { EBookingTabState } from "../bookAppointment.interface";
import PopupModal from "src/components/PopupModal";
import Sidebar from "src/components/Sidebar";
import CustomButton from "src/components/CustomButton";
import { Spinner } from "flowbite-react";
import { useTrail, useTransition, animated } from "react-spring";

const ProductBooking = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const booking = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const shop = useAppSelector(currentShop);
    const [allProducts, setAllProducts] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [product, setProduct] = useState<any>(null);
    const [productVariant, setProductVariant] = useState<any>({});
    const [action, setAction] = useState<IInitAction>(initAction);
    const [isLoading, setIsLoading] = useState({ data: false, process: false });

    const productTransitions = useTrail(products.length, {
        from: { opacity: 0, transform: "translateY(20px)" },
        to: { opacity: 1, transform: "translateY(0px)" },
        config: { tension: 220, friction: 20 },
        trail: 30,
    });

    const selectedTransitions = useTransition(
        booking.selectedProducts.map((productData, index) => ({ ...productData, index })),
        {
            key: (item: any) => item.index,
            from: { opacity: 0, transform: "scale(0)" },
            enter: { opacity: 1, transform: "scale(1)" },
            leave: { opacity: 0, transform: "scale(0)" },
            exitBeforeEnter: true,
            config: { easing: (time: any) => time * (2 - time), duration: 300 },
        },
    );

    useEffect(() => {
        dispatch(setStep(EBookingTabState.PRODUCT_BOOKING));
        getProducts();
        dispatch(setPaymentType("cash"));
        dispatch(setPaymentOption("pay_later"));
    }, []);

    useEffect(() => {
        if (!uiState.isMobile) {
            handleClose();
        }
    }, [uiState.isMobile]);

    useEffect(() => {
        const processProducts = async () => {
            setIsLoading((prev: any) => ({ ...prev, process: true }));
            try {
                if (allProducts.length) {
                    let aggregatedProducts = booking.selectedProducts.reduce((acc: any, item: any) => {
                        const id = item.selectedVariant.inventory.id;
                        if (acc[id]) {
                            acc[id].quantity += 1;
                        } else {
                            acc[id] = {
                                ...item,
                                id,
                                quantity: 1,
                            };
                        }
                        return acc;
                    }, {});
                    aggregatedProducts = Object.values(aggregatedProducts);

                    let filteredProducts = [];

                    if (booking.productCategoryFilterValue) {
                        filteredProducts = allProducts.filter((ProductDetails) => ProductDetails.category.id === booking.productCategoryFilterValue.value);
                    }

                    const productsToProcess = filteredProducts.length > 0 ? filteredProducts : allProducts;

                    const updatedProducts = productsToProcess.reduce((acc: any[], allProduct: any) => {
                        const variants = allProduct.variants.reduce((variantsAcc: any[], variant: any) => {
                            const inventories = variant.inventories.reduce((inventoriesAcc: any[], inventory: any) => {
                                const aggregatedProduct = aggregatedProducts.find((item: any) => item.id === inventory.id);
                                const quantity = inventory.in_stock_quantity - (aggregatedProduct ? aggregatedProduct.quantity : 0);

                                if (quantity > 0) {
                                    inventoriesAcc.push({ ...inventory, in_stock_quantity: quantity });
                                }
                                return inventoriesAcc;
                            }, []);

                            if (inventories.length > 0) {
                                variantsAcc.push({ ...variant, inventories, inventory: inventories[0] });
                            }
                            return variantsAcc;
                        }, []);

                        if (variants.length > 0) {
                            acc.push({ ...allProduct, variants });
                        }
                        return acc;
                    }, []);

                    setProducts(updatedProducts);
                }
            } finally {
                setIsLoading((prev: any) => ({ ...prev, process: false }));
            }
        };
        processProducts();
    }, [booking, allProducts]);

    const getProducts = async () => {
        setIsLoading((prev: any) => ({ ...prev, data: true }));
        const params = {
            shop_id: shop.id,
            location_id: booking.selectedShopLocation?.id,
        };
        const payload = {
            booking_id: booking.modifyingAppointmentId || null,
        };
        await axiosGet(API.PRODUCT.LIST, payload, params)
            .then((response) => setAllProducts(response.data.data))
            .finally(() => setIsLoading({ process: true, data: false }));
    };

    const handleAction = (type: keyof IInitAction, data?: any) => (event: any) => {
        event.stopPropagation();
        if (type === "product") {
            setProduct(data);
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
        setProductVariant({});
    };

    const manageCart =
        (type: string, index: number = 0) =>
        (item: any, variant: any) =>
        () => {
            if (type === "add") {
                dispatch(addProduct({ ...item, selectedVariant: variant }));
                handleClose();
            } else {
                dispatch(removeProduct(index));
            }
        };

    return (
        <>
            <div className={`relative h-[calc(100%-160px)] lg:h-[calc(100%-70px)] ${Object.values(isLoading).includes(true) ? "lg:pt-[12px]" : "lg:pt-[36px]"}`}>
                {Object.values(isLoading).includes(true) ? (
                    <div className="custom_loading_wrapper">
                        <div className="custom_loading"></div>
                    </div>
                ) : (
                    <>
                        <div className="overflow-y-scroll scrollbar-hide h-full max-h-full">
                            {booking.selectedProducts.length > 0 && (
                                <>
                                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 3xl:grid-cols-5 pt-[5px] max-lg:px-2">
                                        {selectedTransitions((style, productData) => (
                                            <animated.div key={productData.index} style={style}>
                                                <ProductCard
                                                    key={`${productData.id} - ${productData.index}`}
                                                    product={productData}
                                                    onClick={manageCart("remove", productData.index)}
                                                    isselected
                                                    handleAction={handleAction}
                                                    isSelected
                                                />
                                            </animated.div>
                                        ))}
                                    </div>
                                    <div className="font-semibold font-base text-txtAppointmentColor pb-4 px-4 lg:px-2 -tracking-[0.03rem]">{t("Add More products to Your Cart")}</div>
                                </>
                            )}

                            <div className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 3xl:grid-cols-5 content-baseline max-lg:px-2`}>
                                {productTransitions.map((style, index) => (
                                    <ProductCard key={products[index].id} product={products[index]} onClick={manageCart("add")} handleAction={handleAction} style={style} />
                                ))}
                            </div>
                        </div>
                        {/* <Sidebar isOpen={action.product} isBottomView={uiState.isMobile} position={uiState.isMobile ? "bottom" : "right"} size={uiState.isMobile ? "" : "w-[460px]"} handleClose={handleClose}>
            </Sidebar> */}
                    </>
                )}
            </div>
            {uiState.isMobile && <ManageSubmit />}
            {uiState.isMobile && action.product ? (
                <Sidebar isOpen={action.product && uiState.isMobile} position="bottom" handleClose={handleClose} isBottomView customClass="" size="w-[460px]" dismissable>
                    <div className="w-full flex flex-1 relative max-h-[calc(100vh-170px)]  bg-contentBackground ">
                        <ProductDetail data={product} productVariant={productVariant} setProductVariant={setProductVariant} />
                    </div>
                    <div className="flex w-full gap-3 absolute bottom-0 mb-5 bg-contentBackground">
                        <CustomButton className="w-full" secondary onClick={handleClose}>
                            Cancel
                        </CustomButton>
                        <CustomButton primary className="w-full" onClick={manageCart("add")(product, productVariant)}>
                            Continue
                        </CustomButton>
                    </div>
                </Sidebar>
            ) : (
                action.product && (
                    <PopupModal
                        onClose={handleClose}
                        size="max-w-[95%] min-w-[340px] mx-auto lg:w-[650px] font-primary outline-custom"
                        className="!pt-5 !pb-0"
                        isCrossSign={false}
                        primaryButton={t("Add to Order")}
                        secondaryButton={t("Cancel")}
                        acceptAction={manageCart("add")(product, productVariant)}
                        declineAction={handleClose}
                        dismissible
                        position="center"
                    >
                        <ProductDetail data={product} productVariant={productVariant} setProductVariant={setProductVariant} />
                    </PopupModal>
                )
            )}
        </>
    );
};

export default ProductBooking;
