import React, { useEffect } from "react";
import { RouteProps, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { ROUTES } from "src/constants/routes";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { useShopNavigation } from "src/hooks/useShopNavigation";

type NotGuardedRouteProps = {
    Component: React.ComponentType<any>;
    title?: string;
} & RouteProps;

const NotGuardedRoute: React.FC<NotGuardedRouteProps> = ({ Component, title }) => {
    const shop = useAppSelector(currentShop);
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const settings: any = useAppSelector((data) => data.Common.settings)?.find((setting: any) => setting.type === "template")?.value;
    const { pathname } = useLocation();
    useEffect(() => {
        document.title = `${title} - ${shop.business_name}`;
    }, [title, shop]);

    useEffect(() => {
        if (pathname === ROUTES.HOME && settings?.length !== 0 && !settings?.is_active_template) {
            navigateBasedOnShopLocations();
        }
    }, [settings]);

    // always allowing visit to all the pages
    return <Component />;
};

export default NotGuardedRoute;
