import React from "react";
import { Button, Spinner } from "flowbite-react";

interface IProps {
    onClick?: () => void;
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    buttonColor?: string;
    isRoundedCorner?: boolean;
    loading?: boolean;
    size?: string;
    type?: "submit" | "button" | "reset" | undefined;
}

const ButtonCmp = ({ children, onClick = undefined, className = "", disabled = false, buttonColor = "primary", isRoundedCorner = true, loading = false, type = "submit", size = "sm" }: IProps) => (
    <Button
        className={`text-base rounded-lg focus:outline-none focus-within:outline-none focus-visible:outline-none ${className}`}
        onClick={onClick}
        pill={isRoundedCorner}
        color={buttonColor}
        disabled={disabled}
        size={size}
        type={type}
    >
        {loading && <Spinner aria-label="Alternate spinner button example" className="w-5 h-5" />}
        <span className={`${loading ? "pl-2" : "pl-0"}`}>{children}</span>
    </Button>
);

export default ButtonCmp;
