import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

const Loader = () => (
    <>
        <div className="custom-ProgressSpinner max-w-sm dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700 flex-1 flex h-[100vh]">
            <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                {/* <span className={`loading loading-spinner text-gray-600 h-10 w-10 ${spinnerClass}`}></span> */}
                <div className="card flex justify-center item-center">
                    <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="5" animationDuration="1s" />
                </div>
            </div>
        </div>
    </>
);

export default Loader;
