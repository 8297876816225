import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FloatingInputbox from "src/components/FloatingInputbox";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";

const MAccountSetting = () => {
    const { t } = useTranslation();
    const user = useAppSelector(currentUser);
    const { control } = useFormContext();
    return (
        <div className="w-full flex flex-col gap-4 mt-4">
            <Controller
                name="first_name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className="flex w-full flex-col">
                        <FloatingInputbox label={t("First Name")} type="text" handleChange={onChange} value={value} name="first_name" />
                        {error && <p className="text-error">{error.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="last_name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className="flex w-full flex-col">
                        <FloatingInputbox label={t("Last Name")} type="text" handleChange={onChange} value={value} name="last_name" />
                        {error && <p className="text-error">{error.message}</p>}
                    </div>
                )}
            />

            <div className="w-full flex flex-col">
                <FloatingInputbox label={t("Email")} disabled type="text" handleChange={() => {}} value={user?.email as string} name="email" />
            </div>
        </div>
    );
};

export default MAccountSetting;
