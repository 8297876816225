import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { DefaultUserImagePNG, UnionSVG } from "src/theme/Images";
import { useTranslation } from "react-i18next";
import { ISelectedTab } from "../../../../Interface";
import { selectTab, setIsScrollable } from "../../../../redux/reducers/Landing.slice";
import Profile from "../../../Profile";
import { useLocation } from "react-router-dom";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { IInitAction, initAction, navLinks } from "./Header.interface";
import CompleteProfile from "src/app/Auth/CompleteProfile/CompleteProfile";
import Auth from "src/app/Auth";
import { ROUTES } from "src/constants/routes";
import { currentSettings, currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { Menu02, User01, XClose } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import SelectBox from "src/components/SelectBox";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import ConditionalNoShowView from "src/app/BookAppointment/ConditionalNoShowView";

const Header: React.FC<any> = () => {
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const user = useAppSelector(currentUser);

    const shop = useAppSelector(currentShop);
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const activeTab = useAppSelector((data) => data.LandingStates.initSelectedTab);
    const settings = useAppSelector(currentSettings);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [action, setAction] = useState<IInitAction>(initAction);
    const [nav, setNav] = useState(false);
    const [template, setTemplate] = useState<string[]>([]);
    const [appearance, setAppearance] = useState<any>(null);
    const [language, setLanguage] = useState("eng");

    const languageOptions = [
        { label: "ENG", value: "eng" },
        { label: "FRA", value: "fra" },
    ];

    useEffect(() => {
        if (settings.length) {
            const templates = settings.find((setting: any) => setting.type === "template");
            if (templates) {
                setTemplate(extractKeysWithStatusTrue(templates.value));
            }

            const appearances = settings.find((setting: any) => setting.type === "appearance");
            if (appearances) {
                setAppearance(appearances.value);
            }
        }
    }, [settings]);

    useEffect(() => {
        if (user) {
            const isAccountComplete = user.is_account_complete;
            if (!isAccountComplete) {
                handleAction("completeProfile")();
            }
        }
    }, [user]);

    const extractKeysWithStatusTrue = (obj: any) => {
        const result = [];
        for (const key in obj) {
            if (obj[key].status === true) {
                result.push(key);
            }
        }
        return result;
    };

    const handleNavigate = (tabName: ISelectedTab) => () => {
        dispatch(setIsScrollable(true));
        dispatch(selectTab(tabName));
        location.pathname !== ROUTES.HOME && navigate(ROUTES.HOME, { state: { selectTab: tabName, isScrollable: true } });
    };

    const handleAction = (type: keyof IInitAction) => () => {
        if (nav) {
            handleNav();
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleLogin = () => {
        if (nav) {
            handleNav();
        }
        dispatch(setIsShowLoginModal(true));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const handleNav = () => {
        setNav(!nav);
    };

    const getLogo = () => {
        let response;
        let logo;
        let text;

        if (shop) {
            const logoHeight = appearance ? `${appearance?.logo_height}px` : "50px";
            logo = (
                <img
                    src={shop.logo_image_url ?? UnionSVG}
                    className="max-w-[120px] me-2 object-contain"
                    alt=""
                    style={{
                        height: logoHeight,
                        minHeight: logoHeight,
                        maxHeight: logoHeight,
                    }}
                />
            );
            text = (
                <p className={`text-txtcolor hover:text-primary  cursor-pointer text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] flex items-center font-logo font-logoWeight`}>
                    {shop.business_name}
                </p>
            );

            if (appearance) {
                switch (appearance.logo_type) {
                    case "logo":
                        response = logo;
                        break;
                    case "logo_text":
                        response = (
                            <>
                                {logo} {text}
                            </>
                        );
                        break;
                    case "text":
                        response = text;
                        break;
                    default:
                        break;
                }
            }
        }

        return response;
    };

    const handleLanguage = (selectedOption: any) => {
        setLanguage(selectedOption.value);
    };

    const handleBookNow = () => {
        if (nav) {
            handleNav();
        }
        navigateBasedOnShopLocations();
    };

    return (
        <div className={`flex justify-between border-b border-borderColorPrimary items-center px-4 sticky lg:px-8 min-h-[60px]  h-[60px] lg:h-[60px] w-full z-[9999] bg-contentBackground top-0`}>
            <div className="items-center cursor-pointer justify-between  w-full md:w-auto flex">
                <div className="flex items-center" onClick={() => navigate("/")}>
                    {getLogo()}
                </div>
                <div className="flex flex-row justify-end pl-2.5 ml-3  lg:hidden items-center">
                    {user ? (
                        <div className="flex gap-2">
                            <div onClick={handleAction("profile")} className="flex cursor-pointer items-center rounded-secondary w-9 h-9">
                                <img src={user?.profile_image_url ?? DefaultUserImagePNG} className="w-full h-full rounded-primary" alt="" />
                            </div>
                        </div>
                    ) : (
                        <CustomButton secondary size="w-full" className="btn-appointment-primary text-primary px-5 !py-[2px] !h-[32px] text-sm whitespace-nowrap" onClick={handleLogin}>
                            {t("Login")}
                        </CustomButton>
                    )}
                    <div className="w-[35px] justify-end pl-2.5 ml-3 border-l items-center lg:hidden border-borderColorPrimary" onClick={handleNav}>
                        {nav ? <XClose color="var(--brand-color)" className="w-[24px]" /> : <Menu02 className="w-[24px]" color="var(--brand-color)" />}
                    </div>
                </div>
            </div>
            <ul
                className={
                    nav
                        ? `ease-in-out right-0 z-[99999] absolute h-[100vh] top-[60px] bg-contentBackground flex flex-col lg:flex-row lg:justify-between lg:items-center lg:h-[44px] 2xl:h-[44px] lg:top-0 xl:h-[45px] space-y-3 lg:space-y-0 lg:space-x-4 xl:space-x-5 duration-500 lg:sticky pl-[12px] pr-4 lg:pr-0 pt-[12px] lg:pl-[0px] lg:pt-[0px] min-w-[240px] justify-start navbar-duration items-start border-l lg:border-l-0 border-borderColorPrimary *:w-full`
                        : " right-0 z-[99999] absolute h-[100vh] top-[45px] bg-contentBackground  flex-col lg:flex-row lg:justify-between lg:items-center lg:h-[44px] 2xl:h-[44px] lg:top-0 xl:h-[45px]  lg:sticky pl-[12px] pt-[12px] lg:pl-[0px] lg:pt-[0px] min-w-[240px] justify-start navbar-duration  space-x-3 lg:space-x-3 xl:space-x-5 navbar-duration ease-in-out duration-500 hidden lg:flex items-center"
                }
            >
                {navLinks
                    .filter(({ tabName }) => template.includes(tabName))
                    .map(({ name, tabName }) => (
                        <li
                            key={tabName}
                            className={`hover:text-primary font-normal cursor-pointer  text-[14px] -tracking-[-0.01rem] whitespace-nowrap ${activeTab[tabName] ? "text-primary" : "text-txtcolor"}`}
                            onClick={handleNavigate(tabName)}
                        >
                            <span onClick={handleNav}>{name}</span>
                        </li>
                    ))}
                <li>
                    <SelectBox
                        options={languageOptions}
                        value={languageOptions.find((option) => option.value === language)}
                        onChange={handleLanguage}
                        inputClass="outline-select-box rounded-secondary navigation-select"
                        allowClear={false}
                        searchable={false}
                        disabled={false}
                        name={"language"}
                    />
                </li>
                {/* <li className="hover:text-primary font-normal cursor-pointer mt-[7px] text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] text-txtcolor uppercase">
                    <span onClick={handleNav}>(ENG / FRA)</span>
                </li> */}
                <li>
                    <CustomButton
                        primary
                        size="w-full"
                        className="bg-primary text-white  flex items-center !px-4 !py-[2px] !h-[30px] !text-[12px] rounded-secondary  whitespace-nowrap"
                        onClick={handleBookNow}
                    >
                        Book Now
                    </CustomButton>
                </li>
                {user ? (
                    <li className="hidden lg:flex">
                        <div className="flex gap-2">
                            <div onClick={handleAction("profile")} className="flex cursor-pointer items-center rounded-secondary w-9 h-9">
                                {user?.profile_image_url ? (
                                    <img src={user.profile_image_url} className="w-full h-full rounded-primary" alt="" />
                                ) : (
                                    <div className="w-full h-full rounded-primary bg-primaryHover flex items-center justify-center">
                                        <User01 className="text-primary w-5 h-5" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                ) : (
                    <li className="hidden lg:flex">
                        <CustomButton secondary size="w-full" className="btn-appointment-primary text-primary !px-3 !py-[2px] !h-[30px] !text-[12px] text-sm whitespace-nowrap" onClick={handleLogin}>
                            {t("Login")}
                        </CustomButton>
                    </li>
                    // <button
                    //     className="hover:text-primary font-normal cursor-pointer mt-[7px] text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] text-txtcolor uppercase"
                    //     onClick={handleLogin}
                    // >
                    //     <span onClick={handleNav} className="`">
                    //         {t("Login")}
                    //     </span>
                    // </button>
                )}
                {AuthUserState.isShowLoginModal && <Auth />}
                {user && <ConditionalNoShowView />}
                <Profile handleClose={handleClose} isOpen={action.profile} />
                {action.completeProfile && <CompleteProfile handleClose={handleClose} />}
            </ul>
        </div>
    );
};

export default Header;
