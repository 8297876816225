import React, { useEffect, useState } from "react";
import { Pluse } from "../../../../components/SvgColor/svgIcons";
import DateSelectButton from "../../../../components/DateSelectButton";
import ButtonCmp from "../../../../components/ButtonCmp";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import RangeCalnedarComponent from "./RangeCalnedarComponent";
import { format } from "date-fns";
import { axiosPost } from "../../../../utils/requestClient";
import { API } from "../../../../constants/api";
import { toast } from "react-toastify";
import { getNextAvailableDay, getNextDayOfWeek } from "../../../../utils/global-functions";
import { getBookingInfo, setSelectedBookingDate, setTempStore } from "../../Booking.slice";
import { ISpecialist, ISpecialistService, IWorkingHour } from "../../../../Interface";
import "./datepicker_mobile.scss";
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { useTranslation } from "react-i18next";
import { BiSun } from "react-icons/bi";
import { BsFillSunsetFill, BsMoonFill } from "react-icons/bs";
import moment from "moment";
import ManageSubmit from "../../AppointmentPreview/ManageSubmit";
import { animated, config, useSpring, useTransition } from "react-spring";
import { Spinner } from "flowbite-react";
import { PlusCircle } from "@untitled-ui/icons-react/build/cjs";
import { isGetApp } from "src/redux/reducers/Landing.slice";

interface IProps {
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    selectedTimeSlot: string | undefined;
    setSelectedTimeSlot: React.Dispatch<React.SetStateAction<string | undefined>>;
    closeCurrentTab: () => void;
}
interface IPeriods {
    name: "all" | "morning" | "afternoon" | "evening";
    label: string;
    start: string;
    end: string;
    icon?: React.ReactNode;
}
const initPeriods: IPeriods[] = [
    { name: "all", label: "All", start: "00:00", end: "23:59" },
    { name: "morning", label: "Morning", start: "06:00", end: "11:59", icon: <BiSun className="w-4 h-4 mr-1.5" /> },
    { name: "afternoon", label: "Afternoon", start: "12:00", end: "16:59", icon: <BsFillSunsetFill className="w-4 h-4 mr-1.5" /> },
    { name: "evening", label: "Evening", start: "17:00", end: "23:59", icon: <BsMoonFill className="w-3 h-3 mr-1.5" /> },
];
const DateTimePick = ({ selectedDate, setSelectedDate, selectedTimeSlot, setSelectedTimeSlot }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [slots, setSlots] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState<IPeriods>(initPeriods[0]);
    const [workingDays, setWorkingDays] = useState<string[]>([]);
    const seletedDateFirstRender = useFirstRender({ selectedDate });
    const [periods, setPeriods] = useState<IPeriods[]>(initPeriods);
    const [filteredSlots, setFilteredSlots] = useState<string[]>([]);
    const [animationCompletedCount, setAnimationCompletedCount] = useState(0);
    const [isTimeSlotLoading, setIsTimeSlotLoading] = useState(false);
    const [isShopWorkingHoursLoading, setIsShopWorkingHoursLoading] = useState(false);
    const isGetAppVisible = useAppSelector(isGetApp);

    useEffect(() => {
        setAnimationCompletedCount(0);
    }, [filteredSlots]);

    const transitions = useTransition(filteredSlots, {
        keys: (item) => item,
        from: { opacity: 0, transform: "translateY(20px)" },
        enter: (item, index) => ({
            opacity: 1,
            transform: "translateY(0)",
            delay: index * 50,
        }),
        config: { tension: 220, friction: 20 },
        onStart: () => setAnimationCompletedCount(0),
        onRest: (item, phase) => {
            setAnimationCompletedCount((prevCount: any) => prevCount + 1);

            if (animationCompletedCount + 1 === filteredSlots.length) {
                setFilteredSlots([]);
            }
        },
    });

    useEffect(() => {
        if (!bookingState.selectedSpecialist) {
            listStaff();
        }
    }, []);

    useEffect(() => {
        manageWorkingHours();
    }, [bookingState.selectedSpecialist]);

    const manageWorkingHours = async () => {
        setIsShopWorkingHoursLoading(true);
        const workingDaysArray = bookingState.selectedSpecialist?.working_hours
            ? bookingState.selectedSpecialist?.working_hours.map((workingHour: IWorkingHour) => (workingHour.status ? workingHour.day : false))
            : [];
        setWorkingDays(workingDaysArray.filter((f) => f) as string[]);
        setIsShopWorkingHoursLoading(false);
    };

    useEffect(() => {
        setSlots([]);
        setFilteredSlots([]);
        listSlots();
        if (seletedDateFirstRender === false) {
            setSelectedTimeSlot(undefined);
            dispatch(setTempStore({ selectedTimeSlot: undefined, selectedBookingDateTime: undefined }));
        }

        if (format(selectedDate, "yyyy-MM-dd") === bookingState.selectedBookingDate) {
            setSelectedTimeSlot(bookingState.selectedBookingTime ?? "");
            dispatch(setTempStore({ selectedBookingTime: bookingState.selectedBookingTime ?? undefined }));

            if (bookingState.selectedBookingTime) {
                dispatch(setTempStore({ selectedBookingDateTime: `${format(selectedDate, "Y-MM-dd")} ${bookingState.selectedBookingTime}` }));
            }
        }
        dispatch(setTempStore({ selectedBookingDate: selectedDate ? format(selectedDate, "Y-MM-dd") : "un" }));
    }, [selectedDate]);

    useEffect(() => {
        if (slots.length) {
            const activePeriods = new Set();
            slots.forEach((time) => {
                const momentTime = moment(time, "HH:mm");
                initPeriods.forEach(({ name, start, end }) => {
                    if (momentTime.isBetween(moment(start, "HH:mm"), moment(end, "HH:mm"))) {
                        activePeriods.add(name);
                    }
                });
            });
            const filteredPeriods = initPeriods.filter((period) => activePeriods.has(period.name));
            setPeriods(filteredPeriods);
        }
    }, [slots]);

    // const filteredSlots = useMemo(
    //     () => slots.filter((time: moment.MomentInput) => moment(time, "HH:mm").isBetween(moment(selectedPeriod.start, "HH:mm"), moment(selectedPeriod.end, "HH:mm"))),
    //     [slots, selectedPeriod],
    // );

    useEffect(() => {
        if (slots.length) {
            const filterNew: string[] = slots.filter((time: moment.MomentInput) => moment(time, "HH:mm").isBetween(moment(selectedPeriod.start, "HH:mm"), moment(selectedPeriod.end, "HH:mm")));
            setFilteredSlots(filterNew);
        }
    }, [slots, selectedPeriod]);

    const handleOnclickTimeSlot = (slot: string | undefined) => {
        // dispatch(setSelectedBookingTime(slot));
        dispatch(setTempStore({ selectedBookingTime: slot }));
        dispatch(setTempStore({ selectedBookingDateTime: bookingState.tempStore.selectedBookingDate && slot ? `${bookingState.tempStore.selectedBookingDate} ${slot}` : undefined }));
        setSelectedTimeSlot(slot);
    };

    const listStaff = () => {
        setIsShopWorkingHoursLoading(true);
        const serviceIds = bookingState.selectedServices?.map((service: ISpecialistService) => service.id);
        const payload = {
            service_id: serviceIds.length ? serviceIds : undefined,
            booking_date: bookingState.selectedBookingDate,
            booking_time: bookingState.selectedBookingTime ? `${bookingState.selectedBookingTime}:00` : undefined,
        };
        const params = {
            shop_id: bookingState.selectedShopLocation?.shop_id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosPost(API.STAFF.LIST, payload, params)
            .then((response) => {
                const allSpecialists = response.data.data;
                const workingDaysArray: string[] = [];
                allSpecialists.forEach((specialistData: ISpecialist) => {
                    if (specialistData?.working_hours) {
                        specialistData?.working_hours.forEach((workingHour: IWorkingHour) => (workingHour.status ? workingDaysArray.push(workingHour.day) : false));
                    }
                });
                setWorkingDays(workingDaysArray.filter((item, index) => workingDaysArray.indexOf(item) === index));
            })
            .finally(() => {
                setIsShopWorkingHoursLoading(false);
            });
    };

    const listSlots = () => {
        setIsTimeSlotLoading(true);
        const payload = {
            booking_date: format(selectedDate, "yyyy-MM-dd"),
            booking_id: bookingState.modifyingAppointmentId ? bookingState.modifyingAppointmentId : null,
            staff_id: bookingState.selectedSpecialist?.id,
            services: bookingState.selectedServices.length
                ? bookingState.selectedServices.map((service) => {
                      const servicePayloadElm = {
                          id: service.id,
                          quantity: service.quantity,
                      };
                      return servicePayloadElm;
                  })
                : undefined,
        };
        const params = {
            shop_id: bookingState.selectedShopLocation?.shop_id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosPost(API.BOOKING.SLOTS, payload, params)
            .then((response) => {
                setSlots(response.data.data);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setTimeout(() => {
                    setIsTimeSlotLoading(false);
                }, 500);
            });
    };

    const handleLoadNextWorkingDate = () => {
        const nextAvailableDay = getNextAvailableDay(format(selectedDate, "iiii"), workingDays);
        if (nextAvailableDay) {
            const nextDate = getNextDayOfWeek(selectedDate, nextAvailableDay);
            setSelectedDate(nextDate);
            // dispatch(setSelectedBookingDate(format(nextDate, "Y-MM-dd")));
            dispatch(setTempStore({ selectedBookingDate: format(nextDate, "Y-MM-dd") }));
        }
    };

    const formatTimeTo12Hour = (time24: any) => {
        const [hours, minutes] = time24.split(":").map(Number); // Split into hours and minutes
        const period = hours >= 12 ? "PM" : "AM";
        const adjustedHours = hours % 12 || 12; // Convert to 12-hour format
        return `${adjustedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
    };

    const PeriodStyle = useSpring({
        from: { opacity: 0, transform: "translateY(-20px)" },
        to: { opacity: 1, transform: "translateY(0)" },
        config: config.stiff,
    });

    return (
        <div className={`flex flex-col w-full  ${isGetAppVisible ? "h-[calc(100vh-398px)]" : "h-[calc(100vh-330px)]"} lg:h-[calc(100vh-344px)]`}>
            <div className="flex-shrink-0">
                {isShopWorkingHoursLoading ? (
                    <Spinner size="xl" className="" />
                ) : (
                    <>
                        <div className="relative flex justify-between max-lg:px-4 z-20 items-center">
                            <RangeCalnedarComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} workingDays={workingDays} />
                            <div className={`flex flex-col items-center ${uiState.isMobile ? "absolute top-[60px] right-[20px]" : "relative pr-2 pl-1"} `}>
                                <DateSelectButton
                                    className={` ${
                                        uiState.isMobile ? " " : " w-[68px] h-[68px] border cursor-pointer border-borderColorSecondary border-dashed rounded-primary"
                                    } flex justify-center items-center`}
                                    dateFormat="dd/MM/yyyy"
                                    selectedDate={selectedDate}
                                    onChangeFunc={(changedDate: Date) => {
                                        setSelectedDate(new Date(changedDate));
                                        dispatch(setTempStore({ selectedBookingDate: format(new Date(changedDate), "Y-MM-dd") }));
                                        dispatch(setSelectedBookingDate(format(new Date(changedDate), "Y-MM-dd")));
                                    }}
                                    minDate={new Date()}
                                    closeOnOutsideClick={true}
                                >
                                    <PlusCircle className="w-[22px] h-[22px] !text-primaryAppointment" />
                                </DateSelectButton>
                            </div>
                        </div>

                        {/* {!isTimeSlotLoading && !!slots.length && (
                    <>
                        {bookingState.selectedSpecialist ? (
                            <div className="flex gap-4 items-center mt-5">
                                <img src={bookingState.selectedSpecialist.profile_image_url} className="w-12  rounded-2xl" alt="" onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)} />
                                <div>
                                    <h2 className=" leading-5 font-semibold text-sm text-txtcolor">
                                        {`Available times for ${bookingState.selectedSpecialist.first_name} ${bookingState.selectedSpecialist.last_name} on ${format(
                                            selectedDate,
                                            "EEEE, LLLL dd",
                                        )}${getOrdinalSuffix(format(selectedDate, "dd"))}`}
                                    </h2>
                                    <p className="  text-xs text-secondaryTxt">{bookingState.selectedSpecialist.staff_role?.name}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="flex gap-4 items-center mt-5 md:mt-10">
                                <div className="w-[50px] flex items-center justify-center bg-inputbg border-primary border-2 h-[50px] rounded-2xl text-primary text-2xl">
                                    <HiExclamationCircle />
                                </div>
                                <div className="flex-1">
                                    <h2 className=" leading-5 font-semibold text-sm text-primary">{t("You have not select any stylist yet")}</h2>
                                    <p className="  text-xs text-primary mt-[5px]">{t("Please select stylist according to your selected time slot.")}</p>
                                </div>
                            </div>
                        )}
                    </>
                )} */}
                        <animated.div style={PeriodStyle} className="fl-tab-btn-view w-full lg:mt-[44px] mt-[24px] px-4 lg:px-2 ">
                            {periods.map((period) => (
                                <button
                                    key={period.name}
                                    type="button"
                                    onClick={() => setSelectedPeriod(period)}
                                    className={`fl-tab-link  text-txtAppointmentColor border-borderColorPrimary -tracking-[0.03rem] hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)]  ${
                                        selectedPeriod.name === period.name ? "active" : ""
                                    }`}
                                >
                                    {period.icon && period.icon}

                                    <span className="mt-0 -tracking-[0.04rem]">{period.label}</span>
                                </button>
                            ))}
                        </animated.div>
                    </>
                )}
            </div>
            <div
                className={`${
                    filteredSlots.length === 0 && !isShopWorkingHoursLoading
                        ? "grid grid-cols-1 mt-3 lg:mt-3 max-lg:flex-1"
                        : `grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 relative overflow-auto scrollbar-hide max-lg:px-2  ${
                              isTimeSlotLoading ? "!grid-cols-1 sm:!grid-cols-1 md:!grid-cols-1 lg:!grid-cols-1 w-full mt-3 lg:mt-3" : " mt-5 lg:mt-7"
                          }`
                }`}
            >
                <>
                    {isTimeSlotLoading ? (
                        <div className="custom_loading_wrapper">
                            <div className="custom_loading"></div>
                        </div>
                    ) : !!filteredSlots.length && !isShopWorkingHoursLoading ? (
                        transitions((style, item) => (
                            <animated.div style={style} key={item} className="p-2">
                                <div
                                    onClick={() => handleOnclickTimeSlot(item === selectedTimeSlot ? undefined : item)}
                                    className={`cursor-pointer ${
                                        selectedTimeSlot === item
                                            ? "border border-primaryBorderHover hover:!border-primaryBorderHover bg-primaryHover text-primaryAppointment font-normal hover:!text-primary"
                                            : "border border-borderColorPrimary text-textGrey"
                                    } font-normal px-2 text-center py-3 transition-all ease-in-out hover:text-txtAppointmentColor -tracking-[0.02rem] hover:shadow-[0px_2px_12px_0px_#00000014] hover:border-borderColorPrimary rounded-secondary text-sm xl:text-base`}
                                >
                                    {formatTimeTo12Hour(item)}
                                </div>
                            </animated.div>
                        ))
                    ) : (
                        !isShopWorkingHoursLoading && (
                            <div className={`flex justify-center items-center w-full ${isGetAppVisible ? "max-lg:h-[270px]" : "max-lg:h-[320px]"} lg:min-h-[50vh] flex-col`}>
                                <div className="flex flex-col items-center m-auto">
                                    <h1 className="text-center text-textGrey text-base font-normal -tracking-[0.03rem]">{t("No time is available for selected date.")}</h1>
                                    {bookingState.selectedSpecialist && !slots.length && (
                                        <div className="w-full flex items-center justify-center -tracking-[0.03rem]">
                                            <ButtonCmp className="text-secondaryTxt font-medium -tracking-[0.03rem]" onClick={handleLoadNextWorkingDate}>
                                                {t("Select next available date")}
                                            </ButtonCmp>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    )}
                    {/* {!isTimeSlotLoading && (
                                    <>
                                        {!!filteredSlots.length ? (
                                            <Transition
                                                native
                                                items={filteredSlots}
                                                from={{ transform: "translateX(-100%)", opacity: 0 }} 
                                                enter={{ transform: "translateX(0)", opacity: 1 }} 
                                                leave={{ transform: "translateX(100%)", opacity: 0 }} 
                                                config={(item: any, index: number) => ({
                                                    ...config.slow,
                                                    trail: 100 * index,
                                                })}
                                            >
                                                {(styles, item) => (
                                                    <animated.div style={styles} className="p-2">
                                                        <div
                                                            onClick={() => handleOnclickTimeSlot(item === selectedTimeSlot ? undefined : item)}
                                                            className={`cursor-pointer ${
                                                                seSelectDateAndTimelectedTimeSlot === item
                                                                    ? "border border-primaryBorderHover hover:!border-primaryBorderHover bg-primaryHover text-primaryAppointment font-normal hover:!text-primary"
                                                                    : "border border-borderColorPrimary text-textGrey"
                                                            } font-normal px-2 text-center py-3 transition-all ease-in-out hover:text-txtAppointmentColor -tracking-[0.02rem] hover:shadow-[0px_2px_12px_0px_#00000014] hover:border-borderColorPrimary rounded-secondary text-sm xl:text-base`}
                                                        >
                                                            {formatTimeTo12Hour(item)}
                                                        </div>
                                                    </animated.div>
                                                )}
                                            </Transition>
                                        ) : (
                                            <div className="flex justify-center w-full md:min-h-[50vh] flex-col">
                                                <div className="m-auto">
                                                    <h1 className="text-txtcolor font-bold  text-base -tracking-[0.03rem]">{t("No time is available for selected date.")}</h1>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )} */}
                </>
                {/* )} */}

                {/* {!!slots.length && slots.length >= 10 && !isTimeSlotLoading && uiState.isMobile && (
                    <div className="w-full flex justify-center">
                        {showLimitedTimeSlots ? (
                            <ButtonCmp onClick={() => setShowLimitedTimeSlots(undefined)} className="btn_secondary_black mb-8 fl-btn min-w-[240px]">
                                <div className="flex gap-[5px] items-center">
                                    {t("View more times")} <FiArrowRight />
                                </div>
                            </ButtonCmp>
                        ) : (
                            <ButtonCmp onClick={() => setShowLimitedTimeSlots(10)} className="btn_secondary_black mb-8 fl-btn min-w-[240px]">
                                <div className="flex gap-[5px] items-center">
                                    {t("View less times")} <IoIosArrowUp />
                                </div>
                            </ButtonCmp>
                        )}
                    </div>
                )} */}
            </div>
            {/* {!slots.length && !isTimeSlotLoading && !isShopWorkingHoursLoading && (
                <div className="flex justify-center w-full md:min-h-[50vh] flex-col">
                    <div className="m-auto">
                        <h1 className="text-center text-textGrey text-base font-normal -tracking-[0.03rem]">{t("No time is available for selected date.")}</h1>
                        {bookingState.selectedSpecialist && (
                            <div className="w-full flex items-center justify-center -tracking-[0.03rem]">
                                <ButtonCmp className="text-secondaryTxt font-medium -tracking-[0.03rem]" onClick={handleLoadNextWorkingDate}>
                                    {t("Select next available date")}
                                </ButtonCmp>
                            </div>
                        )}
                    </div>
                </div>
            )} */}
            {uiState.isMobile && <ManageSubmit />}
        </div>
    );
};

export default DateTimePick;
