import React, { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { IShopLocation } from "../../Interface";

interface IProps {
    map: google.maps.Map;
    shopLocation: IShopLocation;
    children: React.ReactNode;
    onClick: (e: IShopLocation) => void;
    isSelected: boolean;
}

const MarkerAdvance = ({ map, shopLocation, children, onClick, isSelected }: IProps) => {
    const rootRef = useRef<any>();
    const markerRef = useRef<any>();

    useEffect(() => {
        if (!rootRef.current) {
            const container = document.createElement("div");
            rootRef.current = createRoot(container);
            const currentMarker = new (google.maps.marker as any).AdvancedMarkerView({
                position: {
                    lat: Number(shopLocation.latitude),
                    lng: Number(shopLocation.longitude),
                },
                content: container,
            });
            markerRef.current = currentMarker;
        }

        return () => {
            markerRef.current.map = null;
        };
    }, []);

    useEffect(() => {
        if (isSelected && markerRef.current) {
            markerRef.current.content.parentElement.parentElement?.classList.add("marker-style");
        } else if (markerRef.current) {
            markerRef.current.content.parentElement.parentElement?.classList.remove("marker-style");
        }
    }, [markerRef.current, isSelected]);

    useEffect(() => {
        rootRef.current.render(children);
        markerRef.current.position = {
            lat: Number(shopLocation.latitude),
            lng: Number(shopLocation.longitude),
        };
        markerRef.current.map = map;
        const listener = markerRef.current.addListener("gmp-click", () => onClick(shopLocation));
        return () => {
            listener.remove();
        };
    }, [map, children, onClick, shopLocation]);

    return <></>;
};

export default MarkerAdvance;
