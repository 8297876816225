import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { errorCode } from "../../../../constants/common";
import { API } from "../../../../constants/api";
import { axiosPost } from "../../../../utils/requestClient";
import { Stripe, StripeElements, TokenResult } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import WSidebarPaymentElement from "./WSidebarPaymentElement";
import { useAppSelector } from "src/redux/hooks";
import MSidebarPaymentElement from "./MSidebarPaymentElement";

interface IProps {
    handleClose: (isSucceed?: boolean) => void;
    successBtnText: string;
}

interface IFormData {
    cardnumber: string;
    date: string;
    cvv: string;
    holdername: string;
}

const SidebarPaymentElement = ({ handleClose, successBtnText }: IProps) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();
    const [isCreateCardLoading, setIsCreateCardLoading] = useState(false);

    const [cardError, setCardError] = useState<{
        cardNumber?: string;
        cardExpiry?: string;
        cardCvc?: string;
    }>({});

    const methods = useForm<IFormData>({
        defaultValues: {
            cardnumber: "",
            date: "",
            cvv: "",
            holdername: "",
        },
    });

    const {
        handleSubmit,
        setError,
        formState: { isValid },
    } = methods;

    const onSubmit = async (data: IFormData) => {
        setIsCreateCardLoading(true);
        const cardElement = elements?.getElement(CardNumberElement);

        if (stripe && cardElement) {
            const { error, token }: TokenResult = await stripe.createToken(cardElement);
            if (error) {
                const errCode = error.code as string;
                const parts = errCode.split("_");

                if (parts[1] === "number") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardNumber: error?.message,
                    }));
                }
                if (parts[1] === "expiry") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardExpiry: error?.message,
                    }));
                }
                if (parts[1] === "cvc") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardCvc: error?.message,
                    }));
                }
            }

            if (!data.holdername || data.holdername === "") {
                setError("holdername", {
                    type: "manual",
                    message: t("This field is required"),
                });
            }

            if (isValid && !error) {
                handleCreateCard(token?.id);
            } else {
                setIsCreateCardLoading(false);
            }
        }
    };

    const handleCreateCard = async (tokenId: string) => {
        try {
            setIsCreateCardLoading(true);
            const response = await axiosPost(API.STRIPE.CARD_ADD, {
                token: tokenId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                handleClose(true);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsCreateCardLoading(false);
        }
    };

    const [isFocus, setIsFocus] = useState(false);
    const [isLabel, setIsLabel] = useState(true);

    const onBlur = (e: any) => {
        if (!e.target.value) {
            setIsLabel(true);
        } else {
            setIsLabel(false);
        }
        setIsFocus(false);
    };

    const onFocus = () => {
        setIsFocus(true);
        setIsLabel(true);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full px-4 lg:px-5">
                {uiState.isTablet ? (
                    <WSidebarPaymentElement isCreateCardLoading={isCreateCardLoading} setCardError={setCardError} cardError={cardError} successBtnText={successBtnText} handleClose={handleClose} />
                ) : (
                    <MSidebarPaymentElement
                        isCreateCardLoading={isCreateCardLoading}
                        setCardError={setCardError}
                        cardError={cardError}
                        successBtnText={successBtnText}
                        handleClose={handleClose}
                        stripe={stripe}
                        elements={elements}
                    />
                )}
            </form>
        </FormProvider>
    );
};

export default SidebarPaymentElement;
