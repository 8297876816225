import React, { useCallback, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";

import "swiper/css";
import "swiper/css/pagination";
// Image path
interface Props {
    data: any;
}
const Gallery: React.FC<Props> = ({ data }) => {
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    return (
        <div className={`relative  h-screen w-full bg-gray-800 bg-opacity-50`}>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                loop={true}
                onSwiper={setSwiperRef}
                navigation={false} // Disable Swiper default navigation
                className="mySwiper w-full h-screen"
                // Apply dynamic height here
            >
                {data?.urls?.map((img: any, index: React.Key | null | undefined) => (
                    <SwiperSlide key={index}>
                        <>
                            <div className="relative w-full h-screen">
                                <img src={img} alt="" className="w-full h-[100vh] object-cover " />
                            </div>
                        </>
                    </SwiperSlide>
                ))}
                <div className="absolute top-[40%]  w-full h-full pl-7 lg:pl-28 z-50 text-txtcolor">
                    <h2 className="title_text text-[75px] text-white">Gallery</h2>
                    <p className="sub_text text-white">From our studio</p>
                    <div className="flex mt-5 relative ">
                        <button className="rounded-full p-2 cursor-pointer mr-3 bg-contentBackground" onClick={handlePrevious}>
                            <FaChevronLeft className="text-gray-600 text-lg text-txtcolor" />
                        </button>
                        <button className="rounded-full  p-2  cursor-pointer bg-contentBackground " onClick={handleNext}>
                            <FaChevronRight className="text-gray-600 text-lg text-txtcolor" />
                        </button>
                    </div>
                </div>
            </Swiper>
        </div>
    );
};

export default Gallery;
