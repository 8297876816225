import React from "react";
import { FaCheck } from "react-icons/fa6";
import { ActionMeta, components } from "react-select";
import CreatableSelect from "react-select/creatable";
const { Option } = components;

interface IProps {
    options: any[];
    allowClear: boolean;
    disabled: boolean;
    name: string;
    label?: string;
    defaultValue?: string;
    searchable?: boolean;
    onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
    value: any | undefined;
    placeholder?: string;
    openMenuOnClick?: boolean;
    inputClass?: string;
}

const SelectBox = ({ label, options, allowClear, disabled, name, defaultValue, searchable = false, onChange, value, placeholder, openMenuOnClick = true, inputClass }: IProps) => (
    <div className="select_box_main">
        {label && <label className="fl-field-title-label">{label}</label>}
        <CreatableSelect
            className={`border-none w-full relative ${inputClass}`}
            classNamePrefix="select"
            defaultValue={defaultValue}
            isDisabled={disabled}
            isClearable={allowClear}
            isSearchable={searchable}
            name={name}
            options={options}
            onChange={onChange}
            value={value}
            // defaultMenuIsOpen
            placeholder={placeholder}
            openMenuOnClick={openMenuOnClick}
            components={{ Option: IconOption }}
            // menuIsOpen
        />
    </div>
);

const IconOption = (props: any) => (
    <Option {...props}>
        <div className="flex justify-between items-center font-normal cursor-pointer dropdown-item-wrapper">
            <span className=""> {props.data.label}</span>
            {props.isSelected && (
                <span className=" leading-3 flex">
                    <FaCheck size={14} />{" "}
                </span>
            )}
        </div>
    </Option>
);

export default SelectBox;
