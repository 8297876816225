import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { IProps, ProductProps } from "./Product.interface";
import { onError } from "src/utils/global-functions";
import { PlaceholderImgPNG } from "src/theme/Images";
import { Minus, Plus } from "@untitled-ui/icons-react/build/cjs";

const ProductDetail: FC<IProps> = ({ data, productVariant, setProductVariant }) => {
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [mainImage, setMainImage] = useState(PlaceholderImgPNG);
    const [activeImage, setActiveImage] = useState("");

    useEffect(() => {
        const mainImages = data.images.find((image: any) => image.is_main);
        if (mainImages) {
            setMainImage(mainImages.image_url);
            setActiveImage(mainImages.image_url);
        }
    }, []);

    useEffect(() => {
        setProductVariant(Object.keys(productVariant).length ? productVariant : data.variants[0]);
    }, [productVariant]);

    const handleImageClick = (url: string) => () => {
        setMainImage(url);
        setActiveImage(url);
    };

    const ProductInfo: React.FC<ProductProps> = ({ index, title, description }) => {
        const [isCollapsed, setIsCollapsed] = useState<number[]>([]); // State to keep track of open sections

        const toggleCollapse = (indexNum: number) => {
            setIsCollapsed((prevState) => (prevState.includes(indexNum) ? prevState.filter((i) => i !== indexNum) : [...prevState, indexNum]));
        };

        return (
            <div className="border-b border-borderColorPrimary py-4 text-txtAppointmentColor last:border-b-0">
                <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleCollapse(index)}>
                    <div className="flex-1">
                        <h3 className="text-sm leading-[18.2px] font-semibold truncate gap-3 lg:gap-4 -tracking-[0.01rem]">{title}</h3>
                    </div>
                    <div className="text-right -tracking-[0.01rem]">
                        <button>{isCollapsed.includes(index) ? <Minus className="w-[18px] h-[18px]" /> : <Plus className="w-[18px] h-[18px]" />}</button>
                    </div>
                </div>
                {isCollapsed.includes(index) && (
                    <div className="mt-3">
                        <div className="flex justify-between items-center">
                            <div className="w-full">
                                <div className="flex font-normal text-sm leading-[18.2px] text-txtAppointmentColor">{description}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="flex flex-col lg:p-5 !pb-0 w-full">
            <div className="flex flex-col sm:flex-row border-b border-borderColorPrimary sm:gap-5">
                {uiState.isMobile ? (
                    // <>
                    //     <img className="rounded-xl w-full h-[226px] min-w-[226px]" src={mainImage} alt="" onError={onError} />
                    //     <div className="flex flex-row">
                    //         <div className="flex-shrink-0 flex flex-row space-x-[16px] overflow-x-scroll scrollbar_add w-full max-w-[calc(100%-0px)]  py-3 mb-5 ">
                    //             {data.images?.map((image: any, index: number) => (
                    //                 <img
                    //                     key={index}
                    //                     className="rounded-secondary object-cover h-[48px] w-[48px] cursor-pointer"
                    //                     src={image.image_url}
                    //                     alt=""
                    //                     onClick={handleImageClick(image.image_url)}
                    //                     onError={onError}
                    //                 />
                    //             ))}
                    //         </div>
                    //     </div>
                    // </>
                    <div className="flex bg-contentBackground gap-4 w-full min-w-[326px] max-w-full mb-5 flex-1">
                        <div className="flex-shrink-0 flex-col space-y-[10px] overflow-y-scroll h-full max-h-[260px] scrollbar-hide">
                            {data.images?.map((image: any, index: number) => (
                                <img
                                    key={index}
                                    className={`w-[48px] h-[48px] object-cover rounded-[8px] cursor-pointer ${
                                        activeImage === image.image_url ? "  border border-primary  " : "border border-transparent"
                                    }`}
                                    src={image.image_url}
                                    alt=""
                                    onClick={handleImageClick(image.image_url)}
                                    onError={onError}
                                />
                            ))}
                        </div>
                        <div className="flex-grow">
                            <img src={mainImage} className="w-full rounded-secondary min-w-[262px] max-w-full h-[262px] object-cover" alt="" onError={onError} />
                        </div>
                    </div>
                ) : (
                    <div className="flex  gap-4 w-[326px] min-w-[326px] max-w-[326px] mb-5 flex-1">
                        <div className="flex-shrink-0 flex-col space-y-[10px] overflow-y-scroll h-full max-h-[226px] scrollbar-hide">
                            {data.images?.map((image: any, index: number) => (
                                <img
                                    key={index}
                                    className={`w-[48px] h-[48px] object-cover rounded-[8px] cursor-pointer ${
                                        activeImage === image.image_url ? "  border border-primary  " : "border border-transparent"
                                    }`}
                                    src={image.image_url}
                                    alt=""
                                    onClick={handleImageClick(image.image_url)}
                                    onError={onError}
                                />
                            ))}
                        </div>
                        <div className="flex-grow">
                            <img src={mainImage} className="w-full rounded-secondary min-w-[262px] max-w-[262px] h-[262px] object-cover" alt="" onError={onError} />
                        </div>
                    </div>
                )}
                <div className=" flex-col hidden sm:flex">
                    <div className="flex flex-col mb-5">
                        <p className="font-semibold text-[22px] leading-[26.4px] text-txtAppointmentColor -tracking-[0.03rem] mb-1.5">{data.name}</p>
                        <span className="text-xs font-normal text-textGrey leading-[14.52px]">{data.category.name}</span>
                    </div>

                    <div className="flex w-full flex-col ">
                        <div className="flex fl-tab-btn-view flex-wrap flex-row gap-2 items-center mb-[12px]">
                            {data.variants?.map((variant: any) => (
                                <div
                                    key={variant.id}
                                    className={`!px-1.5 !text-xs leading-[18px] !font-medium !h-[28px] flex items-center justify-center border rounded-[6px] border-borderColorPrimary text-textGrey cursor-pointer -tracking-[0.02rem] hover:border-primary hover:bg-primaryHover hover:!text-primary ${
                                        productVariant.id === variant.id ? "border-primary !text-primary" : ""
                                    }`}
                                    onClick={() => {
                                        setProductVariant(variant);
                                    }}
                                >
                                    {variant.size} {data.unit_of_measure}
                                </div>
                            ))}
                        </div>
                        {productVariant?.color && (
                            <div className="flex gap-4 items-center border-b border-borderColorPrimary -tracking-[0.01rem] mb-[10px]">
                                <div className="text-sm font-semibold uppercase text-txtAppointmentColor ">Colors</div>
                                {productVariant.color} #fff
                            </div>
                        )}
                        <p className="font-medium text-[28px] text-txtAppointmentColor leading-[33.6px] -tracking-[0.03rem] mb-3"> ${productVariant?.inventory?.price}</p>
                        <p className="text-sm  font-normal text-txtAppointmentColor leading-[18.2px] -tracking-[0.02rem] line-clamp-4">{data.description}</p>
                    </div>
                </div>
            </div>

            <div className=" flex flex-col max-lg:overflow-auto max-lg:h-[calc(100vh-350px)] max-lg:pb-[40px] scrollbar-hide">
                <div className="flex flex-col sm:hidden mt-3">
                    <div className="flex flex-col mb-5">
                        <p className="font-semibold text-[22px] leading-[26.4px] text-txtAppointmentColor -tracking-[0.03rem] mb-1.5">{data.name}</p>
                        <span className="text-xs font-normal text-textGrey leading-[14.52px]">{data.category.name}</span>
                    </div>

                    <div className="flex w-full flex-col ">
                        <div className="flex fl-tab-btn-view flex-wrap flex-row gap-2 items-center mb-[12px]">
                            {data.variants?.map((variant: any) => (
                                <div
                                    key={variant.id}
                                    className={`!px-1.5 !text-xs leading-[18px] !font-medium !h-[28px] flex items-center justify-center border rounded-[6px] border-borderColorPrimary text-textGrey cursor-pointer -tracking-[0.02rem] hover:border-primary hover:bg-primaryHover hover:!text-primary ${
                                        productVariant.id === variant.id ? "border-primary !text-primary" : ""
                                    }`}
                                    onClick={() => {
                                        setProductVariant(variant);
                                    }}
                                >
                                    {variant.size} {data.unit_of_measure}
                                </div>
                            ))}
                        </div>
                        {productVariant?.color && (
                            <div className="flex gap-4 items-center border-b border-line -tracking-[0.01rem] mb-[10px]">
                                <div className="text-sm font-semibold uppercase text-txtAppointmentColor ">Colors</div>
                                {productVariant.color} #fff
                            </div>
                        )}
                        <p className="font-medium text-[28px] text-txtAppointmentColor leading-[33.6px] -tracking-[0.03rem] mb-3"> ${productVariant?.inventory?.price}</p>
                        <p className="text-sm  font-normal text-txtAppointmentColor leading-[18.2px] -tracking-[0.02rem] line-clamp-4">{data.description}</p>
                    </div>
                </div>
                <div className="-tracking-[0.03rem]">
                    {data.details?.map((product: any, index: number) => (
                        <ProductInfo key={index} index={index} title={product.name} description={product.description} />
                    ))}
                </div>
            </div>
        </div>
    );
};
export default ProductDetail;
