import { useRef, useEffect } from "react";

export const useFirstRender = (p: any) => {
    const firstRender = useRef(true);

    useEffect(() => {
        firstRender.current = false;
    }, [p]);

    return firstRender.current;
};
