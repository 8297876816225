export const initAction = {
    product: false,
};

export interface IInitAction {
    product: boolean;
}

export interface IProps {
    data: any;
    productVariant: any;
    setProductVariant: React.Dispatch<any>;
}

export type ProductProps = {
    index: number;
    title: string;
    description: string;
};
