import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { PlaceholderImgPNG, InstagramSVG } from "src/theme/Images";
import { convertToHumanReadable, onError } from "../../utils/global-functions";
import { ISpecialistImageGallary } from "../../Interface";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import { INSTAGRAM_URL } from "src/constants/common";

const SpecialistDetail = () => {
    const { t } = useTranslation();
    const uiState = useAppSelector((data) => data.UiStates);
    const [activeTab, setActiveTab] = useState<any>("Gallery");

    const tabs = useMemo(
        () => [
            { name: "Gallery", count: uiState.showSpecialistProfileModalData?.galleries?.length || 0 },
            { name: "Services", count: uiState.showSpecialistProfileModalData?.services?.length || 0 },
        ],
        [uiState.showSpecialistProfileModalData],
    );

    return (
        <div className="flex flex-col rounded-t-3xl xl:rounded-3xl lg:p-5 w-full  lg:h-full relative">
            <div className="flex max-lg:flex-col max-lg:gap-4 lg:justify-between items-center">
                <div className="flex flex-row items-center gap-2 rounded-primary w-full flex-1">
                    <img className="rounded-primary w-[48px] h-[48px] object-cover" src={uiState.showSpecialistProfileModalData?.profile_image_url} alt="" onError={onError} />
                    <div className="content">
                        <h2 className="font-medium text-[16px] text-txtAppointmentColor leading-[19.2px] -tracking-[0.03rem] mb-1.5">
                            {`${uiState.showSpecialistProfileModalData?.first_name} ${uiState.showSpecialistProfileModalData?.last_name}`}
                        </h2>
                        <p className="font-normal text-[12px] leading-[14.52px] text-textGrey -tracking-[0.01rem]">{uiState.showSpecialistProfileModalData?.staff_role?.name}</p>
                    </div>
                </div>
                <CustomButton outlineSecondary className="!h-[40px] rounded-[8px] max-lg:w-full !py-1.5 shadow-InputAndButton cursor-pointer hover:shadow-md shadow:[0px_1px_2px_0px_#1018280D]">
                    {uiState.showSpecialistProfileModalData?.instagram_link && (
                        <a
                            className="flex gap-3 w-full items-center bg-inputbg  rounded-xl cursor-pointer justify-between max-lg:justify-center max-lg:mx-auto"
                            href={`${INSTAGRAM_URL}${uiState.showSpecialistProfileModalData?.instagram_link}`}
                            target="_blank"
                        >
                            <div className="flex gap-1.5 items-center ">
                                <img src={InstagramSVG} className="w-[20px]" alt="" />
                                <p className="text-xs sm:text-sm font-semibold text-txtAppointmentColor mb-[2px]  flex justify-between items-center leading-[18.2px] -tracking-[0.01rem]">
                                    @{uiState.showSpecialistProfileModalData?.instagram_link}
                                </p>
                            </div>
                        </a>
                    )}
                </CustomButton>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-between items-center mb-5">
                <div className="font-normal flex-1 text-sm leading-[18.2px] text-textGrey text-justify -tracking-[0.01rem] line-clamp-3">{uiState.showSpecialistProfileModalData?.about}</div>
            </div>
            {/* image hoi aa 2 class add max-h-[295px] lg:max-h-[245px] and no hoi thayre h-full */}
            <div className="flex flex-row gap-2  transition duration-200 ease-in-out">
                {tabs.map((tab, index) => (
                    <div className="group" key={index}>
                        <button
                            key={index}
                            type="button"
                            className={` group max-w-[182px] flex flex-row gap-1.5 text-sm font-medium  items-center h-[28px] pl-2.5 pr-1 py-1  border   rounded-lg transition duration-200 ease-in-out group-hover:bg-[#FEFEFE] group-hover:text-black group-hover:border-[#CACFDC] ${
                                activeTab === tab.name ? "bg-[#FEFEFE] text-black rounded-lg border-[#CACFDC]" : "text-[#8C9CBB] border-[#E3E7EF]"
                            }`}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {tab.name}
                            <div
                                className={`w-[21px] h-5 rounded-[3px] text-sm group-hover:bg-[#E3E7EF] group-hover:text-black transition duration-200 ease-in-out ${
                                    activeTab === tab.name ? " bg-[#E3E7EF] bg-opacity-100  " : " bg-[#F7F7F8]  text-[#8C9CBB] "
                                }`}
                            >
                                {tab.count}
                            </div>
                        </button>
                    </div>
                ))}
            </div>
            {activeTab === "Services" ? (
                <div className="max-h-[250px]  lg:max-h-[248px]  pb-2 overflow-y-scroll  scrollbar-hide mt-4  grid grid-cols-2 sm:grid-cols-3 gap-2.5 flex-wrap -tracking-[0.03rem] ">
                    {uiState.showSpecialistProfileModalData?.services?.map((item: any, index: number) => (
                        <div
                            key={index}
                            className="flex flex-col border border-borderColorPrimary rounded-secondary justify-between w-full h-[118px] hover:shadow-[0px_2px_10px_0px_#0000001A] hover:cursor-pointer"
                        >
                            <div className="flex flex-col  h-[68px] p-3.5 border-b border-borderColorPrimary">
                                <div className="text-xs sm:text-sm font-bold text-txtAppointmentColor -tracking-[0.02rem] leading-[18.2px] truncate max-w-full mb-0.5">{item?.name}</div>
                                <div className="text-xs font-normal text-textGrey leading-[18px] -tracking-[0.02rem]">
                                    {item?.shop_staff_service?.duration && convertToHumanReadable(item?.shop_staff_service?.duration)}
                                </div>
                            </div>
                            <div className="flex flex-row px-4 py-3 justify-between items-center w-full ">
                                <div className="text-xs font-normal text-textGrey -tracking-[0.01rem] leading-[16.8px]">
                                    {item.shop_staff_service.starting_price ? t("Starting price") : t("Price")}
                                </div>
                                <div className="bg-[#E3E7EF] border rounded-[6px] border-[#CACFDC] max-w-max h-[24px] px-[6px] flex justify-center ">
                                    <div
                                        className="text-txtAppointmentColor flex items-center gap-[2px] text-xs sm:text-sm -tracking-[0.02rem] font-semibold 
                                        leading-[22.4px]"
                                    >
                                        <span className="">$</span>
                                        <span className="">{item.shop_staff_service.price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                uiState.showSpecialistProfileModalData?.galleries &&
                uiState.showSpecialistProfileModalData?.galleries?.length >= 0 && (
                    <div className="flex flex-col mt-2">
                        {/* <div className="font-normal text-base leading-[22.4px] text-txtAppointmentColor -tracking-[0.02rem]">Gallery of {uiState.showSpecialistProfileModalData?.first_name}</div> */}
                        <div className=" mt-4 grid grid-cols-2 sm:grid-cols-3 gap-2.5 overflow-x-auto max-h-[calc(100vh-378px)] lg:max-h-[282px]  w-full scrollbar_add ">
                            {uiState.showSpecialistProfileModalData?.galleries?.map((item: ISpecialistImageGallary, index: number) => (
                                <div key={index} className="w-full h-[133.25px]  rounded-lg ">
                                    <img src={item?.image_url} alt="" className=" object-cover w-full h-full rounded-lg" onError={onError} />
                                </div>
                            ))}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default SpecialistDetail;
