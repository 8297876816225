import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import MainRoutes from "./routes/MainRoutes";
import ToastBodyContainer from "./components/ToastContainer";

const App = () => (
    <Provider store={store}>
        <BrowserRouter>
            <PrimeReactProvider>
                <MainRoutes />
            </PrimeReactProvider>
        </BrowserRouter>
        <ToastBodyContainer />
    </Provider>
);

export default App;
