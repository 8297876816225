import React, { FC, useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import { Cropper, ReactCropperElement } from "react-cropper";
import { ICropType, IPops } from "./CropperModal.interface";
import { useTranslation } from "react-i18next";
import Crop01 from "@untitled-ui/icons-react/build/cjs/Crop01";

import PopupModal from "../PopupModal";

const CropperModal: FC<IPops> = ({
    imageUpload,
    imageUrl,
    setUpload,
    setCropData,
    isCropType = true,
    defaultCropType = "Rounded",
    title = "Adjust image",
    description = "Adjust you image",
    btnTitle,
    types = ["Rounded", "Square", "Rectangular"],
}) => {
    const { t } = useTranslation();
    // const cropTypeArray: ICropType[] = types ? types : ['Rounded', 'Square', 'Rectangular'];
    const cropperRef = useRef<ReactCropperElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [cropType, setCropType] = useState<ICropType>(defaultCropType);
    const zoomSliderRef = useRef<HTMLInputElement>(null);
    const [zoomLevel, setZoomLevel] = useState<any>(0);
    const [naturalAspectRatio, setNaturalAspectRatio] = useState<any>(null);

    useEffect(() => {
        if (cropperRef.current && typeof cropperRef.current?.cropper.zoomTo === "number") {
            cropperRef.current?.cropper.zoomTo(Number(zoomSliderRef.current!.value));
        }
    }, [zoomLevel]);

    useEffect(() => {
        handleCropType(cropType);
    }, [cropType, naturalAspectRatio]);

    const getCropData = () => {
        if (cropperRef.current?.cropper) {
            const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas({
                rounded: true,
                imageSmoothingEnabled: true,
            });
            const imageData = cropType === "Rounded" ? getRoundedCanvas(croppedCanvas).toDataURL() : croppedCanvas.toDataURL();
            if (croppedCanvas) {
                setCropData(imageData);
                setUpload(false);
                imageUpload && imageUpload(imageData as any);
            }
        }
    };

    const handleZoomChange = (e: any) => {
        const newZoomLevel = parseFloat(e.target.value);
        setZoomLevel(newZoomLevel);
        if (cropperRef.current) {
            cropperRef.current?.cropper.zoomTo(Number(newZoomLevel));
        }
    };

    const handleCropType = (type: ICropType) => {
        cropperRef.current?.cropper.reset();
        if (type === "Rectangular" || cropType === "Rectangular") {
            const aspectRatio: number = 16 / 9;

            // cropperRef.current?.cropper.setAspectRatio(naturalAspectRatio);
            cropperRef.current?.cropper.setAspectRatio(aspectRatio);
        } else {
            const aspectRatio: number = 1 / 1;
            cropperRef.current?.cropper.setAspectRatio(aspectRatio);
        }
        // const aspectRatio: number = type === 'Rectangular' ? 16 / 9 : 1 / 1;
        // cropperRef.current?.cropper.setAspectRatio(aspectRatio);

        /* let imageData = cropperRef.current?.cropper.getImageData();
        let imageData2 = cropperRef.current?.cropper.getCropBoxData();

        if (imageData && imageData2) {
            const minSliderZoom = imageData?.width / imageData.naturalWidth;
            const mintestZoom = 1 - imageData2?.height / imageData.height;

            setScale(mintestZoom);
        } */
    };

    const handleImageLoaded = (e: any) => {
        const naturalWidth = e.target.naturalWidth;
        const naturalHeight = e.target.naturalHeight;
        const aspectRatio = naturalWidth / naturalHeight;
        setNaturalAspectRatio(aspectRatio);
    };

    const getRoundedCanvas = (sourceCanvas: any) => {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        var width = sourceCanvas.width;
        var height = sourceCanvas.height;

        canvas.width = width;
        canvas.height = height;
        if (context) {
            context.imageSmoothingEnabled = true;
            context.drawImage(sourceCanvas, 0, 0, width, height);
            context.globalCompositeOperation = "destination-in";
            context.beginPath();
            context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
            context.fill();
        }
        return canvas;
    };

    const handleClose = () => {
        setUpload(false);
    };

    return (
        <PopupModal
            onClose={handleClose}
            size="max-w-[95%] min-w-[360px] mt-[76px] mx-auto md:w-[600px] font-primary outline-custom"
            className="!pt-0 !pb-0"
            primaryButton={btnTitle ? btnTitle : t("Crop")}
            secondaryButton={t("Cancel")}
            acceptAction={getCropData}
            declineAction={() => setUpload(false)}
            title={
                <div className="flex justify-between items-start ">
                    <div className="flex gap-4 items-start">
                        <div className="w-12 h-12 rounded-[10px] p-3 border border-gray-200 shadow-InputAndButton">
                            <Crop01 className="w-6 h-6" />
                        </div>
                        <div className="flex flex-col gap-1">
                            <h2 className=" font-semibold text-lg text-mainTextColor">{title}</h2>
                            <p className="text-sm font-normal  text-secondaryTxtColor">{description}</p>
                        </div>
                    </div>
                </div>
            }
        >
            <div className="relative p-5 overflow-auto">
                {isCropType && (
                    <div className="fl-tab mb-6 rounded-[10px]">
                        {types.map((type: ICropType) => (
                            <button key={type} type="button" value={type} className={`fl-tab-link !rounded-md ${cropType === type && "active"}`} onClick={() => setCropType(type)}>
                                {type}
                            </button>
                        ))}
                    </div>
                )}
                <div>
                    <div>
                        <div className="h-[320px] mb-6 overflow-hidden rounded-lg app-logo-img-block bg-baseBg ">
                            <Cropper
                                ready={handleImageLoaded}
                                aspectRatio={1 / 1}
                                autoCrop={true}
                                autoCropArea={1}
                                preview=".img-preview"
                                src={imageUrl}
                                center={true}
                                ref={cropperRef}
                                viewMode={0}
                                guides={false}
                                minCropBoxHeight={3}
                                minCropBoxWidth={3}
                                background={false}
                                responsive={false}
                                checkOrientation={true}
                                cropBoxMovable={false}
                                cropBoxResizable={false}
                                dragMode="move"
                                disabled={true}
                                highlight={true}
                                className={`${cropType === "Rounded" ? "imageCropper" : ""}`}
                                // zoom={onCropperZoom}
                            />
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    height: 0.5,
                                }}
                                className="hidden"
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex justify-between items-center custom-range-slider">
                            <input ref={zoomSliderRef} id="zoomSlider" type="range" min="0" max="3" step="0.01" value={zoomLevel} onChange={handleZoomChange} />
                        </div>
                        <div className="flex justify-between">
                            <h1 className="text-mainTextColor text-base font-medium ">0%</h1>
                            <h1 className="text-mainTextColor text-base font-medium ">{Math.round((zoomLevel / 3) * 100)}%</h1>
                        </div>
                    </div>
                </div>
            </div>
        </PopupModal>
    );
};
export default CropperModal;
