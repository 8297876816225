import { Modal } from "flowbite-react";
import React, { ReactNode } from "react";

interface IDrawerProps {
    children: ReactNode;
    header?: ReactNode;
    handleClose: () => void;
    size: string;
    position?: string;
    className?: string;
    dismissible?: boolean;
}

const FlowbiteModal = ({ children, handleClose, size, position = "center", dismissible = true, className = "", header }: IDrawerProps) => (
    <Modal
        show
        size={size}
        onClose={handleClose}
        popup
        position={position}
        dismissible={dismissible}
        theme={{
            content: { inner: `relative flex max-h-[90dvh] flex-col rounded-secondary bg-cardBackground shadow dark:bg-gray-700 ${className}` },
        }}
    >
        <Modal.Header className="absolute top-2 right-3">{header}</Modal.Header>
        <Modal.Body className="mt-10">{children}</Modal.Body>
    </Modal>
);

export default FlowbiteModal;
