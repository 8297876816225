import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputWithLabel from "src/components/InputWithLabel";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";

const WCardTabComponent = ({ setCardError, cardError }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const user = useAppSelector(currentUser);
    return (
        <div className={`flex-1  ${user && user.total_loyalty_points > 0 ? "mt-5" : ""}`}>
            <Controller
                name="holdername"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className="w-full mb-5">
                        <label htmlFor="cardnumber" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                            Name on Card
                        </label>
                        <InputWithLabel
                            // label={t("Cardholder Name")}
                            placeholder={t("Enter card holder name")}
                            handleChange={(e) => onChange(e.target.value)}
                            value={value}
                            name="holdername"
                            inputClass={`!card-element !h-[44px] !py-[10px] !text-sm ${error && "is-invalid border-solid"}`}
                        />
                        {error && <p className="text-error">{error && error.message}</p>}
                    </div>
                )}
            />

            <div className="grid grid-cols-2 gap-4 w-full tracking-[0.03rem]">
                <Controller
                    name="cardnumber"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full mb-5">
                            <label htmlFor="cardnumber" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                                Card number
                            </label>
                            <CardNumberElement
                                id="number-123"
                                className={`card-element !h-[44px]flex !pt-[14px] !text-base ${(error || cardError.cardNumber) && "is-invalid"}`}
                                options={{
                                    style: {
                                        base: {
                                            color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                        },
                                    },
                                }}
                                onChange={(e) => {
                                    onChange(e.error ? "" : e.complete);
                                    setCardError((prev: any) => ({
                                        ...prev,
                                        cardNumber: e.complete ? "" : e.error?.message,
                                    }));
                                }}
                            />
                            {/* <CardNumberElement
                            options={{
                                style: {
                                    base: {
                                        lineHeight: "35px",
                                        color: cardNumberColor,
                                        textAlign: "left",
                                    },
                                },
                            }}
                            id="number-123"
                            className={`ba_form_control form_control_payment ${(errors.cardnumber || cardError.cardNumber) && "is-invalid border-solid text-secondaryTxt"}`}
                            onChange={(e) => {
                                onChange(e.error ? "" : e.complete);
                                setCardError((prev) => ({
                                    ...prev,
                                    cardNumber: e.complete ? "" : e.error?.message,
                                }));
                            }}
                        /> */}
                            {(error || cardError.cardNumber) && <p className="text-error">{(error || cardError.cardNumber) && (error?.message || cardError.cardNumber)}</p>}
                        </div>
                    )}
                />
                <div className="grid grid-cols-2 gap-4 w-full">
                    <Controller
                        name="date"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="w-full">
                                <label htmlFor="date" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                                    Date
                                </label>

                                {/* <CardExpiryElement
                                    options={{
                                        style: {
                                            base: {
                                                lineHeight: "35px",
                                            },
                                        },
                                    }}
                                    className={`ba_form_control form_control_payment ${(errors.date || cardError.cardExpiry) && "is-invalid border-solid"}`}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev) => ({
                                            ...prev,
                                            cardExpiry: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                /> */}
                                <CardExpiryElement
                                    className={`card-element !h-[44px] pt-[13px] !text-base ${(error || cardError.cardExpiry) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                    }}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev: any) => ({
                                            ...prev,
                                            cardExpiry: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                />

                                {(error || cardError.cardExpiry) && <p className="text-error">{(error || cardError.cardExpiry) && (error?.message || cardError.cardExpiry)}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="cvv"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="w-full mb-5">
                                <label htmlFor="cvv" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                                    CVC
                                </label>
                                {/* <CardCvcElement
                                    options={{
                                        style: {
                                            base: {
                                                lineHeight: "35px",
                                            },
                                        },
                                    }}
                                    className={`ba_form_control form_control_payment ${(errors.cvv || cardError.cardCvc) && "is-invalid border-solid"}`}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev) => ({
                                            ...prev,
                                            cardCvc: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                /> */}
                                <CardCvcElement
                                    className={`card-element border !h-[44px] pt-[13px] !text-base tracking-[0.03rem]  ${(error || cardError.cardCvc) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                    }}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev: any) => ({
                                            ...prev,
                                            cardCvc: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                />

                                {(error || cardError.cardCvc) && <p className="text-error">{(error || cardError.cardCvc) && (error?.message || cardError.cardCvc)}</p>}
                            </div>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default WCardTabComponent;
