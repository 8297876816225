import React from "react";
import { PlaceholderImgPNG } from "src/theme/Images";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setBookingInitialState, setSelectedShopLocation } from "../../../BookAppointment/Booking.slice";
import { IShopLocation } from "../../../../Interface";
import { ROUTES } from "../../../../constants/routes";
import { ArrowNarrowRight } from "@untitled-ui/icons-react/build/cjs";
import { PATH } from "src/constants/path";
import { onError } from "src/utils/global-functions";
interface IProps {
    location: IShopLocation;
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
}

const BarberDetailCard = ({ location, selectedShopData, setSelectedShopData }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector((data) => data.Booking);
    return (
        <div
            onMouseEnter={() => setSelectedShopData(location)}
            onMouseLeave={() => setSelectedShopData(undefined)}
            onClick={() => {
                if (location.id !== bookingState.selectedShopLocation?.id || bookingState.isModifyingAppointment) {
                    dispatch(setBookingInitialState());
                }
                dispatch(setSelectedShopLocation(location));
                navigate(PATH.APPOINTEMENT);
            }}
        >
            <label
                htmlFor={`${location.id}`}
                className={`bg-cardColor group flex shadow-card mb-4 relative rounded-secondary hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)]  cursor-pointer outline-none ring-0 p-3 min-h-[95px]  ${
                    selectedShopData?.id === location.id ? "border-borderColorPrimary border" : "border-borderColorPrimary border"
                } `}
            >
                <div className="w-[100px] mr-[12px] lg:mr-5">
                    <img src={location.profile_image_url} alt="" className=" lg:w-[100px] lg:h-[71px] object-cover min-w-[100px] min-h-[70px] h-[70px] rounded-secondary" onError={onError} />
                </div>
                <div className="flex gap-5 flex-1 ">
                    <div className="content p-0  w-full flex flex-col justify-center ">
                        <h2 className="text-base font-normal text-txtcolor leading-[22.4px] -tracking-[0.03em] mb-[3px] lg:mb-2">{location.name}</h2>
                        <p className="text-sm  text-textGrey font-normal leading-[18.2px] -tracking-[0.02em]">{`${location.street}, ${location.city}, ${location.country} ${location.postal_code}`}</p>

                        {/* <div className="flex mt-[8px] lg:mt-[5px] lg:hidden items-center">
                            <PiMapPin size={18} className="text-primaryAppointment  mr-1 self-center" />

                            <span className="text-xs text-primaryAppointment  -tracking-[0.12px] font-medium">{t("Directions")}</span>
                            <FiArrowRight size={18} className="text-primaryAppointment  ms-3 self-center" />
                        </div> */}
                    </div>
                </div>
                <div className="hidden lg:flex items-center text-primaryAppointment opacity-0 group-hover:opacity-100 ml-2">
                    <ArrowNarrowRight className="" />
                </div>
            </label>
        </div>
    );
};

export default BarberDetailCard;
