import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITabs } from "../../Interface";
import { RootState } from "src/redux/store";

const initialState: ITabs = {
    initSelectedTemplate: {
        template1: false,
        template2: true,
        template3: false,
        template4: false,
        personal_template1: false,
    },
    initSelectedTab: {
        hero: false,
        team: false,
        about: false,
        footer: false,
        academy: false,
        gallery: false,
        product: false,
        service: false,
        location: false,
        testimonial: false,
        language: false,
    },
    isScrollable: false,
    isGetApp: true,
};

export const landingSlice = createSlice({
    name: "landing",
    initialState,
    reducers: {
        selectTab: (state, action: PayloadAction<keyof ITabs["initSelectedTab"]>) => {
            Object.keys(state.initSelectedTab).forEach((key) => {
                const tabKey = key as keyof ITabs["initSelectedTab"];
                state.initSelectedTab[tabKey] = false;
            });
            state.initSelectedTab[action.payload] = true;
        },
        selectTemplate: (state, action: PayloadAction<keyof ITabs["initSelectedTemplate"]>) => {
            Object.keys(state.initSelectedTemplate).forEach((key) => {
                state.initSelectedTemplate[key as keyof ITabs["initSelectedTemplate"]] = false;
            });
            state.initSelectedTemplate[action.payload] = true;
        },
        setIsScrollable: (state, action: PayloadAction<boolean>) => {
            state.isScrollable = action.payload;
        },
        setIsGetApp: (state, { payload }) => {
            state.isGetApp = payload;
        },
    },
});

export const { selectTab, selectTemplate, setIsScrollable, setIsGetApp } = landingSlice.actions;
export const isGetApp = (state: RootState) => state.LandingStates.isGetApp;
export default landingSlice.reducer;
