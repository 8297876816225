import React, { useEffect, useState } from "react";
import SelectBox from "../../../../components/SelectBox";
import Info from "./Info";

interface Props {
    data: any;
    locationSetting: any;
    sectionIndex: number;
}
const Professional: React.FC<Props> = ({ data, locationSetting, sectionIndex }) => {
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [staffList, setStaffList] = useState([]);
    useEffect(() => {
        const locationOptionsData: any = locationSetting.locations.map((location: any) => ({
            value: location.title,
            label: location.title,
            id: location.title,
        }));
        const selectAllOption = {
            value: null,
            label: "ALL LOCATIONS",
            id: 0,
        };
        const updatedLocationOptionsData: any = [selectAllOption, ...locationOptionsData];

        setLocationOptions(updatedLocationOptionsData);
    }, []);
    const handleLocation = (e: any) => {
        setSelectedLocation(e.value);
    };
    useEffect(() => {
        if (selectedLocation) {
            const filteredStaff = data?.staff.filter((staffInfo: any) => staffInfo.location.some((location: any) => location === selectedLocation));
            setStaffList(filteredStaff);
        } else {
            setStaffList(data?.staff);
        }
    }, [selectedLocation]);
    return (
        <>
            <div className="w-full flex justify-between items-center mb-4">
                <div className="w-full flex items-center">
                    <div className="slice-label">
                        <span className="num">{sectionIndex}</span>
                        <span className="slice-text">OUR PROFESSIONALS</span>
                    </div>
                </div>
                <SelectBox
                    placeholder={"All Locations"}
                    name="location"
                    allowClear={false}
                    options={locationOptions}
                    inputClass="w-[190px]"
                    searchable={true}
                    onChange={handleLocation}
                    value={locationOptions.filter((item: any) => item.value === selectedLocation)}
                    disabled={false}
                    // inputClass={`${errors.gender ? "is-invalid border-solid" : ""}`}
                />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 text-txtcolor mb-5">
                {staffList.map((staff: any, index: React.Key | null | undefined) => (
                    <Info key={index} data={staff} />
                ))}
            </div>
        </>
    );
};

export default Professional;
