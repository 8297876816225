import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { axiosGet, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { getCardTypeImage } from "src/utils/global-functions";
import Sidebar from "src/components/Sidebar";
import AddNewCardTabComponent from "../BookAppointment/FinalizeBooking/AddNewCardTabComponent";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { Plus } from "@untitled-ui/icons-react/build/cjs";

const PaymentMethod = () => {
    const { t } = useTranslation();
    const [stripeCards, setStripeCards] = useState([]);
    const [isStripeCardLoading, setIsStripeCardLoading] = useState(false);
    const [defaultCardId, setDefalutCardId] = useState<string | undefined>(undefined);
    const [isShowAddNewCardTab, setIsShowAddNewCardTab] = useState(false);
    const uiState = useAppSelector((data) => data.UiStates);

    useEffect(() => {
        if (!isShowAddNewCardTab) {
            listStripeCards();
        }
    }, [isShowAddNewCardTab]);

    const listStripeCards = () => {
        setIsStripeCardLoading(true);
        axiosGet(API.STRIPE.CARD_LIST)
            .then((response) => {
                setStripeCards(response.data.data.cards);
                setDefalutCardId(response.data.data.default_card_id);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsStripeCardLoading(false);
            });
    };

    const handleDefault = (paymentMethodId: string) => () => {
        if (defaultCardId === paymentMethodId) {
            return;
        }
        setIsStripeCardLoading(true);
        axiosPost(API.STRIPE.CARD_DEFAULT, { payment_method_id: paymentMethodId })
            .then(() => {
                setDefalutCardId(paymentMethodId);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsStripeCardLoading(false);
            });
    };

    return (
        <div className="flex flex-col lg:flex-1">
            <div className="flex flex-col mb-4 mt-1 lg:mt-[20px] px-4 lg:px-5">
                <div className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                    Payment method
                </div>
                <div className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">Update your plan payment details</div>
            </div>

            <div className="flex flex-col gap-4 px-4 lg:px-5 flex-1">
                <div className="flex flex-col flex-1 gap-2.5 lg:gap-3.5">
                    {stripeCards.length > 0 ? (
                        stripeCards.map((stripeCard: any) => (
                            <div
                                className="p-3 rounded-lg border border-borderColorPrimary flex items-center cursor-pointer"
                                // onClick={(e) => {
                                //     e.stopPropagation();
                                //     handlePaymentMethodChange("card", stripeCard.id);
                                // }}
                                key={stripeCard.id}
                            >
                                <img src={getCardTypeImage(stripeCard.card.brand)} alt="" className="mr-2.5 lg:mr-3 rounded-lg w-[46px] h-[46px] border border-borderColorPrimary object-contain" />
                                <div>
                                    <h4 className="text-sm font-semibold text-txtcolor">
                                        {stripeCard.card.brand} card ending in {stripeCard.card.last4}
                                    </h4>
                                    {stripeCard.id === defaultCardId ? (
                                        <span className="text-xs">{t("Default")}</span>
                                    ) : (
                                        <span className="text-xs text-primary cursor-pointer" onClick={handleDefault(stripeCard.id)}>
                                            {t("Set as default")}
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div
                            className="w-full border border-dashed border-borderColorPrimary px-[12px] py-[12px] flex flex-col items-center h-[92px]  rounded-secondary justify-center bg-mainBackgroundColor cursor-pointer mb-4"
                            onClick={() => setIsShowAddNewCardTab(true)}
                        >
                            {isStripeCardLoading ? (
                                <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{t("Loading")}</p>
                            ) : (
                                <>
                                    <span className="flex items-center justify-center h-[26px] w-[26px] mb-1">
                                        <Plus className="w-[16px] h-[16px]" />
                                    </span>
                                    <p className="text-sm text-textGrey font-medium leading-[18px] tracking-[-0.01rem]">Click here to add payment method</p>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className={`w-full mb-4 mt-auto ${stripeCards.length > 0 ? "flex" : "hidden"}`}>
                    <CustomButton primary className="!h-[48px] w-full" onClick={() => setIsShowAddNewCardTab(true)}>
                        {t("Add payment method")}
                    </CustomButton>
                </div>
            </div>
            <Sidebar
                isOpen={isShowAddNewCardTab}
                handleClose={() => setIsShowAddNewCardTab(false)}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`} // w-[460px]"
                customClass={"profile-sidebar-pages max-md:h-auto top-line-remove"}
                isBottomView={uiState.isMobile ? true : false}
                handleBack={() => setIsShowAddNewCardTab(false)}
                isBack={true}
            >
                {isShowAddNewCardTab && (
                    <AddNewCardTabComponent
                        closeAddNewCardTab={() => {
                            setIsShowAddNewCardTab(false);
                        }}
                    />
                )}
            </Sidebar>
        </div>
    );
};

export default PaymentMethod;
