import React, { useEffect, useRef, useState } from "react";
import Tooltip from "src/components/Tooltip/Tooltip";
import { useAppSelector } from "../../../redux/hooks";
import { InfoCircle, X } from "@untitled-ui/icons-react/build/cjs";
import { animated } from "react-spring";
import { onError } from "src/utils/global-functions";

const ProductCard = ({ product, onClick, handleAction, isSelected = false, style }: any) => {
    const uiState = useAppSelector((data) => data.UiStates);
    const variantContainerRef = useRef<HTMLDivElement>(null);
    const [hoverProduct, setHoverProduct] = useState<any>(null);
    const [defaultPrice, setDefaultPrice] = useState<number | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        if (product.variants && product.variants.length > 0) {
            setDefaultPrice(product.variants[0].inventory.price); // Set default price to the first variant's price
        }
    }, [product]);

    const handleMouseEnter = (variantDetails: any) => () => {
        setHoverProduct(variantDetails);
    };

    const handleMouseLeave = () => {
        setHoverProduct(null);
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - variantContainerRef.current!.offsetLeft);
        setScrollLeft(variantContainerRef.current!.scrollLeft);
    };
    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return; // Only scroll when dragging
        e.preventDefault();
        const x = e.pageX - variantContainerRef.current!.offsetLeft;
        const walk = x - startX; // Adjust scroll speed
        variantContainerRef.current!.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false); // Stop scrolling when mouse is released
    };

    return (
        <animated.div style={style} className="w-full flex flex-col px-2 pb-4 relative z-[99] hover:z-[9999]">
            <div
                className={`border p-3 rounded-secondary border-borderColorPrimary h-full  bg-productBackgroundColor relative hover:bg-primaryHover hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                    product.selectedVariant ? "!bg-primaryHover shadow-[0_2px_16px_0_rgba(0,0,0,0.10)]" : ""
                } hover:z-[9999]`}
            >
                {product.selectedVariant && (
                    <button
                        className="absolute -right-[6px] -top-[6px] z-10 flex justify-center items-center w-6 h-6 bg-white border-borderColorPrimary text-txtcolor rounded-full border"
                        onClick={onClick(product, product.selectedVariant)}
                    >
                        <X width="18" />
                    </button>
                )}
                <div
                    className="cursor-pointer relative"
                    onClick={!isSelected ? (product.variants && product.variants.length > 1 ? handleAction("product", product) : onClick(product, product.variants[0])) : undefined}
                >
                    <img
                        // className="w-full rounded-[12px] h-[118px] sm:h-[106px] md:h-[139px] lg:h-[80px] xl:h-[110px] 2xl:h-[138px] 3xl:h-[200px]  object-cover"
                        className="w-full rounded-[12px] aspect-square  object-cover"
                        src={product?.images?.find((image: any) => image?.is_main)?.image_url}
                        alt=""
                        onError={onError}
                    />
                    {product.selectedVariant ? (
                        <>
                            <div className=" absolute right-[6px] bottom-[6px]">
                                <div className="bg-black text-white rounded-full px-3 py-1 text-xs font-medium -tracking-[0.01rem]">
                                    {product.selectedVariant.size} {product.unit_of_measure} - ${product.selectedVariant.price}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className=" absolute right-[6px] bottom-[6px]">
                                <div className="bg-black text-white rounded-full px-3 py-1 text-xs font-medium -tracking-[0.01rem]">
                                    {product.variants && product.variants.length > 1 ? `${product.variants.length} Options` : `$${defaultPrice}`}
                                </div>
                            </div>
                        </>
                    )}
                    {/* {(hoverProduct && product.id === hoverProduct.shop_product_id) || defaultPrice ? (
                        <div className=" absolute right-[6px] bottom-[6px]">
                            <div className="bg-black text-white rounded-full px-3 py-1 text-xs font-medium -tracking-[0.01rem]">${hoverProduct ? hoverProduct.price : defaultPrice}</div>
                        </div>
                    ) : null} */}
                </div>

                <div className="flex flex-col w-full mt-3 ">
                    <div className="flex gap-1 items-center ">
                        <h2 className="text-sm font-normal text-txtcolor leading-[18.2px] -tracking-[0.02rem] flex-auto truncate">{product.name}</h2>
                        {uiState.isMobile ? (
                            <div className="cursor-pointer items-end" onClick={!isSelected ? handleAction("product", product) : undefined}>
                                <InfoCircle className="text-primary w-4 h-4" />
                            </div>
                        ) : (
                            <Tooltip title={product.name} description={product.description}>
                                <InfoCircle id="alert_circle_svg" width={16} className="text-primary cursor-pointer" onClick={!isSelected ? handleAction("product", product) : undefined} />
                            </Tooltip>
                        )}
                    </div>
                    {/* <div className="flex items-center gap-[6px] -tracking-[0.03rem] overflow-auto no-scrollbar">
                        {product.selectedVariant ? (
                            <div className="variant  cursor-pointer active_add relative" onClick={onClick(product, product.selectedVariant)}>
                                <span className="variant_text_title whitespace-nowrap">
                                    {product.selectedVariant.size} {product.unit_of_measure}
                                </span>
                            </div>
                        ) : (
                            <div
                                className="flex items-center gap-[6px] -tracking-[0.03rem] overflow-x-auto no-scrollbar"
                                ref={variantContainerRef}
                                onMouseDown={handleMouseDown} // Start drag when mouse is down
                                onMouseMove={handleMouseMove} // Drag to scroll
                                onMouseUp={handleMouseUp} // Stop dragging on mouse up
                                onMouseLeave={handleMouseUp}
                            >
                                {product.variants.map((variant: any) => (
                                    <div
                                        className={`variant group hover:border-primary hover:text-primary cursor-pointer relative ${
                                            !hoverProduct && defaultPrice === variant.inventory.price ? "border-borderColorPrimary" : ""
                                        }`}
                                        key={variant.id}
                                        onClick={onClick(product, variant)}
                                        onMouseOver={handleMouseEnter(variant)}
                                        onFocus={handleMouseEnter(variant)}
                                        onMouseOut={handleMouseLeave}
                                        onBlur={handleMouseLeave}
                                    >
                                        <span className="variant_text_title whitespace-nowrap">
                                            {variant.size} {product.unit_of_measure}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
        </animated.div>
    );
};

export default ProductCard;
