import React from "react";
import { Step3PNG } from "src/theme/Images";
const Step3 = () => (
    <>
        <div className="flex flex-col">
            <span className="text-[16px] leading-[22.4px] font-normal text-txtAppointmentColor tracking-[-0.01rem] mb-3 min-h-[44px] max-h-[44px]">
                Select option and Scroll down and select <span className="text-primary leading-[22.4px] text-[16px]">“Add to Home Screen”</span>
            </span>
            <div className="phone-body-bg">
                <img src={Step3PNG} className="w-full rounded-secondary" alt="" />
            </div>
        </div>
    </>
);

export default Step3;
