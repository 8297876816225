import React, { useState } from "react";
interface Props {
    data: any;
    sectionIndex: number;
}
const Product: React.FC<Props> = ({ data, sectionIndex }) => {
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 0, isShow: false });

    type ProductProps = {
        info: any;
        index: React.Key | null | undefined;
    };

    const Products: React.FC<ProductProps> = ({ info, index }) => (
        <div
            className="border-b relative service-duration py-[5.2vw] md:py-[3vw] lg:py-[2vw] xl:py-[1.2vw] border-borderColorPrimary border-b-animation text-txtcolor transition-all cursor-pointer"
            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
        >
            <div className="flex justify-between items-center">
                <div className="flex w-1/2">
                    <h3 className="text-base md:text-lg xl:text-xl leading-[1] pt-2.5 font-normal uppercase text-txtcolor">{info.name}</h3>
                </div>
                <div className="flex w-1/2 md:pl-5 xl:pl-0 justify-between">
                    <div className="flex-1 hidden md:flex">
                        <h3 className="text-base md:text-lg xl:text-xl leading-[1] pt-2.5 font-normal uppercase text-txtcolor">${Number(info.price).toFixed(2)}</h3>
                    </div>
                    <div className="w-full lg:w-auto text-right">
                        <button
                            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
                            className="text-base md:text-lg xl:text-xl font-normal leading-[1] pt-2.5 uppercase text-txtcolor min-w-8"
                        >
                            {isCollapsed.isShow && isCollapsed.id === index ? "LESS −" : "MORE +"}
                        </button>
                    </div>
                </div>
            </div>
            {isCollapsed.isShow && isCollapsed.id === index && (
                <div className="mt-4 service-duration">
                    <div className="flex flex-col lg:flex-row justify-between gap-[9px]">
                        <div className="w-full lg:w-1/2">
                            {info.urls && info.urls[0] && <img src={`${info.urls[0]}`} alt="" className="w-full h-[141px] object-cover " />}
                            <div className="grid grid-cols-3 gap-[9px] mt-2">
                                {info.urls &&
                                    info.urls.length > 1 &&
                                    info.urls
                                        .slice(1)
                                        .map((imgUrl: string, urlIndex: React.Key | null | undefined) => <img key={urlIndex} src={imgUrl} alt="" className="w-full h-[75px] object-cover" />)}
                            </div>
                        </div>
                        <div className="flex flex-col w-full lg:w-1/2">
                            <p className=" text-justify sub_text mb-5">{info.description}</p>
                            <button className="bg-primary text-white px-8 py-3 h-[42px]">BUY NOW</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div className="mb-5">
            <div className="slice-label mb-4">
                <span className="num">{sectionIndex}</span>
                <span className="slice-text">PRODUCT</span>
            </div>
            {data?.products?.map((product: any, index: React.Key | null | undefined) => (
                <Products key={index} info={product} index={index} />
            ))}
        </div>
    );
};

export default Product;
