import React, { useEffect, useState } from "react";
import "./index.scss";

interface IProps {
    isLoading?: boolean;
}

const BarLoader = ({ isLoading = true }: IProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        let timeoutId: any;

        if (isLoading === false) {
            setVisible(false);
        } else {
            // Calculate the next multiple of 3 seconds
            const currentTime = new Date().getTime();
            const nextMultipleOfThree = Math.ceil(currentTime / 4500) * 3000;
            const delay = nextMultipleOfThree - currentTime;
            // Set the visibility after the calculated delay
            timeoutId = setTimeout(() => {
                setVisible(false);
            }, delay);
        }
        return () => clearTimeout(timeoutId);
    }, [isLoading]);
    return (
        <>
            {visible ? (
                <div className="relative">
                    <div className="loader"></div>
                </div>
            ) : null}
        </>
    );
};

export default BarLoader;
