import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/redux/hooks";
import { currentSettings, currentShop } from "src/redux/reducers/common/Common.slice";
import { UnionSVG } from "src/theme/Images";

const GetLogo = () => {
    const shop = useAppSelector(currentShop);
    const settings = useAppSelector(currentSettings);
    const [appearance, setAppearance] = useState<any>(null);

    useEffect(() => {
        if (settings.length) {
            const appearances = settings.find((setting: any) => setting.type === "appearance");
            if (appearances) {
                setAppearance(appearances.value);
            }
        }
    }, [settings]);
    const getLogo = () => {
        let response;
        let logo;
        let text;

        if (shop) {
            logo = <img src={shop.logo_image_url ?? UnionSVG} className="max-w-[120px] max-h-[46px] lg:max-h-[50px]  object-contain" alt="" />;
            text = (
                <p className="text-txtcolor hover:text-primary  cursor-pointer text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] flex items-center font-logo font-logoWeight">
                    {shop.business_name}
                </p>
            );

            if (appearance) {
                switch (appearance.logo_type) {
                    case "logo":
                        response = logo;
                        break;
                    case "logo_text":
                        response = (
                            <div className="flex items-center text-center justify-center flex-col gap-2">
                                {logo} {text}
                            </div>
                        );
                        break;
                    case "text":
                        response = text;
                        break;
                    default:
                        break;
                }
            }
        }

        return response;
    };
    return <div>{getLogo()}</div>;
};

export default GetLogo;
