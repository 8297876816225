export interface IIsLoading {
    deafultCard: boolean;
    cardList: boolean;
    id: number | null;
}

export const initLoading = {
    deafultCard: false,
    cardList: false,
    id: null,
};
