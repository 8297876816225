import React, { useState } from "react";
import Info from "./Info";

interface Props {
    data: any;
}
const Service: React.FC<Props> = ({ data }) => {
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 0, isShow: false });

    return (
        <div className="w-full spacing-top ">
            <div className="px-5 md:px-[34px] bg-primary py-20">
                <h2 className="custom-heading uppercase text-primaryText text-[6.9vw] lg:text-[2.78vw]">{data?.title}</h2>
                <div className="py-[6vw] md:py-[3.2vw] lg:py-[2.4vw] xl:py-[1.4vw]">
                    {data?.services?.map((service: any, index: number) => (
                        <Info index={index} service={service} key={service.id} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Service;
