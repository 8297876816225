import React from "react";
import moment from "moment";
import { format } from "date-fns";
import { BiCheck, BiHelpCircle, BiXCircle } from "react-icons/bi";
import { onError } from "src/utils/global-functions";

const BookingDetails = ({ handleAction, bookingDetails, key }: any) => (
    <div
        key={key}
        className={` bg-cardBackground rounded-secondary p-[12px] lg:shadow-[0px_4px_70px_0px_rgba(0,0,0,0.10)] flex flex-wrap items-center justify-between cursor-pointer font-primary shadow-[0px_4px_10px_0px_#0000000D] z-10`}
        onClick={handleAction("booking", bookingDetails)}
    >
        <div className="flex item-center gap-[15px] w-full">
            <div className="relative">
                <div className="w-[44px] relative">
                    <img src={bookingDetails.location.location_image_url} alt="" onError={onError} className="w-full h-[44px] object-cover relative rounded-primary" />
                    <div className="flex gap-[10px] flex-wrap items-center mt-2 absolute bottom-[-4px] right-[-4px]">
                        {bookingDetails.status === "confirmed" && (
                            <span className="bg-success rounded-full p-[1px]">
                                <BiCheck className="text-white w-4 h-4" />
                            </span>
                        )}
                        {bookingDetails.status === "pending" && (
                            <span className="bg-pending rounded-full p-[1px]">
                                <BiHelpCircle className="text-white w-4 h-4" />
                            </span>
                        )}
                        {bookingDetails.status === "declined" && (
                            <span className="bg-danger rounded-full p-[1px]">
                                <BiXCircle className="text-white w-4 h-4" />
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex-1 flex flex-col justify-center">
                <div className="flex flex-row gap-2">
                    <p className="text-textGrey text-xs font-medium pt-0.5 tracking-[0.03rem]">Appointment</p>
                    <p className="text-txtAppointmentColor text-xs font-medium pt-0.5 tracking-[0.03rem]">#{bookingDetails?.receipt_code}</p>
                </div>
                <p className="font-medium text-base text-txtAppointmentColor mt-1 tracking-[0.03rem]">{bookingDetails?.staff?.full_name}</p>
                <p className="text-textGrey text-xs leading-[18px] font-normal mt-1 tracking-[0.03rem]">
                    {moment(bookingDetails.booking_date).format("dddd, DD MMM YYYY • ")}
                    {format(new Date(`${bookingDetails.booking_date} ${bookingDetails.booking_start_time}`), "hh:mm-")}
                    {format(new Date(`${bookingDetails.booking_date} ${bookingDetails.booking_end_time}`), "hh:mm a")}
                </p>
            </div>
        </div>
    </div>
);

export default BookingDetails;
