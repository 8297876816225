import React, { useEffect, useRef, useState } from "react";
import MarkerAdvance from "./MarkerAdvance";
import { MapStyle } from "./MapStyle";
import { IShopLocation } from "../../Interface";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FiMapPin } from "react-icons/fi";
import { PlaceholderImgPNG, LocationActiveSVG, LocationGraySVG } from "src/theme/Images";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setBookingInitialState, setSelectedShopLocation } from "../../app/BookAppointment/Booking.slice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { onError } from "src/utils/global-functions";

const DEFAULT_ZOOM = 14;

interface IProps {
    shopLocationData: IShopLocation[];
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
    showAppointmentShopOnMap: IShopLocation | undefined;
    setShowAppointmentShopOnMap?: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
}

const MyMap = ({ shopLocationData, selectedShopData, setSelectedShopData, showAppointmentShopOnMap, setShowAppointmentShopOnMap }: IProps) => {
    const { t } = useTranslation();
    const [map, setMap] = useState<google.maps.Map | undefined>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const bookingState = useAppSelector((data) => data.Booking);

    const ref = useRef<any>();

    const checkIsMarkerVisible = (markerPosition: any) => {
        var mapBounds = map?.getBounds();

        if (mapBounds && markerPosition) {
            return mapBounds.contains(markerPosition);
        }

        return false;
    };

    useEffect(() => {
        const styledMapType = new google.maps.StyledMapType(MapStyle, {
            name: "Styled Map",
        });

        const createdMap = new window.google.maps.Map(ref.current, {
            mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
            center: { lat: 21.2496398, lng: 72.8934363 },
            gestureHandling: "auto",
            zoom: DEFAULT_ZOOM,
            disableDefaultUI: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: {
                mapTypeIds: ["styled_map"],
            },
        });

        createdMap.mapTypes.set("styled_map", styledMapType);
        createdMap.setMapTypeId("styled_map");

        setMap(createdMap);
    }, []);

    useEffect(() => {
        if (selectedShopData) {
            const isMarkerVisible = checkIsMarkerVisible({
                lat: Number(selectedShopData.latitude),
                lng: Number(selectedShopData.longitude),
            });
            if (!isMarkerVisible) {
                panAndZoomToLocation({
                    lat: Number(selectedShopData.latitude),
                    lng: Number(selectedShopData.longitude),
                });
            }
        }
    }, [map, selectedShopData]);

    useEffect(() => {
        if (shopLocationData.length && shopLocationData[0].latitude && shopLocationData[0].longitude) {
            panAndZoomToLocation({
                lat: Number(shopLocationData[0].latitude),
                lng: Number(shopLocationData[0].longitude),
            });
        }
    }, [map, shopLocationData]);

    const panAndZoomToLocation = (position: any) => {
        map?.panTo(position);
        map?.setZoom(DEFAULT_ZOOM);
    };

    useEffect(() => {
        if (map && showAppointmentShopOnMap && showAppointmentShopOnMap.latitude && showAppointmentShopOnMap.longitude) {
            panAndZoomToLocation({
                lat: Number(showAppointmentShopOnMap.latitude) + 0.02,
                lng: Number(showAppointmentShopOnMap.longitude),
            });
            if (setShowAppointmentShopOnMap) {
                google.maps.event.addListener(map, "click", () => {
                    setShowAppointmentShopOnMap(undefined);
                });
            }
        }
    }, [map, showAppointmentShopOnMap]);

    const handleLocationRedirection = (destinationLat: string, destinationLng: string) => {
        const location = window.navigator && window.navigator.geolocation;
        if (location) {
            location.getCurrentPosition(
                (position) => {
                    const redirectUrl = `https://www.google.com/maps/dir/?api=1&origin=${position.coords.latitude},${
                        position.coords.longitude
                    }&destination=${destinationLat},${destinationLng}&key=${process.env.REACT_APP_GOOGLE_API_KEY!}`;
                    window?.open(redirectUrl, "_blank");
                },
                (error) => {
                    const redirectUrl = `https://www.google.com/maps?q=${destinationLat},${destinationLng}`;
                    window?.open(redirectUrl, "_blank");
                },
            );
        } else {
            const redirectUrl = `https://www.google.com/maps?q=${destinationLat},${destinationLng}`;
            window?.open(redirectUrl, "_blank");
        }
    };

    const handleStartBookingWithShopLocation = (location: IShopLocation) => {
        if (location.id !== bookingState.selectedShopLocation?.id || bookingState.isModifyingAppointment) {
            dispatch(setBookingInitialState());
        }
        dispatch(setSelectedShopLocation(location));
        navigate(ROUTES.BOOK_APPOINTMENT);
    };

    return (
        <>
            <div ref={ref} id="map" className="h-full w-full" />
            {map && (
                <>
                    {shopLocationData.map((shopLocation: IShopLocation) => (
                        <MarkerAdvance
                            key={shopLocation.id}
                            map={map}
                            shopLocation={shopLocation}
                            isSelected={shopLocation?.id === selectedShopData?.id || shopLocation?.id === showAppointmentShopOnMap?.id}
                            onClick={(e: IShopLocation) => handleStartBookingWithShopLocation(e)}
                        >
                            <img
                                src={shopLocation?.id === selectedShopData?.id || shopLocation?.id === showAppointmentShopOnMap?.id ? LocationActiveSVG : LocationGraySVG}
                                alt=""
                                onMouseEnter={() => setSelectedShopData(shopLocation)}
                                onMouseLeave={() => setSelectedShopData(undefined)}
                            />
                        </MarkerAdvance>
                    ))}
                    {showAppointmentShopOnMap && (
                        <MarkerAdvance
                            key={showAppointmentShopOnMap.id}
                            map={map}
                            shopLocation={showAppointmentShopOnMap}
                            isSelected={selectedShopData?.id === showAppointmentShopOnMap?.id}
                            onClick={(e: IShopLocation) => handleStartBookingWithShopLocation(e)}
                        >
                            <img
                                src={showAppointmentShopOnMap?.id === selectedShopData?.id ? LocationActiveSVG : LocationGraySVG}
                                alt=""
                                onMouseEnter={() => setSelectedShopData(showAppointmentShopOnMap)}
                                onMouseLeave={() => setSelectedShopData(undefined)}
                            />

                            <div role="tooltip" className="absolute z-50 bg-cardColor bottom-[50px] left-1/2 -translate-x-1/2 border border-line rounded-3xl shadow-sm w-[230px]">
                                <div id="arrow" className="tooltip-bottom"></div>
                                <img
                                    src={showAppointmentShopOnMap?.location_image_url}
                                    alt=""
                                    className="w-full rounded-t-3xl h-[150px] transition-transform transform-gpu object-cover"
                                    onError={onError}
                                />
                                <div className="p-5">
                                    <p className="text-base font-bold text-txtcolor leading-[140%] -tracking-[0.16px]">{showAppointmentShopOnMap.name}</p>
                                    <p className="mt-2 text-xs text-secondaryTxt leading-[150%] -tracking-[0.12px] font-normal">
                                        {`${showAppointmentShopOnMap?.street}, ${showAppointmentShopOnMap?.city}, ${showAppointmentShopOnMap?.country} ${showAppointmentShopOnMap?.postal_code}`}
                                    </p>
                                    <div className="mt-4">
                                        <span
                                            className="flex items-center text-primary gap-2"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleLocationRedirection(showAppointmentShopOnMap?.latitude, showAppointmentShopOnMap?.longitude);
                                            }}
                                        >
                                            <FiMapPin size={16} />
                                            <p className="text-sm  font-normal">{t("Get directions")}</p>
                                            <HiOutlineArrowSmRight size={22} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </MarkerAdvance>
                    )}
                </>
            )}
        </>
    );
};

export default MyMap;
