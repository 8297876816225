import React from "react";
interface Props {
    data: any;
}
const Course: React.FC<Props> = ({ data }) => (
    <section className=" text-txtcolor spacing-top flex">
        <div className="flex flex-col md:flex-row overflow-hidden w-full">
            <div className="w-full md:w-1/2 md:pr-12 ">
                {data?.is_title && <h2 className="custom-heading pt-5 font-semithin uppercase">{data.title}</h2>}

                <div className="flex flex-col md:flex-row justify-between my-6">
                    <p className="text-3xl font-semithin">${Number(data.price).toFixed(2)}</p>
                    <p className="text-xl text-secondaryTxt">{data.time}</p>
                </div>
                {data.is_description && (
                    <>
                        <p className="text-2xl mb-4 text-txtcolor font-normal uppercase">Description Course</p>
                        <p className="mb-6 text-justify text-txtcolor text-base md:text-lg">{data.description}</p>
                    </>
                )}
                <form className="space-y-4">
                    <div className="flex flex-col md:flex-row justify-between gap-8">
                        <div className="w-full">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="w-full pl-0 pt-1 pr-3 pb-3 bg-contentBackground border-[0px] !outline-0 border-b border-text-secondaryTxt mt-1 placeholder:text-txtcolor placeholder:text-lg placeholder:font-normal  text-lg font-normal focus:outline-none focus:ring-0 focus-visible:border-text-txtcolor focus-within:border-text-txtcolor autofill:border-text-txtcolor focus:border-text-txtcolor"
                                placeholder="NAME*"
                                required
                            />
                        </div>
                        <div className="w-full">
                            <input
                                type="text"
                                id="phone"
                                name="number"
                                className="w-full pl-0 pt-1 pr-3 pb-3 bg-contentBackground border-[0px] outline-0 !focus:outline-0 focus-within:outline-0 active:outline-0 border-b border-text-secondaryTxt mt-1 placeholder:text-txtcolor placeholder:text-lg placeholder:font-normal  text-lg font-normal focus:outline-none focus:ring-0 focus-visible:border-text-txtcolor focus-within:border-text-txtcolor autofill:border-text-txtcolor focus:border-text-txtcolor"
                                placeholder="PHONE NUMBER"
                                required
                            />
                        </div>
                    </div>
                    <div className="!mb-7">
                        <textarea
                            name="plan"
                            className="w-full pl-0 pt-1 pr-3 pb-3 bg-contentBackground placeholder:uppercase border-[0px] outline-0 border-b border-text-secondaryTxt mt-1 placeholder:text-txtcolor placeholder:text-lg placeholder:font-normal text-lg font-normal focus:outline-none focus:ring-0 focus-visible:border-text-txtcolor focus-within:border-text-txtcolor autofill:border-text-txtcolor focus:border-text-txtcolor"
                            rows={3}
                            placeholder="Tell us your plan*"
                            required
                        />
                    </div>
                    <button type="submit" className="bg-primary text-primaryText px-8 py-3 h-[42px]">
                        {data.button}
                    </button>
                </form>
            </div>
            <div className="w-full md:w-1/2 bg-cover bg-center" style={{ backgroundImage: `url(${data.image_url})` }}></div>
        </div>
    </section>
);

export default Course;
