import React, { ReactElement, SyntheticEvent, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useAppSelector } from "src/redux/hooks";

interface IProps extends Omit<ReactDatePickerProps, "name" | "onChange" | "placeholderText"> {
    inputName?: string | undefined;
    className?: string;
    icon?: ReactElement;
    tabIndex?: number | undefined;
    errorText?: string | null;
    required?: boolean | undefined;
    onChangeFunc(date: Date, event: SyntheticEvent<any> | undefined): void;
    selectedDate: Date | undefined;
    minDate?: Date | undefined;
    closeOnOutsideClick?: boolean;
}

const DateSelectButton = React.forwardRef(
    ({ inputName, className, errorText, onKeyDown, required, tabIndex, icon, onChangeFunc, children, selectedDate, minDate, closeOnOutsideClick, ...props }: IProps, ref: any) => {
        const uiState = useAppSelector((uiData) => uiData.UiStates);
        const [isOpen, setIsOpen] = useState(false);
        const handleChange = (date: Date, e: SyntheticEvent<any> | undefined) => {
            setIsOpen(!isOpen);
            onChangeFunc(date, e);
        };
        const handleClick = (e: any) => {
            e.preventDefault();
            setIsOpen(!isOpen);
        };

        return (
            <div className={className}>
                <button className="example-custom-input" onClick={handleClick}>
                    {children}
                </button>
                {isOpen && (
                    <DatePicker
                        selected={selectedDate && new Date(selectedDate)}
                        onChange={handleChange}
                        inline={true}
                        popperPlacement="top-end"
                        minDate={minDate}
                        disabledKeyboardNavigation={true}
                        onClickOutside={() => {
                            if (closeOnOutsideClick) {
                                setIsOpen(false);
                            }
                        }}
                    />
                )}
            </div>
        );
    },
);

export default DateSelectButton;
