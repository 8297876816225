import React from "react";
import { Tooltip as FlowbiteTooltip } from "flowbite-react";
import { ITooltip } from "./Tooltip.interface";

const Tooltip: React.FC<ITooltip> = ({
    title,
    description,
    titleClassname,
    position = "auto", // Default position
    descriptionClassname,
    children,
}) => (
    <FlowbiteTooltip
        content={
            <div>
                {title && <p className={`text-tooltipTextColor text-sm font-medium -tracking-[0.01rem] mb-1 ${titleClassname}`}>{title}</p>}
                {description && <p className={`text-tooltipTextColor font-normal text-xs -tracking-[0.01rem] ${descriptionClassname}`}>{description}</p>}
            </div>
        }
        trigger="hover"
        style={"light" as any}
        className="w-64 bg-tooltipBg !rounded-secondary tracking-[0.03rem] !py-3 !border-0 z-[999]"
        placement={position}
    >
        {children}
    </FlowbiteTooltip>
);

export default Tooltip;
