import React from "react";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";
import moment from "moment";
import { onError } from "src/utils/global-functions";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { GoDotFill } from "react-icons/go";

interface IProps {
    booking: IAppointmentBooking;
    handleAction: (action: string, booking: IAppointmentBooking) => (event: any) => void;
}
const BookingCard = ({ booking, handleAction }: IProps) => {
    const { t } = useTranslation();

    const textColorClass = booking.status === "completed" ? "text-[#2dce89]" : booking.status === "canceled" ? "text-[#E33B3B]" : "text-[#FDB022]";

    return (
        <div className="border rounded-secondary border-borderColorPrimary">
            <div className="flex items-start p-3 justify-between border-b border-borderColorPrimary">
                <div className="flex flex-row items-center">
                    <img src={booking.location.location_image_url} alt="" onError={onError} className="mr-3 w-[44px] min-w-[44px] h-[44px] object-cover rounded-primary" />
                    <div className="items-center">
                        <h3 className="text-[16px] font-medium text-txtAppointmentColor tracking-[-0.01rem] leading-[22.4px] mb-1">{booking.location.name}</h3>
                        <p className="text-xs text-textGrey font-normal leading-[18px] tracking-[-0.01rem]">
                            {`${booking.location.street}, ${booking.location.city}, ${booking.location.country} ${booking.location.postal_code}`}
                        </p>
                    </div>
                </div>
                {booking.status === "completed" && (
                    <button className="text-xs text-primaryAppointment font-medium text-right flex-1" onClick={handleAction("tip", booking)}>
                        {t("Add Tip")}
                    </button>
                )}
            </div>
            <div className="flex gap-2 p-3 flex-row">
                <span className="text-xs text-txtAppointmentColor font-normal flex items-center  leading-[18px] flex-1 truncate">
                    {moment(`${booking.booking_date} ${booking.booking_start_time}`).format("DD MMM YYYY - hh:mm A")}
                </span>

                <div className="flex items-center justify-center rounded-[6px] border border-borderCounter pr-1.5 pl-1 h-[24px] shadow-[0px_1px_2px_0px_#1018280D]">
                    <span className="flex items-center">
                        <GoDotFill size={12} className={`w-3 h-3 mr-0.5 ${textColorClass} `} />
                        <span className="text-[12px] font-medium text-textCounterColor leading-[18px] capitalize">{booking.status}</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default BookingCard;
