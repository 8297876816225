import React from "react";

type StepProgressBarProps = {
    steps: number;
    currentStep: number;
};

const StepProgressBar: React.FC<StepProgressBarProps> = ({ steps, currentStep }) => (
    <div className="flex items-center gap-[12px] ">
        {Array.from({ length: steps }, (_, i) => i + 1).map((step, index) => {
            const isCompleted = index < currentStep;
            return <div key={step} className={`flex-1 ${isCompleted ? "bg-primaryAppointment" : "bg-borderColorPrimary opacity-70"} h-[5px] rounded-[30px]`}></div>;
        })}
    </div>
);

export default StepProgressBar;
