import React, { ReactElement } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MyMap from "./MyMap";
import { IShopLocation } from "../../Interface";
import { Spinner } from "flowbite-react";

interface IProps {
    shopLocationData: IShopLocation[];
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
    showAppointmentShopOnMap: IShopLocation | undefined;
    setShowAppointmentShopOnMap?: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
}

const MapAdvance = ({ shopLocationData, selectedShopData, setSelectedShopData, showAppointmentShopOnMap, setShowAppointmentShopOnMap }: IProps) => {
    const render = (status: Status): ReactElement => {
        if (status === Status.LOADING)
            return (
                <div className="h-full w-full flex justify-center items-center">
                    <Spinner />
                </div>
            );
        if (status === Status.FAILURE) return <div className="h-full w-full flex justify-center items-center">Failed to load map, Please try again!</div>;
        return <h3>{status} ...</h3>;
    };

    return (
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY!} version="beta" libraries={["marker"]} render={render}>
            <MyMap
                shopLocationData={shopLocationData}
                selectedShopData={selectedShopData}
                showAppointmentShopOnMap={showAppointmentShopOnMap}
                setSelectedShopData={setSelectedShopData}
                setShowAppointmentShopOnMap={setShowAppointmentShopOnMap}
            />
        </Wrapper>
    );
};

export default MapAdvance;
