export interface INotification {
    referral: NotificationOptions;
    whats_new: NotificationOptions;
    promotions: NotificationOptions;
    booking_confirm: NotificationOptions;
    booking_canceled: NotificationOptions;
    booking_reschedule: NotificationOptions;
}

export type NotificationOptions = "none" | "all" | "email" | "sms";

export enum Category {
    TODAY = "today",
    YESTERDAY = "yesterday",
    THIS_WEEK = "this_week",
    OLDER = "older",
}

export const initAction = {
    landing: true,
    logout: false,
    notification: false,
    notification_setting: false,
    account_setting: false,
    payment_method: false,
    appointment_history: false,
    tip: false,
    booking: false,
    create_payment_method: false,
};
