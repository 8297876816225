import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { FlairLogo, PhoneBackgoround, MassageIcon, SafariIcon } from "src/theme/Images";

const Step5 = () => {
    const shop = useAppSelector(currentShop);
    const logoImageUrl = shop && shop.admin_logo_image_url ? shop.admin_logo_image_url : FlairLogo;
    const businessName = shop && shop.business_name ? shop.business_name : "FLAIR Studios";
    return (
        <>
            <span className="text-[16px] leading-[22.4px] font-normal text-txtAppointmentColor tracking-[-0.01rem] mb-3 min-h-[44px] max-h-[44px]">Open {businessName} on your Home Screen</span>
            <div className="phone-right-body-bg relative">
                <div className="phone-body relative">
                    <img src={PhoneBackgoround} className="w-[100%] h-[100%] relative" alt="" />
                    <div className="absolute bottom-[22px] rounded-[34.8px] h-[108.5px] left-[22px] w-full right-0  bg-[#000000] bg-opacity-30 p-5 backdrop-blur-[1.0236px]">
                        <div className="flex justify-between items-center gap-[36px]">
                            <img src={logoImageUrl} className=" rounded-lg w-[67.56px] h-[67.56px]" alt="" />
                            <img src={SafariIcon} className=" rounded-lg w-[67.56px] h-[67.56px]" alt="" />
                            <img src={MassageIcon} className=" rounded-lg w-[67.56px] h-[67.56px]" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Step5;
