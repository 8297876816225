import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { loginSlice } from "./reducers/Login.slice";
import { uiSlice } from "./reducers/Ui.slice";
import { landingSlice } from "./reducers/Landing.slice";
import { commonSlice } from "./reducers/common/Common.slice";
import { bookingSlice } from "src/app/BookAppointment/Booking.slice";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["AuthUser", "Booking", "UiStates", "Common"],
};

const rootReducer = combineReducers({
    AuthUser: loginSlice.reducer,
    Booking: bookingSlice.reducer,
    UiStates: uiSlice.reducer,
    LandingStates: landingSlice.reducer,
    Common: commonSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
