import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { FlairLogo } from "src/theme/Images";
const Step4 = () => {
    const shop = useAppSelector(currentShop);
    const slugValue = shop ? `https://${shop.slug}.getflair.ca` : "https://web.getflair.ca";
    const logoImageUrl = shop && shop.admin_logo_image_url ? shop.admin_logo_image_url : FlairLogo;
    const businessName = shop && shop.business_name ? shop.business_name : "FLAIR Studios";
    return (
        <>
            <span className="text-[16px] leading-[22.4px] font-normal text-txtAppointmentColor tracking-[-0.01rem] mb-3 min-h-[44px] max-h-[44px]">Add a name</span>
            <div className="phone-revarse-body-bg">
                <div className="phone-body ">
                    <div className="flex w-full justify-between  items-center h-[40px] px-3 py-2.5">
                        <span className="text-primary text-[14px] leading-[19.6px] tracking-[-0.01rem]">Cancel</span>
                        <span className="text-[14px] leading-[19.6px] font-bold text-txtAppointmentColor tracking-[-0.01rem] ">Add to Home Screen</span>
                        <span className="text-primary text-[14px] leading-[19.6px] tracking-[-0.01rem]">Add</span>
                    </div>
                    <div className="h-[78px] bg-white border-t-[0.67px] border-b-[0.67px] border-[#E3E7EF] flex  flex-row gap-3 items-center justify-center w-full pl-3 pt-3 pb-3">
                        <div className="flex w-[19%]">
                            <img src={logoImageUrl} className=" rounded-lg w-[42px] h-[42px]" alt="" />
                        </div>
                        <div className="flex flex-col w-[81%]">
                            <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor tracking-[-0.01rem] border-b border-[#E3E7EF] pb-1.5">{businessName}</span>
                            <span className="text-[14px] leading-[19.6px] font-normal text-textGrey tracking-[-0.01rem] pt-1.5">{slugValue}</span>
                        </div>
                    </div>
                    <span className="px-3 text-[12px] leading-[18px] font-normal text-textGrey tracking-[-0.01rem]">
                        An icon will be added to your Home Screen so you can quickly access this website.
                    </span>
                </div>
            </div>
        </>
    );
};

export default Step4;
