import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { Step2PNG } from "src/theme/Images";
import { Step1PNG, Steplockicon, StepaA, Stepreload, StepSymbolleft, StepSymbolright, StepSymbolshare, StepSymbolcopy, Stepbook } from "src/theme/Images";
const Step2 = () => {
    const shop = useAppSelector(currentShop);
    const websiteUrl = shop && shop.slug ? `${shop.slug}.getflair.ca` : "flairapp.com";
    return (
        <div className="flex flex-col">
            <div className=" flex text-[16px] leading-[22.4px] font-normal text-txtAppointmentColor tracking-[-0.01rem] mb-3 min-h-[44px] max-h-[44px]">
                Click on Share icon <img className="symbol-share px-1.5 w-6 h-[22px]" src={StepSymbolshare} /> in your browser
            </div>
            <div className="phone-body-bg">
                <div className="phone-body">
                    <div className="frame-1171278336">
                        <div className="url-bar-mini">
                            <div className="searchbar">
                                <div className="search-bottom">
                                    <img src={StepaA} className="a-a" alt="" />
                                    <div className="domain">
                                        <div className="div mr-1">
                                            <img src={Steplockicon} alt="" />
                                        </div>
                                        <div className="domain2">{websiteUrl}</div>
                                    </div>
                                    <img className="reload" src={Stepreload} />
                                </div>
                            </div>
                            <div className="toolbar">
                                <img className="symbol-left" src={StepSymbolleft} />
                                <img className="symbol-right" src={StepSymbolright} />
                                <div className="w-[37px] h-[37px] flex items-center justify-center bg-white  rounded-full">
                                    <img className="symbol-share" src={StepSymbolshare} />
                                </div>
                                <img className="symbol-book" src={Stepbook} />
                                <img className="symbol-copy" src={StepSymbolcopy} />
                            </div>
                            <div className="home-bar-mini">
                                <div className="home-indicator"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step2;
