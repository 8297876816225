import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import OTPField from "src/components/OTPField/OTPField";
import { IVerifyOtp } from "../Password.interface";

const WVerifyOtp: FC<IVerifyOtp> = ({ authData, loading, handlerOtpAgainSend }: any) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const otpWatch = watch("otp");
    return (
        <div className="content font-primary model-inner-wrapper">
            <h2 className="login-model-title">{t("Enter the verification code")}</h2>
            <div className="login-model-subtitle flex flex-col">
                <span className="login-model-subtitle"> {t("A 4-digit code has been sent to you at")}</span>
                <span className="login-model-subtitle-dec">{authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}</span>
            </div>
            <div className="mx-auto mt-[42px]">
                <Controller
                    name="otp"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col">
                            <OTPField otp={value} setOtp={onChange} errors={error} />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                {authData.isEmail && <div className="text-textGrey text-center text-sm font-normal mt-1.5 -tracking-[0.02rem]">{t("Tip: Be sure to check your inbox and spam folders")}</div>}
                <div className="mt-[24px] flex flex-col items-center">
                    <div className="flex items-center gap-1">
                        <p className="text-textGrey text-sm leading-[19.6px] font-normal -tracking-[0.02rem]">{t("Didn’t receive code")}?</p>
                        <CustomButton
                            type="button"
                            disabled={loading}
                            onClick={handlerOtpAgainSend}
                            className="text-primary !border-0 !p-0 !h-auto leading-[19.6px] font-semibold !bg-transparent text-sm text-center flex flex-col ring-0"
                            shadow={false}
                        >
                            {t("Resend Code")}
                        </CustomButton>
                    </div>
                </div>
                <div className="mt-[52px] w-full bottom-0 flex flex-col">
                    <CustomButton type="submit" isLoading={loading} disabled={otpWatch && otpWatch.length !== 4 ? true : false} primary className="w-full !h-[48px]">
                        <span>{t("Continue")}</span>
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default WVerifyOtp;
