import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { API } from "src/constants/api";
import { axiosGet, axiosPatch } from "src/utils/requestClient";
import { Category } from "./Profile.interface";
import { pickBy, some } from "lodash";
import { ArrowLeft } from "@untitled-ui/icons-react/build/cjs";
import { DoubleCheck } from "src/theme/Images";
import { PlaceholderImgPNG } from "src/theme/Images";
import { GoDotFill } from "react-icons/go";
import CustomButton from "src/components/CustomButton";
import { Paginator } from "primereact/paginator";

const Notification = ({ handleSidebarClose, totalRecords, setTotalRecords }: any) => {
    const [notifications, setNotifications] = useState([]);
    const [isNotification, setIsNotification] = useState(false);
    const [filteredNotifications, setFilteredNotifications] = useState({
        [Category.TODAY]: [],
        [Category.YESTERDAY]: [],
        [Category.THIS_WEEK]: [],
        [Category.OLDER]: [],
    });
    const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingState, setLoadingState] = useState({
        next: false,
        prev: false,
    });

    useEffect(() => {
        listNotification();
    }, []);

    useEffect(() => {
        const data: any = {
            [Category.TODAY]: [],
            [Category.YESTERDAY]: [],
            [Category.THIS_WEEK]: [],
            [Category.OLDER]: [],
        };

        const today = moment().startOf("day");
        const yesterday = moment().subtract(1, "days").startOf("day");
        const startOfWeek = moment().startOf("week");

        notifications.forEach((item: any) => {
            const date = moment(item.created_at);
            if (date.isSame(today, "d")) {
                data[Category.TODAY].push(item);
            } else if (date.isSame(yesterday, "d")) {
                data[Category.YESTERDAY].push(item);
            } else if (date.isAfter(startOfWeek)) {
                data[Category.THIS_WEEK].push(item);
            } else {
                data[Category.OLDER].push(item);
            }
        });

        const filteredData: any = pickBy(data, (item) => item.length > 0);

        setFilteredNotifications(filteredData);
        setIsNotification(some(filteredData, (arr) => Array.isArray(arr) && arr.length > 0));
    }, [notifications]);

    const listNotification = () => {
        setIsLoading(true);
        axiosGet(API.NOTIFICATION.LIST, lazyState)
            .then((response) => {
                setTotalRecords({ totalRecords: response.data.data.totalRecords, unread_count: response.data.data.unread_count });
                setNotifications(response.data.data.data);
            })
            .finally(() => {
                setLoadingState({ next: false, prev: false });
                setIsLoading(false);
            });
    };

    const checkNotificationStatus =
        (payloadType: string, data: any = null) =>
        async () => {
            if (totalRecords.unread_count === 0) return;

            setIsMarkingAsRead(true);
            const payload: any = {
                type: payloadType,
                ...(data && { id: data.id }),
            };

            await axiosPatch(API.NOTIFICATION.READ, payload)
                .then(async (response) => {
                    listNotification();
                    return;
                })
                .finally(() => setIsMarkingAsRead(false));
        };

    const NotificationCard = ({ notification }: any) => (
        <div className="flex items-start border rounded-lg border-borderColorPrimary p-4 relative cursor-pointer" onClick={checkNotificationStatus("single", notification)}>
            <div className="flex mr-4">
                <img src={PlaceholderImgPNG} className="h-[40px] min-w-[40px] object-cover relative rounded-primary" alt="" />
            </div>
            <div className="flex flex-col multiline-truncate-wrapper">
                <p className="text-[14px] font-normal leading-[20px] text-[#475467] mb-1 maltiline-truncate">{notification.description}</p>
                <div className="flex items-center">
                    <p className="text-[12px] leading-[18px] font-medium text-[#475467]">{notification.name}</p>
                    <span className="text-[12px] font-normal text-[#667085] leading-[18px] flex flex-row items-center">
                        <span className="flex h-[4px] w-[4px] bg-textGrey rounded-full mr-1 ml-1 leading-[18px]"></span> {moment(notification.created_at).fromNow()}
                    </span>
                </div>
            </div>
            {!notification.is_read && (
                <div className="absolute right-[10px] top-[10px]">
                    <GoDotFill size={10} color="#17B26A" className="rounded-full flex" />
                </div>
            )}
        </div>
    );

    const [lazyState, setlazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
    });

    useEffect(() => {
        listNotification();
    }, [lazyState]);

    const onPageChange = (event: any) => {
        const newLoadingState = { ...loadingState };
        if (event.page > lazyState.page) {
            newLoadingState.next = true;
        }
        if (event.page < lazyState.page) {
            newLoadingState.prev = true;
        }
        setLoadingState(newLoadingState);
        setlazyState((prev: any) => ({
            ...prev,
            first: event.first,
            rows: event.rows,
            page: event.page,
        }));
    };

    const customPaginatorTemplate = {
        layout: "CurrentPageReport PrevPageLink NextPageLink",

        PrevPageLink: ({ onClick }: any) =>
            totalRecords.totalRecords > lazyState.rows && (
                <CustomButton secondary onClick={onClick} isLoading={loadingState.prev} disabled={isLoading} className="mr-3 text-sm">
                    Previous
                </CustomButton>
            ),
        NextPageLink: ({ onClick }: any) =>
            totalRecords.totalRecords > lazyState.rows && (
                <CustomButton secondary onClick={onClick} isLoading={loadingState.next} disabled={isLoading} className="text-sm">
                    Next
                </CustomButton>
            ),
        CurrentPageReport: ({ currentPage, totalPages }: { currentPage: number; totalPages: number }) => (
            <div className="text-gray-700 flex items-center text-sm font-medium mr-auto gap-3">
                {totalRecords.totalRecords > lazyState.rows && (
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                )}
            </div>
        ),
    };

    return (
        <div className="flex flex-col h-full flex-1">
            <div className="flex justify-between items-center border-b  h-[52px] lg:h-[60px] px-4 lg:px-5 border-borderColorPrimary ">
                <div className="flex items-center flex-1 gap-1" onClick={() => handleSidebarClose()}>
                    <ArrowLeft className="w-[20px] h-[20px] cursor-pointer text-txtAppointmentColor" />
                    <span className="text-txtcolor font-bold leading-[22.4px] text-[16px] tracking-[-0.01rem]">Notifications</span>
                </div>
                <CustomButton
                    isLoading={isMarkingAsRead}
                    disabled={totalRecords.unread_count === 0}
                    secondary
                    onClick={checkNotificationStatus("all")}
                    className="text-[#475467] !font-semibold cursor-pointer !text-[13px] !leading-[18px] border-none !bg-transparent"
                >
                    <DoubleCheck />
                    Mark as all read
                </CustomButton>
                {/* <button className="text-primary font-semibold text-sm flex items-center" onClick={checkNotificationStatus("all")}>
                    
                    <span className=" text-[#475467] font-semibold text-[12px] leading-[18px] "> Mark as all read</span>
                </button> */}
            </div>
            <div className="flex flex-col gap-2.5 lg:3.5 mt-4 lg:mt-5 overflow-y-scroll max-h-[calc(100vh-138px)] lg:max-h-[calc(100vh-154px)] px-4 lg:px-5 flex-1">
                {isNotification ? (
                    Object.entries(filteredNotifications).map(([category, data]) => (
                        <Fragment key={category}>
                            <p className="text-sm font-medium text-txtcolor capitalize">{category.replace(/_/g, " ")}</p>
                            {data.map((notification: any) => (
                                <NotificationCard key={notification.id} notification={notification} />
                            ))}
                        </Fragment>
                    ))
                ) : (
                    <div className="flex-1 flex items-center justify-center text-center">Notifications not available</div>
                )}
            </div>
            <div className="px-4 lg:px-5 pt-2">
                <Paginator
                    first={lazyState.first}
                    rows={lazyState.rows}
                    totalRecords={totalRecords.totalRecords}
                    template={customPaginatorTemplate}
                    rowsPerPageOptions={[10, 20, 30]}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    );
};

export default Notification;
