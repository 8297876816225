import React, { memo, useEffect, useLayoutEffect } from "react";
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { FaAngleDown, FaCheck } from "react-icons/fa6";
import { usePhone } from "../../hooks/usePhone";
import { CountryCode, formatIncompletePhoneNumber, parseIncompletePhoneNumber } from "libphonenumber-js";
import { INumberList, ISelectedNumber } from "./Number.interface";
import { IoCloseCircle } from "react-icons/io5";
import { useAppSelector } from "src/redux/hooks";

interface IProps {
    getNumber: (e: string) => void;
    onNumberChange: (country: CountryCode, phone: string, code: string) => void;
    errors?: any;
    disabled?: boolean;
    defaultPhone?: string;
    defaultCode?: string;
}

const Number = ({ getNumber, onNumberChange, errors, defaultPhone, defaultCode, disabled = false }: IProps) => {
    const { setFormatPhone, phoneInfo } = usePhone();
    const [isShowCountryDropdown, setIsShowCountryDropdown] = React.useState(false);
    const [selectedNumber, setSelectedNumber] = React.useState<ISelectedNumber>({
        code: "1",
        number: "",
        image: require(`src/assets/flags/CA.svg`).default,
        name: "Canada",
        country: "CA",
    });
    const [numberList, setNumberList] = React.useState<INumberList[]>([]);
    const [isInit, setIsInit] = React.useState(true);
    const [filterList, setFilterList] = React.useState<INumberList[]>([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const uiState = useAppSelector((uiData) => uiData.UiStates);

    useLayoutEffect(() => {
        generateCountryList();
    }, []);

    useEffect(() => {
        const handler = () => setIsShowCountryDropdown(false);
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    }, []);

    useEffect(() => {
        if (!(defaultPhone && defaultCode)) {
            return;
        }

        if (defaultPhone.includes(selectedNumber.number) && defaultCode === selectedNumber.code) {
            return;
        }

        const getDefaultPhoneInfo = phoneInfo(defaultPhone, defaultCode as CountryCode);

        const defaultSelectedNumber: ISelectedNumber = {
            code: getDefaultPhoneInfo && getDefaultPhoneInfo.countryCallingCode ? getDefaultPhoneInfo.countryCallingCode : "1",
            number: getDefaultPhoneInfo && getDefaultPhoneInfo.nationalNumber ? getDefaultPhoneInfo.nationalNumber : "",
            image: defaultCode ? require(`src/assets/flags/${defaultCode}.svg`) : require(`src/assets/flags/CA.svg`).default,
            name: en[defaultCode ? (defaultCode as CountryCode) : "CA"],
            country: defaultCode ? (defaultCode as CountryCode) : "CA",
        };

        setSelectedNumber(defaultSelectedNumber);
    }, [defaultCode, defaultPhone]);

    useEffect(() => {
        if (getNumber) {
            getNumber(`+${selectedNumber.code}${selectedNumber.number}`);
        }
        onNumberChange(selectedNumber.country, selectedNumber.number, selectedNumber.code);
    }, [selectedNumber.code, selectedNumber.number, selectedNumber.country]);

    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            return;
        }
        const filteredList = numberList.filter((country) => country.name.toLowerCase().includes(searchTerm));
        if (searchTerm === "") {
            setFilterList(numberList);
        } else {
            setFilterList(filteredList);
        }
    }, [searchTerm]);

    const handleDropdownOptionOpen = (e: any) => {
        e.stopPropagation();
        if (!disabled) {
            setIsShowCountryDropdown(!isShowCountryDropdown);
        }
    };

    const handleInputNumberChange = (e: any) => {
        let newValue = parseIncompletePhoneNumber(e.target.value);
        if (newValue === selectedNumber.number) {
            const newValueFormatted = formatIncompletePhoneNumber(newValue, selectedNumber.country);
            if (newValueFormatted.indexOf(e.target.value) === 0) {
                // Trim the last digit (or plus sign).
                newValue = newValue.slice(0, -1);
            }
        }
        setSelectedNumber({ ...selectedNumber, number: newValue });
    };

    const generateCountryList = async (selectedCountryName?: string) => {
        let numberListArray = getCountries().map((country) => ({
            image: require(`src/assets/flags/${country}.svg`),
            code: getCountryCallingCode(country),
            name: en[country],
            country: country,
        }));

        const priorityCountries = ["Canada", "United States", selectedCountryName ? selectedCountryName : en[defaultCode as CountryCode]];

        const customSortValue = (country: string) => {
            const priorityIndex = priorityCountries.indexOf(country);
            return priorityIndex !== -1 ? priorityIndex : priorityCountries.length + 1;
        };

        const shortedList = numberListArray.sort((a, b) => customSortValue(a.name) - customSortValue(b.name) || a.name.localeCompare(b.name));
        setNumberList(shortedList);
        setFilterList(shortedList);
    };

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
    };

    const clearSearchTerm = (event: { stopPropagation(): unknown; preventDefault: () => void }) => {
        event.preventDefault();
        event.stopPropagation();
        setSearchTerm("");
    };

    return (
        <div className="relative w-full">
            <div
                className={`fl-input-field fl-phone-field text-sm bg-inputbg overflow-hidden  rounded-secondary ${isShowCountryDropdown && ""} ${
                    errors?.phonenumber || errors?.phone ? "is-invalid bg-red-100" : "bg-inputBg border"
                } ${uiState.isTablet ? "h-[40px]  border border-borderColorSecondary" : "h-[54px] shadow-[0px_1px_2px_0px_#1018280D] border border-borderCounter"}`}
            >
                <span className={`fl-flag ${disabled && "fl-flag-disabled !cursor-not-allowed"}`} onClick={handleDropdownOptionOpen}>
                    <img src={selectedNumber.image} alt="" className="w-6 rounded" />
                    <FaAngleDown className="icon-angel" />
                </span>
                <span className="ml-1 mr-1 text-base"> +{selectedNumber.code}</span>
                <input
                    disabled={disabled}
                    className={`bg-transparent pl-0 text-base border-none w-3/4 focus:ring-0 h-auto`}
                    type={"text"}
                    name={"phonenumber"}
                    value={setFormatPhone(selectedNumber.code, selectedNumber.number, selectedNumber.country)}
                    onChange={handleInputNumberChange}
                    placeholder={setFormatPhone(selectedNumber.code, "8708708866", selectedNumber.country)}
                    autoComplete="off"
                />
            </div>
            {errors && (errors.phone || errors?.phonenumber) ? <p className="text-error">{errors && errors.phone ? errors.phone.message : "Invalid Phone number"}</p> : ""}
            {isShowCountryDropdown && (
                <div id="dropdownDelay" className="z-10 absolute top-15 bg-contentBackground rounded-3xl  w-full fl-dropdown-shadow">
                    <div className="px-4 pb-2 pt-4 ">
                        <div className="relative w-full">
                            <input type="text" placeholder="Search country" value={searchTerm} onChange={handleSearchTermChange} onClick={handleSearchTermClick} className="ba_form_control" />
                            <button className="absolute right-3 top-[14px]" onClick={clearSearchTerm}>
                                <IoCloseCircle className="cursor-pointer text-secondaryTxt text-xl" />
                            </button>
                        </div>
                    </div>
                    <ul className="country-code-dropdown-list bg-contentBackground" aria-labelledby="dropdownDelayButton">
                        {filterList.map((country: any, indexNumberCode: number) => (
                            <li
                                onClick={() => {
                                    generateCountryList(country.name);
                                    setSearchTerm("");
                                    setSelectedNumber({
                                        ...selectedNumber,
                                        ...country,
                                    });
                                }}
                                className={`country-code-dropdown-li ${selectedNumber.name === country.name && "active"}`}
                                key={indexNumberCode}
                                value={country.code}
                            >
                                <div className="flex gap-3">
                                    <img src={country.image} className="w-6 rounded-md" alt="" />
                                    <span className="w-10 text-sm font-normal">+{country.code}</span>
                                    <span className="text-sm  font-normal">{country.name}</span>
                                </div>
                                {selectedNumber.name === country.name && (
                                    // <img
                                    //     src={Tick}
                                    //     className="w-3 "
                                    //     alt=""
                                    // />
                                    <FaCheck size={16} />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default memo(Number);
