import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import MPhoneOrEmail from "src/components/PhoneOrEmail/MPhoneOrEmail";
import { ChevronLeft } from "@untitled-ui/icons-react/build/cjs";
import { IPhoneEmail } from "../Login.interface";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";

const MPhoneEmail: FC<IPhoneEmail> = ({ authData, inputType, loading, handleBackToLogin, onInputChange, isButtonDisabled }) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const { control } = useFormContext();
    return (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col">
                <span className="login-model-title-mobile">{!authData.isEmail ? t("Enter your email address") : t("Enter your phone number")}</span>
                <div className="mt-[12px]">
                    <div className="flex flex-wrap">
                        <Controller
                            name="emailPhone"
                            control={control}
                            render={({ field: { onChange }, fieldState: { error } }: any) => (
                                <div className="flex flex-col w-full">
                                    <MPhoneOrEmail
                                        onNumberChange={(data: any) => {
                                            onChange(data);
                                            onInputChange(data);
                                        }}
                                        errors={error}
                                        singleType={!authData.isEmail ? "email" : "phone"}
                                        inputType={inputType}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className={`w-full flex flex-col gap-2 ${!authData.isEmail ? "mb-0" : "mb-[80px]"} mt-[20px]`}>
                        {uiState.isTablet && (
                            <CustomButton
                                type="button"
                                icon={<ChevronLeft width={20} />}
                                className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-gray-100 !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                                shadow={false}
                                onClick={handleBackToLogin}
                            ></CustomButton>
                        )}
                        <CustomButton
                            type="submit"
                            isLoading={loading}
                            disabled={loading || isButtonDisabled}
                            primary
                            className={`bg-primary font-bold !h-[48px] w-full rounded-secondary transition-all ease-in-out delay-75`}
                        >
                            <span>{"Continue"}</span>
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div className="login-footer mt-5">
                <span className="font-medium text-[12px] leading-[15.52px] text-txtAppointmentColor max-w-[340px] mx-auto">
                    By continuing, you agree to the{" "}
                    <Link className="text-primaryAppointment" target="_blank" to={"/"}>
                        Terms of Service
                    </Link>
                      and acknowledge the{" "}
                    <Link className="text-primaryAppointment" target="_blank" to={"/"}>
                        Privacy Policy
                    </Link>
                    .
                </span>
            </div>
        </div>
    );
};

export default MPhoneEmail;
