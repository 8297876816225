import { createSlice } from "@reduxjs/toolkit";
import { IShopLocation, ISpecialist, ISpecialistService } from "../../Interface";
import { EBookingTabState } from "src/app/BookAppointment/bookAppointment.interface";
import { RootState } from "src/redux/store";
import { IUser } from "src/Interface";

const initialState = {
    modifyingAppointmentId: undefined,
    bookingPreview: [],
    isModifyingAppointment: false,
    isShowFinalizeBooking: false,
    selectedShopLocation: undefined,
    selectedSpecialist: undefined,
    selectedBookingDate: undefined,
    selectedBookingTime: undefined,
    selectedServices: [],
    selectedProducts: [],
    paymentOption: null,
    oldPaymentOption: null,
    paymentType: null,
    isValidPaymentType: false,
    step: EBookingTabState.TAB_MENU,
    isShowNoShowPolicy: false,
    isShowOrderSummary: false,
    isNoShowPolicyAccepted: false,
    selectedPromocode: undefined,
    tempStore: {
        selectedSpecialist: undefined,
        selectedBookingDate: undefined,
        selectedBookingTime: undefined,
        selectedServices: [],
        selectedProducts: [],
        selectedBookingDateTime: undefined,
        SelectionCount: 0,
    },
    productCategoryFilterValue: null,
    isCardAdd: false,
    submitTriggered: false,
    isCardLoading: false,
    bookingTotal: 0,
};

export interface BookingPreviewItem {
    key: "selectedSpecialist" | "selectedServices" | "selectedBookingDateTime";
    value: any;
}

export interface IStateType {
    modifyingAppointmentId: number | undefined;
    bookingPreview: BookingPreviewItem[];
    isModifyingAppointment: boolean;
    isShowFinalizeBooking: boolean;
    selectedShopLocation: IShopLocation | undefined;
    selectedSpecialist: ISpecialist | undefined;
    selectedBookingDate: string | undefined;
    selectedBookingTime: string | undefined;
    selectedServices: ISpecialistService[];
    selectedProducts: any[];
    oldPaymentOption: "pay_now" | "pay_later" | null;
    paymentOption: "pay_now" | "pay_later" | null;
    paymentType: "cash" | "stripe" | "pos" | "loyalty" | "other" | null;
    isValidPaymentType: boolean;
    step: EBookingTabState;
    isShowNoShowPolicy: boolean;
    isShowOrderSummary: boolean;
    isNoShowPolicyAccepted: boolean;
    selectedPromocode: any | undefined;
    tempStore: {
        selectedSpecialist: ISpecialist | undefined;
        selectedBookingDate: string | undefined;
        selectedBookingTime: string | undefined;
        selectedBookingDateTime: string | undefined;
        selectedServices: ISpecialistService[];
        selectedProducts: any[];
        SelectionCount: number;
    };
    productCategoryFilterValue: null | any;
    isCardAdd: boolean;
    submitTriggered: boolean;
    isCardLoading: boolean;
    bookingTotal: number;
}

export const bookingSlice = createSlice({
    name: "booking",
    initialState: initialState as unknown as IStateType,
    reducers: {
        setBookingInitialState: (state) => {
            Object.assign(state, initialState);
        },
        setModifyingAppointmentId: (state, { payload }) => {
            state.modifyingAppointmentId = payload;
        },
        setIsModifyingAppointment: (state, { payload }) => {
            state.isModifyingAppointment = payload;
        },
        setIsShowFinalizeBooking: (state, { payload }) => {
            state.isShowFinalizeBooking = payload;
        },
        setSelectedShopLocation: (state, { payload }) => {
            state.selectedShopLocation = payload;
        },
        setSelectedSpecialist: (state, { payload }) => {
            state.selectedSpecialist = payload;
        },
        setSelectedBookingDate: (state, { payload }) => {
            state.selectedBookingDate = payload;
        },
        setSelectedBookingTime: (state, { payload }) => {
            state.selectedBookingTime = payload;
        },
        setSelectedServices: (state, { payload }) => {
            state.selectedServices = payload;
        },
        setSelectedProducts: (state, { payload }) => {
            state.selectedProducts = payload;
        },
        setOldPaymentOption: (state, { payload }) => {
            state.oldPaymentOption = payload;
        },
        addProduct: (state, { payload }) => {
            state.selectedProducts = [...state.selectedProducts, payload];
        },
        removeProduct: (state, { payload }) => {
            state.selectedProducts = state.selectedProducts.filter((_, idx) => idx !== payload);
        },
        setStep: (state, { payload }) => {
            state.step = payload;
        },
        setTempStore: (state, { payload }) => {
            state.tempStore = { ...state.tempStore, ...payload };
        },
        setPaymentType: (state, { payload }) => {
            state.paymentType = payload;
        },
        setPaymentOption: (state, { payload }) => {
            state.paymentOption = payload;
        },
        setIsValidPaymentType: (state, { payload }) => {
            state.isValidPaymentType = payload;
        },
        setIsShowNoShowPolicy: (state, { payload }) => {
            state.isShowNoShowPolicy = payload;
        },
        setIsShowOrderSummary: (state, { payload }) => {
            state.isShowOrderSummary = payload;
        },
        setIsNoShowPolicyAccepted: (state, { payload }) => {
            state.isNoShowPolicyAccepted = payload;
        },
        setSelectedPromocode: (state, { payload }) => {
            state.selectedPromocode = payload;
        },
        setProductCategory: (state, { payload }) => {
            state.productCategoryFilterValue = payload;
        },
        setIsCardAdded: (state, { payload }) => {
            state.isCardAdd = payload;
        },
        setSubmitTriggered: (state, { payload }) => {
            state.submitTriggered = payload;
        },
        setIsCardLoading: (state, { payload }) => {
            state.isCardLoading = payload;
        },
        setPreview: (state, { payload }) => {
            const { key, value } = payload;
            const existingItemIndex = state.bookingPreview.findIndex((item) => item.key === key);

            if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
                if (existingItemIndex !== -1) {
                    state.bookingPreview.splice(existingItemIndex, 1);
                }
            } else {
                if (existingItemIndex !== -1) {
                    state.bookingPreview[existingItemIndex].value = value;
                } else {
                    state.bookingPreview.push({ key, value });
                }
            }
        },
        setBookingTotal: (state, { payload }) => {
            state.bookingTotal = payload;
        },
    },
});

export const {
    setModifyingAppointmentId,
    setIsModifyingAppointment,
    setIsShowFinalizeBooking,
    setSelectedShopLocation,
    setSelectedSpecialist,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedServices,
    setSelectedProducts,
    addProduct,
    removeProduct,
    setStep,
    setTempStore,
    setPaymentType,
    setPaymentOption,
    setOldPaymentOption,
    setIsValidPaymentType,
    setIsShowNoShowPolicy,
    setIsShowOrderSummary,
    setIsNoShowPolicyAccepted,
    setBookingInitialState,
    setSelectedPromocode,
    setProductCategory,
    setIsCardAdded,
    setSubmitTriggered,
    setIsCardLoading,
    setPreview,
    setBookingTotal,
} = bookingSlice.actions;

export const getBookingInfo = (state: RootState) => state.Booking;
