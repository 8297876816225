import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomButton from "src/components/CustomButton";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import FloatingInputbox from "src/components/FloatingInputbox";

interface FieldState {
    cardnumber: boolean;
    date: boolean;
    cvv: boolean;
}

const MSidebarPaymentElement = ({ isCreateCardLoading, setCardError, cardError, successBtnText, handleClose, stripe, elements }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const [isFocus, setIsFocus] = useState<FieldState>({ cardnumber: false, date: false, cvv: false });
    const [isLabel, setIsLabel] = useState<FieldState>({ cardnumber: true, date: true, cvv: true });
    const [isEmpty, setIsEmpty] = useState<FieldState>({ cardnumber: true, date: true, cvv: true });

    const onFocus = (name: keyof FieldState) => () => {
        setIsFocus((prev: FieldState) => ({
            ...prev,
            [name]: true,
        }));
        setIsLabel((prev: FieldState) => ({
            ...prev,
            [name]: true,
        }));
    };

    const onBlur = (name: keyof FieldState) => () => {
        if (!isEmpty[name]) {
            setIsLabel((prev: FieldState) => ({
                ...prev,
                [name]: false,
            }));
        } else {
            setIsLabel((prev: FieldState) => ({
                ...prev,
                [name]: true,
            }));
        }
        setIsFocus((prev: FieldState) => ({
            ...prev,
            [name]: false,
        }));
    };
    return (
        <>
            <div className="flex-1 flex flex-col gap-4">
                <Controller
                    name="holdername"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full flex flex-col">
                            <FloatingInputbox label={t("Cardholder Name")} handleChange={(e) => onChange(e.target.value)} value={value} name="holdername" inputClass={`${error && "is-error"}`} />
                            {error && <p className="text-error">{error && error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="cardnumber"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="flex flex-col w-full">
                            <div
                                className={`border-none bg-transparent rounded-secondary shadow-[0px_1px_2px_0px_#1018280D] w-full relative card-number-wrapper ${
                                    isFocus.cardnumber ? "card-number-wrapper-focus " : ""
                                }`}
                            >
                                <label htmlFor="cardnumber" className={`card-number-label ${isFocus.cardnumber ? "card-number-label-focus !absolute !top-1.5 !left-3.5 !text-[11px] " : ""}`}>
                                    {isLabel.cardnumber ? "Card Number" : ""}
                                </label>
                                <CardNumberElement
                                    id="number-123"
                                    className={`card-number-input ${isFocus.cardnumber ? "card-number-input-focus !pt-5" : ""} ${(error || cardError.cardNumber) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                        placeholder: "",
                                    }}
                                    onChange={(e) => {
                                        setIsEmpty((prev: any) => ({
                                            ...prev,
                                            cardnumber: e.empty,
                                        }));
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev: any) => ({
                                            ...prev,
                                            cardNumber: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                    onFocus={onFocus("cardnumber")}
                                    onBlur={onBlur("cardnumber")}
                                />
                            </div>
                            {(error || cardError.cardNumber) && <p className="text-error">{(error || cardError.cardNumber) && (error?.message || cardError.cardNumber)}</p>}
                        </div>
                    )}
                />
                <div className="flex gap-5 w-full">
                    <Controller
                        name="date"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="flex w-full flex-col">
                                <div
                                    className={`border-none bg-transparent rounded-secondary shadow-[0px_1px_2px_0px_#1018280D] w-full relative card-number-wrapper ${
                                        isFocus.date ? "card-number-wrapper-focus" : ""
                                    }`}
                                >
                                    <label htmlFor="date" className={`card-number-label ${isFocus.date ? "card-number-label-focus !absolute !top-1.5 !left-3.5 !text-[11px]" : ""}`}>
                                        {isLabel.date ? "Date" : ""}
                                    </label>
                                    <CardExpiryElement
                                        className={`card-number-input ${isFocus.date ? "card-number-input-focus !pt-5" : ""} ${(error || cardError.cardExpiry) && "is-invalid"}`}
                                        options={{
                                            style: {
                                                base: {
                                                    color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                                },
                                            },
                                            placeholder: "",
                                        }}
                                        onChange={(e) => {
                                            setIsEmpty((prev: any) => ({
                                                ...prev,
                                                date: e.empty,
                                            }));
                                            onChange(e.error ? "" : e.complete);
                                            setCardError((prev: any) => ({
                                                ...prev,
                                                cardExpiry: e.complete ? "" : e.error?.message,
                                            }));
                                        }}
                                        onBlur={onBlur("date")}
                                        onFocus={onFocus("date")}
                                    />
                                </div>
                                {(error || cardError.cardExpiry) && <p className="text-error">{(error || cardError.cardExpiry) && (error?.message || cardError.cardExpiry)}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="cvv"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="flex flex-col w-full">
                                <div
                                    className={`border-none bg-transparent rounded-secondary shadow-[0px_1px_2px_0px_#1018280D] w-full relative card-number-wrapper ${
                                        isFocus.cvv ? "card-number-wrapper-focus" : ""
                                    }`}
                                >
                                    <label htmlFor="cvv" className={`card-number-label ${isFocus.cvv ? "card-number-label-focus !absolute !top-1.5 !left-3.5 !text-[11px]" : ""}`}>
                                        {isLabel.cvv ? "CVC" : ""}
                                    </label>
                                    <CardCvcElement
                                        className={`card-number-input ${isFocus.cvv ? "card-number-input-focus !pt-5" : ""}  ${(error || cardError.cardCvc) && "is-invalid"}`}
                                        options={{
                                            style: {
                                                base: {
                                                    color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                                },
                                            },
                                            placeholder: "",
                                        }}
                                        onChange={(e) => {
                                            setIsEmpty((prev: any) => ({
                                                ...prev,
                                                cvv: e.empty,
                                            }));
                                            onChange(e.error ? "" : e.complete);
                                            setCardError((prev: any) => ({
                                                ...prev,
                                                cardCvc: e.complete ? "" : e.error?.message,
                                            }));
                                        }}
                                        onBlur={onBlur("cvv")}
                                        onFocus={onFocus("cvv")}
                                    />
                                </div>
                                {(error || cardError.cardCvc) && <p className="text-error">{(error || cardError.cardCvc) && (error?.message || cardError.cardCvc)}</p>}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="border-t border-borderColorPrimary flex gap-3 mt-5 bg-containerBgColor pt-4 pb-4 -mx-[16px] px-[16px] lg:-mx-[20px] lg:px-[20px]">
                <CustomButton outlineSecondary disabled={isCreateCardLoading} className={`w-full `} type="button" onClick={handleClose}>
                    Cancel
                </CustomButton>
                <CustomButton primary isLoading={isCreateCardLoading} disabled={isCreateCardLoading} className={`w-full `} type="submit">
                    {successBtnText}
                </CustomButton>
            </div>
        </>
    );
};

export default MSidebarPaymentElement;
