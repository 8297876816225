import React from "react";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { Step1PNG } from "src/theme/Images";

const Step6 = () => {
    const shop = useAppSelector(currentShop);
    const businessName = shop && shop.business_name ? shop.business_name : "FLAIR Studios";
    return (
        <>
            <span className="text-[16px] leading-[22.4px] font-normal text-txtAppointmentColor tracking-[-0.01rem] mb-3 min-h-[44px] max-h-[44px]">Enable Push Notifications for Updates</span>
            <div className="phone-front-body-bg">
                <div className="phone-body">
                    <div className="w-[231px] h-[174px] bg-white rounded-3xl flex flex-col items-center justify-center">
                        <div className="flex flex-col gap-1.5 p-3 text-center">
                            <div className="text-[14px] leading-[19.6px] font-bold text-txtAppointmentColor tracking-[-0.01rem]">“{businessName}” Would Like to Send You Notifications</div>

                            <div className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor tracking-[-0.01rem]">
                                Notifications may include alerts, sound, and icon badges. These can be configure in Settings
                            </div>
                        </div>
                        <div className="flex items-center  h-[44px] border-t border-borderColorSecondary w-full">
                            <span className="text-primary py-3 leading-[19.6px] text-[14px] border-r border-borderColorSecondary w-[50%] justify-center items-center flex">Don’t Allow</span>
                            <span className="text-primary leading-[19.6px] text-[14px] justify-center items-center flex w-[50%]">Allow</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Step6;
