import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { usePhone } from "src/hooks/usePhone";
import { DefaultAuthDataInit, ILoginProps } from "../Auth.interface";
import { trim } from "lodash";
import { selectLoading, userPhoneEmailLogin } from "src/redux/reducers/Login.slice";
import { errorCode } from "src/constants/common";
import MPhoneEmail from "./Mobile/MPhoneEmail";
import WPhoneEmail from "./Web/WPhoneEmail";
import { IPhoneEmailProps } from "./Login.interface";

const PhoneEmail: FC<IPhoneEmailProps> = ({ setAuthData, handleAuthAction, authData }) => {
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const dispatch = useAppDispatch();
    const { setFormatPhone } = usePhone();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const loading = useAppSelector(selectLoading);
    const inputType = authData.email && !authData.phone ? "phone" : authData.phone && !authData.email ? "email" : "both";

    const methods = useForm<ILoginProps>({
        defaultValues: {
            emailPhone: "",
        },
    });

    const { handleSubmit, setError } = methods;

    const handleChange = async (data: any) => {
        const info = data.emailPhone;
        if (info.error) {
            setError("emailPhone", {
                type: "manual",
                message: info.error,
            });
            return;
        }
        let payload = {};
        if (!authData.isEmail) {
            const mainNumber = `+${authData.phone.code}${authData.phone.number}`;
            payload = {
                email: info.data,
                phone: mainNumber,
                phone_country_code: authData.phone.country,
            };
        } else {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                email: authData.email,
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
        }

        const result: any = await dispatch(userPhoneEmailLogin(payload));

        if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            let updateAuthData = { ...authData, isEmail: info.isEmail };

            if (!info.isEmail) {
                const phoneInfo = info.data;
                const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                updateAuthData = {
                    ...updateAuthData,
                    phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                };
            } else {
                updateAuthData = {
                    ...updateAuthData,
                    email: info.data,
                };
            }
            setAuthData(updateAuthData);
            handleAuthAction("otp");
        }
        if (result.type === userPhoneEmailLogin.rejected.toString()) {
            const response = result.payload;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError("emailPhone", {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError("emailPhone", {
                        type: "manual",
                        message: response.message,
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };
    const onInputChange = (data: any) => {
        if (trim(data.data)) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    };
    const handleBackToLogin = () => {
        setAuthData(DefaultAuthDataInit);
        handleAuthAction("login");
    };
    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleChange)}>
                    {uiState.isTablet ? (
                        <WPhoneEmail
                            authData={authData}
                            inputType={inputType}
                            loading={loading}
                            handleBackToLogin={handleBackToLogin}
                            onInputChange={onInputChange}
                            isButtonDisabled={isButtonDisabled}
                        />
                    ) : (
                        <MPhoneEmail
                            authData={authData}
                            inputType={inputType}
                            loading={loading}
                            handleBackToLogin={handleBackToLogin}
                            onInputChange={onInputChange}
                            isButtonDisabled={isButtonDisabled}
                        />
                    )}
                </form>
            </FormProvider>
        </>
    );
};

export default PhoneEmail;
