import React, { FC, useState } from "react";
import { ChevronDown, ChevronUp, XClose } from "@untitled-ui/icons-react/build/cjs";
import { useTranslation } from "react-i18next";

export const Collapse: FC<{
    title: string;
    children: any;
    description?: string;
    isCollapsed?: boolean;
    classNames?: string;
    handleRemove?: () => void;
}> = ({ title, children, description, isCollapsed, classNames, handleRemove }) => {
    const [isShow, setIsShow] = useState(isCollapsed);
    const { t } = useTranslation();

    const handleCollapse = () => {
        setIsShow((prev) => !prev);
    };

    return (
        <div className="w-full relative">
            <div
                onClick={handleCollapse}
                className={`${
                    isShow ? " border-borderColorPrimary pb-0 h-full" : "border-borderColorPrimary"
                }  cursor-pointer lg:pt-4 lg:min-h-[78px] pt-3 pb-3 pl-3 pr-4 lg:pb-4 lg:pl-4 lg:pr-5 text-mainTextColor  border border-borderColorPrimary hover:shadow-md group rounded-secondary ${classNames}`}
            >
                <div className="flex items-center gap-4">
                    <div className="flex-1 ">
                        <div className="flex items-center gap-[10px]">
                            <h3 className="text-sm font-semibold leading-5 -tracking-[0.02rem]">{title}</h3>
                        </div>
                        {description && <div className="text-textGrey mt-1 font-medium text-xs lg:text-sm -tracking-[0.02rem]">{description}</div>}
                    </div>

                    <div className="text-right flex gap-3 items-center">
                        {handleRemove && (
                            <span className="text-error-700 cursor-pointer" onClick={handleRemove}>
                                <XClose width={18} className="text-error-700" />
                            </span>
                        )}
                        <button type="button" className=" text-gray-600">
                            {isShow ? <ChevronUp width={20} /> : <ChevronDown width={20} />}
                        </button>
                    </div>
                </div>
                {isShow && children}
            </div>
        </div>
    );
};
