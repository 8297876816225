import React, { useState } from "react";
import { Eye, EyeOff } from "@untitled-ui/icons-react/build/cjs";
import FloatingInputbox from "../FloatingInputbox";

interface IMInputPasswordWithLabel {
    onChange: Function;
    value: string;
    name: string;
    label: string;
    index?: number;
    error?: boolean;
    isFocus?: boolean;
    required?: boolean;
}

const MInputPasswordWithLabel = ({ error, onChange, value, name, label, index, isFocus, required }: IMInputPasswordWithLabel) => {
    const [isPasswordShow, setIsPasswordShow] = useState(false);

    const handlePasswordHideShow = () => {
        setIsPasswordShow(!isPasswordShow);
    };

    return (
        <div className={`flex flex-col relative w-full`}>
            {/* <input
                    className={`rounded-secondary w-full py-0 border-0 text-base focus:ring-0 bg-transparent outline-none font-normal placeholder:text-placeholderTxt placeholder:text-base placeholder:font-normal text-txtAppointmentColor -tracking-[0.03em] placeholder:-tracking-[0.03em] h-[-webkit-fill-available] ${
                        error && "is-invalid "
                    } `}
                /> */}
            <FloatingInputbox
                type={isPasswordShow ? "text" : "password"}
                name={name}
                value={value}
                error={error}
                handleChange={(e) => onChange(e, index)}
                autoFocus={isFocus}
                label={label}
                autoComplete="new-password"
                required={required}
            />

            <div className="cursor-pointer absolute top-1/2 -translate-y-1/2 right-4" onClick={handlePasswordHideShow}>
                {/* <img
                        src={isPasswordShow ? EyeOffIcon : error ? ErrorCircle : EyeIcon}
                        className="cursor-pointer min-w-4 h-4"
                        alt="Show password"
                        title={isPasswordShow ? "Hide password" : "Show Password"}
                    /> */}
                {isPasswordShow ? <Eye width={16} color="#8c9cbb" className="cursor-pointer" /> : <EyeOff width={16} color="#8c9cbb" className="cursor-pointer" />}
            </div>
        </div>
    );
};

export default MInputPasswordWithLabel;
