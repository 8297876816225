import React, { useState } from "react";
import { IInputPasswordWithLabel } from "./InputPasswordWithLabel.interface";
import { EyeOffIcon, EyeIcon, ErrorCircle } from "src/theme/Images";
import { Eye, EyeOff } from "@untitled-ui/icons-react/build/cjs";

const WInputPasswordWithLabel = ({ error, onChange, value, name, placeholder, label, index, isFocus, required, inputPasswordClass }: IInputPasswordWithLabel) => {
    const [isPasswordShow, setIsPasswordShow] = useState(false);

    const handlePasswordHideShow = () => {
        setIsPasswordShow(!isPasswordShow);
    };

    return (
        <div className={`flex flex-col form_group w-full`}>
            <label htmlFor={name} className="text-sm text-txtAppointmentColor leading-[18.2px] flex font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                {label}
                {required && <span className="asterisk">*</span>}
            </label>
            <div
                className={`fl-input-field fl-phone-field !h-10 rounded-secondary form_input_control shadow-InputAndButton relative ${inputPasswordClass} ${
                    error ? "!is-invalid" : "border border-borderColorSecondary"
                }`}
            >
                <input
                    className={`rounded-secondary w-full py-0 border-0 text-base focus:ring-0 bg-transparent outline-none font-normal placeholder:text-placeholderTxt placeholder:text-base placeholder:font-normal text-txtAppointmentColor -tracking-[0.03em] placeholder:-tracking-[0.03em] h-[-webkit-fill-available]  `}
                    type={isPasswordShow ? "text" : "password"}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e, index)}
                    placeholder={placeholder}
                    autoFocus={isFocus}
                    autoComplete="new-password"
                />

                <div className="cursor-pointer absolute top-1/2 -translate-y-1/2 right-4 " onClick={handlePasswordHideShow}>
                    {/* <img
                        src={isPasswordShow ? EyeOffIcon : error ? ErrorCircle : EyeIcon}
                        className="cursor-pointer min-w-4 h-4"
                        alt="Show password"
                        title={isPasswordShow ? "Hide password" : "Show Password"}
                    /> */}
                    {isPasswordShow ? <Eye width={16} color="#8c9cbb" className="cursor-pointer" /> : <EyeOff width={16} color="#8c9cbb" className="cursor-pointer" />}
                </div>
            </div>
        </div>
    );
};

export default WInputPasswordWithLabel;
