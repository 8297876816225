import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputWithLabel from "src/components/InputWithLabel";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";

const WAccountSetting = () => {
    const { t } = useTranslation();
    const user = useAppSelector(currentUser);
    const { control } = useFormContext();
    return (
        <>
            <div className="w-full flex flex-row mt-[20px] gap-4">
                <Controller
                    name="first_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-1/2 ">
                            <InputWithLabel label={t("First Name")} type="text" placeholder={t("Enter your first name")} handleChange={onChange} value={value} name="first_name" />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="last_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-1/2">
                            <InputWithLabel label={t("Last Name")} type="text" placeholder={t("Enter your last name")} handleChange={onChange} value={value} name="last_name" />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
            </div>

            <div className="w-full mt-[18px] ">
                <InputWithLabel label={t("Email")} disabled type="text" handleChange={() => {}} value={user?.email as string} name="email" />
            </div>
        </>
    );
};

export default WAccountSetting;
