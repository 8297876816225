import React, { useState } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import { useTranslation } from "react-i18next";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { errorCode } from "src/constants/common";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { onError } from "src/utils/global-functions";

interface ILanding {
    handleClose: () => void;
    setIsRefresh: (status: boolean) => void;
    booking: IAppointmentBooking;
}

const Tip = ({ handleClose, booking, setIsRefresh }: ILanding) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [customTip, setCustomTip] = useState<string>("");
    const [selectedTip, setSelectedTip] = useState<string | null>(null);
    const [errors, setErrors] = useState(null);
    const [rate] = useState(["Good", "Great", "Wow!", "Best Service Ever"]);

    const handleCustomTip = (event: any) => {
        const value = event.target.value;
        setCustomTip(value);
        setSelectedTip(null);
    };

    const handleTip = (tip: string) => () => {
        setCustomTip("");
        setSelectedTip(tip);
    };

    const handleSubmit = () => {
        setIsLoading(true);

        const params = {
            id: booking.id,
            shop_id: booking.shop.id,
        };
        const payload = {
            tip: Number(customTip || selectedTip),
        };

        axiosPost(API.BOOKING.TIP, payload, params)
            .then((response) => {
                toast.success(response.data.message);
                setIsRefresh(true);
                handleClose();
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setErrors(response.data[field][0]);
                        });
                        return;
                    }
                }
                setErrors(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="relative h-full w-full flex flex-col font-primary tracking-[0.03rem]">
            <div>
                <div className="font-medium text-[28px] leading-8 text-txtAppointmentColor">Thank you for booking at</div>
                <div className="font-medium text-[28px] leading-8 text-primaryAppointment">{booking.shop.business_name} </div>
            </div>
            <div className="flex flex-col mt-2">
                <div className="text-base font-normal text-textGrey">You appointment was successfully completed, would you like to tip for the service</div>
            </div>
            <div className="flex flex-col mt-8">
                <div className="font-medium text-base text-textGrey">Appointment #{booking.receipt_code}</div>
                <div className="flex flex-row justify-between  mt-2">
                    <div className="flex flex-row gap-2 ">
                        <img src={booking.staff.profile_image_url} className="w-[40px] h-[40px] rounded-lg object-cover" alt="" onError={onError} />
                        <div className="flex flex-col justify-between">
                            <div className="text-sm font-medium text-txtAppointmentColor tracking-[0.03rem]">{booking.staff.full_name}</div>
                            <div className="text-xs font-normal text-textGrey tracking-[0.03rem]">{booking.staff.staff_role.name}</div>
                        </div>
                    </div>
                    <div className="text-sm font-medium text-txtAppointmentColor">${booking.total}</div>
                </div>
            </div>
            <div className="flex flex-col mt-6 w-[100%] h-[calc(100vh-380px)] overflow-auto scrollbar-hide  pb-[10px]">
                <div className="font-medium text-base text-textGrey">Add a tip</div>
                <div className=" grid grid-cols-2 mt-2 w-full gap-3">
                    {booking.location.tips.map((tip: string, index: number) => (
                        <div
                            className={`w-full flex h-auto border rounded-lg justify-center cursor-pointer ${
                                selectedTip === tip ? "bg-dropdownBackgroundColor border-primaryAppointment" : "border-line"
                            }`}
                            key={tip}
                            onClick={handleTip(tip)}
                        >
                            <div className="flex flex-col justify-between items-center py-2.5 px-4 ">
                                <div className="text-base font-bold text-txtAppointmentColor">
                                    {booking.location.tips_type === "value" && "$"}
                                    {tip}
                                    {booking.location.tips_type === "percentage" && "%"}
                                </div>
                                {booking.location.tips_type === "percentage" && (
                                    <div className="text-xs font-normal text-txtAppointmentColor py-[2px] tracking-[0.03rem]">
                                        CA ${booking.location.tips_type === "percentage" && Number(Number(booking.total) * (Number(tip) / 100)).toFixed(2)}
                                    </div>
                                )}
                                <div className="text-xs font-normal text-textGrey tracking-[0.03rem]">{rate[index]}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="md:mt-5 mb-[15px]">
                    <div className="text-base font-medium text-textGrey mb-2">{t("Tip amount for the service")}</div>
                    <InputWithLabel type="number" name="Custom Tip" value={customTip} handleChange={handleCustomTip} placeholder={t("Enter custom tip")} min={1} />
                </div>
                {errors && <p className="text-error">{errors}</p>}
            </div>
            <div className="mt-auto absolute bottom-0 w-full flex gap-3 bg-contentBackground pt-2">
                {booking.is_tip_paid ? (
                    <CustomButton outlineSecondary size="w-full" disabled={isLoading} onClick={handleClose}>
                        {t("Paid")}
                    </CustomButton>
                ) : (
                    <>
                        <CustomButton outlineSecondary size="w-1/2" disabled={isLoading} onClick={handleClose}>
                            {t("Skip")}
                        </CustomButton>
                        <CustomButton primary size="w-1/2" disabled={isLoading || (!customTip && !selectedTip)} isLoading={isLoading} onClick={handleSubmit}>
                            {t("Confirm")}
                        </CustomButton>
                    </>
                )}
            </div>
        </div>
    );
};

export default Tip;
