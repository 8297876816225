import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPost } from "../../utils/requestClient";
import { API } from "../../constants/api";
import { errorCode } from "../../constants/common";
import { PURGE } from "redux-persist";
import { RootState } from "src/redux/store";

const initialState = {
    isShowLoginModal: false,
    loading: false,
    cookieSettingAnswer: undefined,
    notificationToken: undefined,
};

interface IStateType {
    isShowLoginModal: boolean;
    loading: boolean;
    cookieSettingAnswer: string | undefined;
    notificationToken: string | undefined;
}

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

export const userLogin = createAsyncThunk("user/login", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.LOGIN, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const userPhoneEmailLogin = createAsyncThunk("user/login/phone-email", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.PHONE_EMAIL, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const userLogout = createAsyncThunk("user/logout", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.LOGOUT, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const loginSlice = createSlice({
    name: "login",
    initialState: initialState as IStateType,
    reducers: {
        setNotificationToken: (state, { payload }) => {
            state.notificationToken = payload;
        },
        setIsShowLoginModal: (state, { payload }) => {
            state.isShowLoginModal = payload;
        },
        setCookieSettingAnswer: (state, { payload }) => {
            state.cookieSettingAnswer = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(PURGE, () => initialState)
            .addCase(userPhoneEmailLogin.pending, (state) => {
                state.loading = true;
            })
            .addCase(userPhoneEmailLogin.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(userPhoneEmailLogin.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(userLogout.pending, (state) => {
                state.loading = true;
            })
            .addCase(userLogout.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(userLogout.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const { setIsShowLoginModal, setCookieSettingAnswer, setNotificationToken } = loginSlice.actions;

export default loginSlice.reducer;

export const selectLoading = (state: RootState) => state.AuthUser.loading;
