import React from "react";

type ProfessionalProps = {
    data: any;
};

const Info: React.FC<ProfessionalProps> = ({ data }) => (
    <div className="flex flex-col">
        <p className="sub_text leading-[0.8] uppercase mb-4">{data.name}</p>
        <img src={data.image_url} alt="" className="h-[480px] sm:h-[410px] md:h-[420px] lg:h-[380px] xl:h-[410px] 2xl:h-[435px] 3xl:h-[540px]" />
        <div className="w-[-webkit-fill-available] relative mt-5 text-txtcolor ">
            <h3 className="sub_text leading-[0.8]">{data?.name}</h3>
        </div>

        {/* {instagram_link && (
            <div className="flex justify-between mt-2">
                <a href={`${formatInstagramLink(instagram_link)}`} target="_blank" className="text-xs border p-1">
                    @{getInstagramUsernames(instagram_link)}
                </a>
            </div>
        )} */}
        {/* <p className="text-lg ">{title}</p>

        <img src={profileImageUrl} alt={name} className="mt-2 " />

        <h3 className="text-xl font-semithin">{name}</h3>
        {instagram_link && (
            <a href={`${formatInstagramLink(instagram_link)}`} target="_blank" className="text-xs border p-1">
                @{getInstagramUsernames(instagram_link)}
            </a>
        )} */}
    </div>
    //      <div className="flex flex-col">
    //      <p className="text-lg">Senior Stylist</p>
    //      <img src="https://source.unsplash.com/random/314x250?barber-artist=3" alt="JASON MILLER" className="mt-2" />
    //      <h3 className="text-xl font-semithin">JASON MILLER</h3>
    //      <p className="">@jasson.miller</p>
    //  </div>
);

export default Info;
