export enum EBookingTabState {
    TAB_MENU = "tab_menu",
    SELECT_SPECIALIST = "select_specialist",
    SELECT_DATE_TIME = "select_date_and_time",
    SELECT_SERVICE = "select_service",
    FINALIZE_BOOKING = "finalize_booking",
    PRODUCT_BOOKING = "product_booking",
}

export enum BookingTABS {
    TAB_MENU = "/",
    SELECT_SPECIALIST = "specialist",
    SELECT_DATE_TIME = "time",
    SELECT_SERVICE = "service",
    FINALIZE_BOOKING = "payment",
    PRODUCT_BOOKING = "product",
}
