import React from "react";
interface Props {
    data: any;
}
const About: React.FC<Props> = ({ data }) => (
    <div className="mx-auto w-full text-txtcolor spacing-top ">
        {data?.is_title && <h1 className="custom-heading font-semithin  uppercase px-5 md:px-[38px] text-txtcolor dark:text-white mb-[1.8vw]">{data?.title}</h1>}
        <div className="flex flex-col md:flex-row  w-full text-txtcolor px-5 md:px-[38px] pb-8 lg:pb-[3vw]">
            <div className="w-full lg:w-1/2">
                {data?.is_subtitle ? (
                    <span className="font-normal text-[14px]  md:text-[16px] xl:text-[1.5vw] leading-[1] flex w-full md:w-[70%] lg:w-[60%] xl:w-[65%] uppercase">{data?.subtitle}</span>
                ) : (
                    ""
                )}
            </div>

            {data?.is_description && (
                <div className="md:max-w-[460px] lg:max-w-[594px] mt-5 md:mt-0 ">
                    <p className="font-light text-[14px] md:text-[16px]  xl:text-[1.111111vw]">{data?.description}</p>
                </div>
            )}
        </div>
        {data?.is_image ? <div className="w-full h-[60vh] md:h-[calc(100vh-56px)] object-cover bg-cover  bg-center bg-no-repeat" style={{ backgroundImage: `url(${data?.image_url})` }}></div> : <></>}
    </div>
);

export default About;
