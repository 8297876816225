import React, { useEffect, useState } from "react";
import Info from "./Info";
import SelectBox from "../../../../components/SelectBox";
import { Swiper, SwiperSlide } from "swiper/react";

interface Props {
    data: any;
    locationSetting: any;
}
const Professional: React.FC<Props> = ({ data, locationSetting }) => {
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [staffList, setStaffList] = useState([]);
    useEffect(() => {
        const locationOptionsData: any = locationSetting.locations.map((location: any) => ({
            value: location.title,
            label: location.title,
            id: location.title,
        }));
        const selectAllOption = {
            value: null,
            label: "All Locations",
            id: 0,
        };
        const updatedLocationOptionsData: any = [selectAllOption, ...locationOptionsData];

        setLocationOptions(updatedLocationOptionsData);
        // setSelectedLocation(locationOptionsData[0].value);
    }, []);
    const handleLocation = (e: any) => {
        setSelectedLocation(e.value);
    };
    useEffect(() => {
        if (selectedLocation) {
            const filteredStaff = data?.staff.filter((staffInfo: any) => staffInfo.location.some((location: any) => location === selectedLocation));
            setStaffList(filteredStaff);
        } else {
            setStaffList(data?.staff);
        }
    }, [selectedLocation]);
    return (
        <div className="w-full spacing-top text-txtcolor">
            <div className="flex flex-col gap-10 md:gap-[54px]">
                <div className="w-full flex flex-col md:flex-row justify-between">
                    <h2 className="custom-heading font-semithin uppercase">Our Professionals</h2>
                    <div className="flex justify-between md:justify-center items-center space-x-2 mt-5 md:mt-0">
                        <div className="flex w-full justify-between">
                            <div className="w-full flex-1">
                                <SelectBox
                                    placeholder={"All Locations"}
                                    name="location"
                                    allowClear={false}
                                    options={locationOptions}
                                    inputClass="w-[180px] h-[43px]"
                                    searchable={true}
                                    // defaultMenuIsOpen={true}
                                    // menuIsOpen
                                    // defaultMenuIsOpen
                                    onChange={handleLocation}
                                    value={locationOptions.filter((item: any) => item.value === selectedLocation)}
                                    disabled={false}
                                    // inputClass={`${errors.gender ? "is-invalid border-solid" : ""}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between gap-1">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        navigation={false} // Disable Swiper default navigation
                        loop={staffList?.length >= 4}
                        className="mySwiper w-full "
                        breakpoints={{
                            // When window width is <= 768px
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1280: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }} // Apply dynamic height here
                    >
                        {staffList.map((staff: any, index: React.Key | null | undefined) => (
                            <SwiperSlide key={index}>
                                <Info key={index} data={staff} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Professional;
